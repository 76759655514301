import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Listado sección Reportes
 * @interface ReportsCourseAdminSection
 */
export const ReportsCourseAdminSection = () => {

    const [users, setUsers] = useState([]);
    const [usersFull, setUsersFull] = useState([]);
    const [fullcatalog, setFullcatalog] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [dataFilter, setDataFilter] = useState({
        name: '',
        lastname: '',
        document: '',
        studyId: '',
        progressini: '',
        progressfin: '',
        since: '',
        studentType: '',
    })
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint reportes de pagos    
    useEffect(() => {
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filterdocument = dataFilter.document !== '' ? `&document=` + dataFilter.document : ''
        let filterstudyid = dataFilter.studyId !== '' ? `&studyId=` + dataFilter.studyId : ''
        let filterini = dataFilter.progressini !== '' ? `&progress[$gte]=` + dataFilter.progressini : ''
        let filterfin = dataFilter.progressfin !== '' ? `&progress[$lte]=` + dataFilter.progressfin : ''        
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filterstudenttype = dataFilter.studentType !== '' ? "&type=" + dataFilter.studentType : ''
        const response = handleFetch({
            url: `bo/usercourses?${filtername}${filterlastname}${filterdocument}${filterstudyid}${filterini}${filterfin}${filtersince}${filterstudenttype}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
            response.then((r) => {
                setUsers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecatalog = handleFetch({
            url: `catalog?$limit=500&$skip=0`,
            method: 'GET',
        });
        responsecatalog
            .then((rc) => {
                setFullcatalog(rc.data);
            },
            error => {
                console.log(error.errors);
                if (error.errors) {

                }
            })
        const responsefull = handleFetch({
            url: `bo/usercourses?$limit=50000${filtername}${filterlastname}${filterdocument}${filterstudyid}${filterini}${filterfin}${filtersince}${filterstudenttype}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsefull
            .then((r) => {
                setUsersFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();        
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filterdocument = dataFilter.document !== '' ? `&document=` + dataFilter.document : ''
        let filterstudyid = dataFilter.studyId !== '' ? `&studyId=` + dataFilter.studyId : ''
        let filterini = dataFilter.progressini !== '' ? `&progress[$gte]=` + dataFilter.progressini : ''
        let filterfin = dataFilter.progressfin !== '' ? `&progress[$lte]=` + dataFilter.progressfin : ''          
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filterstudenttype = dataFilter.studentType !== '' ? "&type=" + dataFilter.studentType : ''
        const response = handleFetch({
            url: `bo/usercourses?${filtername}${filterlastname}${filterdocument}${filterstudyid}${filterini}${filterfin}${filtersince}${filterstudenttype}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        const responsefull = handleFetch({
            url: `bo/usercourses?$limit=50000${filtername}${filterlastname}${filterdocument}${filterstudyid}${filterini}${filterfin}${filtersince}${filterstudenttype}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsefull
            .then((r) => {
                setUsersFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Reporte de cursos</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listreports.csv"}
                            onSubmit={handleSubmit}
                            data={usersFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='text' name='document' value={dataFilter?.document || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Curso realizado</span>
                                <div>
                                    <select name="studyId" className="form-select FilterAdmin__input"
                                        value={dataFilter?.studyId || ''} onChange={handleChangeFilter}>
                                            <option value="" selected>Seleccionar un curso</option>
                                        {
                                            fullcatalog.length !== 0 &&
                                            fullcatalog.slice()
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((data, index) => {
                                                return (
                                                    <option value={data?.courseId} key={data?.id}>{data?.name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Porcentaje inicio</span>
                                <div>
                                    <input type='number' name='progressini' value={dataFilter?.progressini || ''} onChange={handleChangeFilter} min='0' max={dataFilter?.progressfin || 0} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Porcentaje final</span>
                                <div>
                                    <input type='number' name='progressfin' value={dataFilter?.progressfin || ''} onChange={handleChangeFilter} min={dataFilter?.progressini} max='100' className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Inicio</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={dataFilter?.until || newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Tipo de usuario</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='studentType'
                                        value={dataFilter?.studentType || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value="1">Público General</option>
                                        <option value="2">Estudiantes Eanistas</option>
                                        <option value="3">Docente de Planta</option>
                                        <option value="4">Docente de Cátedra</option>
                                        <option value="5">Colaborador</option>
                                        <option value="6">Egresado</option>
                                    </select>
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable users={users} total={total} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar reportes
 * @param {JSON} users Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ users, total, dataFilter }) => {

    const [page, setPage] = useState(0);
    // Header para listado de reportes
    const headers = [
        {
            "name": "NOMBRE COMPLETO",
            "sort_name": "title",
            "width": "25%"
        },
        {
            "name": "CURSO REALIZADO",
            "sort_name": "enabled",
            "width": "35%"
        },
        {
            "name": "PORCENTAJE",
            "sort_name": "highlighted",
            "width": "10%"
        },
        {
            "name": "FECHA INICIO",
            "sort_name": "amount",
            "width": "10%"
        },
        {
            "name": "TIPO DE USUARIO",
            "sort_name": "lastlogin",
            "width": "20%",
            "align": "center"
        }
    ];

    const [usersSorted, setUsersSorted] = useState(users)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))

    useEffect(() => {
        setUsersSorted(users)
        setTotalSorted(total)
    }, [users, total])

    const resetState = () => {
        setUserHovered(Array(users.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(users.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filterdocument = dataFilter.document !== '' ? `&document=` + dataFilter.document : ''
        let filterstudyid = dataFilter.studyId !== '' ? `&studyId=` + dataFilter.studyId : ''
        let filterini = dataFilter.progressini !== '' ? `&progress[$gte]=` + dataFilter.progressini : ''
        let filterfin = dataFilter.progressfin !== '' ? `&progress[$lte]=` + dataFilter.progressfin : ''       
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filterstudenttype = dataFilter.studentType !== '' ? "&type=" + dataFilter.studentType : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/usercourses?$skip=${result}${filtername}${filterlastname}${filterdocument}${filterstudyid}${filterini}${filterfin}${filtersince}${filterstudenttype}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsersSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    
    const usertype = [
        {
            "id": 1,
            "name": "Público General",
        },
        {
            "id": 2,
            "name": "Estudiante Eanista",
        },
        {
            "id": 3,
            "name": "Docente de Planta",
        },
        {
            "id": 4,
            "name": "Docente de Cátedra",
        },
        {
            "id": 5,
            "name": "Colaborador",
        },
        {
            "id": 6,
            "name": "Egresado",
        }
    ];
    return (
        <>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px", textAlign: header.align ? header.align : "left" }}>{header.name}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        usersSorted.length != 0 ?
                            usersSorted.map((user, index) => {
                                const typeUserName = usertype.find(
                                    (item) => parseInt(item.id) == user?.['webuser.type'],
                                );
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{user?.['webuser.name']} {user?.['webuser.lastname']}</td>
                                        <td className="overflow UserAdmin__table-td" style={{ paddingLeft: "16px" }}>{user?.['course.title']}</td>
                                        <td className="overflow" style={{ textAlign: "center" }}>{user?.progress}%</td>
                                        <td className="overflow" style={{ textAlign: "center" }}>{new Date(user.createdAt).toISOString().split('T')[0]}</td>
                                        <td className="overflow" style={{ textAlign: "center", paddingLeft: "16px" }}>{typeUserName?.name}</td>
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="5" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}