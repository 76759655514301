import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección encuestas
 * @interface ScoresUsersSection
 */
export const ScoresUsersSection = () => {

    const [scores, setScores] = useState([]);
    const [scoresFull, setScoresFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();

    const [dataFilter, setDataFilter] = useState({
        name: '',
        lastname: '',
        document: '',
    })
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint listado de encuestas
    useEffect(() => {
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? `&lastname=` + dataFilter.lastname : ''      
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        const response = handleFetch({
            url: `bo/scoresusers?$limit=50${filtername}${filterlastname}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setScores(r)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecsv = handleFetch({
            url: `bo/scoresusers?$limit=50000${filtername}${filterlastname}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecsv
            .then((r) => {
                setScoresFull(r)
            },
                error => {
                    console.log(error.errors);
                })
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? `&lastname=` + dataFilter.lastname : ''      
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        const response = handleFetch({
            url: `bo/scoresusers?$limit=50000${filtername}${filterlastname}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setScoresFull(r)
            },
                error => {
                    console.log(error.errors);
                })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Progreso usuarios</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listscoresusers.csv"}
                            onSubmit={handleSubmit}
                            data={scoresFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='text' name='document' value={dataFilter?.document || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable scores={scores} total={total} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar encuestas
 * @param {JSON} scores Información de encuestas
 * @param {string} total Cantidad total de encuestas
 * @function UsersTable
 */
const UsersTable = ({ scores, total, dataFilter }) => {

    const [page, setPage] = useState(0);
    // Header para listado de encuestas
    const headers = [
        {
            "name": "NOMBRE USUARIO",
            "sort_name": "nameuser",
            "width": "20%"
        },
        {
            "name": "DOCUMENTO",
            "sort_name": "document",
            "width": "15%"
        },
        {
            "name": "TOTAL CURSOS",
            "sort_name": "total",
            "width": "12%"
        },
        {
            "name": "COMPLETADOS",
            "sort_name": "completed",
            "width": "12%"
        },
        {
            "name": "PROGRESO AVG",
            "sort_name": "avgprogress",
            "width": "12%"
        },
        {
            "name": "CON PROGRESO",
            "sort_name": "withprogress",
            "width": "12%"
        },
        {
            "name": "SIN PROGRESO",
            "sort_name": "withoutprogress",
            "width": "12%"
        },
        {
            "name": "CERTIFICADOS",
            "sort_name": "certificates",
            "width": "12%"
        }
    ];

    const [certifications, setUsersSorted] = useState(scores)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(scores?.length).fill(false))

    useEffect(() => {
        setUsersSorted(scores)
        setTotalSorted(total)
    }, [scores, total])

    const resetState = () => {
        setUserHovered(Array(scores.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(scores.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''      
        let filterlastname = dataFilter.lastname !== '' ? `&lastname=` + dataFilter.lastname : ''      
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/scoresusers?&$skip=${result}${filtername}${filterlastname}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsersSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    return (
        <>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        certifications.length != 0 ?
                            certifications.map((cert, index) => {
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert.name} {cert.lastname}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert.documentType} {cert.document}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.totalCourses}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.coursesCompleted}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.avgProgress.toFixed(2)}%</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.coursesWithProgress}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.coursesWithoutProgress}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.coursesWithCertificate}</td>
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="8" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}