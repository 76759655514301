import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { useForm as hookUseForm } from 'react-hook-form';
import { Pagination } from '../../pagination';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Toast } from '../../controls/UIControls';
import Modal from '@mui/material/Modal';
import { apiConfig } from '../../../api/apiConfig';
import * as CONSTANTS from '../../../assets/constants';
import CSVReader from 'react-csv-reader';
import Papa from 'papaparse';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2025<br>
 * Listado usuarios graduados
 * @interface GraduatesSection
 */
export const GraduatesSection = () => {

    const { register, setValue,handleSubmit, getValues, formState: { errors } } = hookUseForm({ reValidateMode: "onChange" });
    
    const alphabetPattern = CONSTANTS.PATTERN_ALPHABET;
    const numericPattern = CONSTANTS.PATTERN_NUMERIC;
    const alphanumericPattern = CONSTANTS.PATTERN_ALPHANUMERIC;

    const numericMessage = CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const alphanumericMessage = CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const [namePattern] = useState(alphabetPattern);
    const [docPattern, setDocPattern] = useState(numericPattern);
    const [docMessage, setDocMessage] = useState(numericMessage);
    const [showAge, setShowAge] = useState(false);
    const [users, setUsers] = useState([]);
    const [segment, setSegment] = useState([]);
    const [usersFull, setUsersFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const location= useLocation();
    const [toastProperties, setToastProperties] = useState({show: false});
    const [showModalCreateUser, setShowModalCreateUser] = useState(false);
    const [showModalCreateUserBatch, setShowModalCreateUserBatch] = useState(false);
    const [showModalTypeUsersCSV, setShowModalTypeUsersCSV] = useState(false);
    const navigate = useNavigate();
    
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }
    const [dataFilter, setDataFilter] = useState({
        identificationNumber: '',
        email: '',
        lastname: '',
        name: '',
    })
    const { authToken, getSessionUser } = useAuthToken(true);
    const sessionUser = getSessionUser();
    const isAdmin = sessionUser.type === 0 ||  sessionUser.type === 1;
    // Consulta de endpoint listado de usuarios
    const refreshUsers = () => {
        setToastProperties(location.state?.toastProperties || {show:false});
        window.history.replaceState(null,'');

        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        let url =  `bo/graduatesusers?${filtername}${filterlastname}${filteremail}${filterdocument}`
        const response = handleFetch({
            url: url,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                    setUsers(r.data)
                    setTotal(r.total)
                },
                error => {
                    console.log(error.errors);
                })
        const responsesegment = handleFetch({
            url: `bo/segment`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsesegment
            .then(async (r) => {
                if(r.data.length == 0){setSegment([
                    { "type": 1, "name": "Público General"},
                    { "type": 2, "name": "Estudiante Eanista"},
                    { "type": 3, "name": "Docente de Planta" },
                    { "type": 4, "name": "Docente de Cátedra"},
                    { "type": 5, "name": "Colaborador" },
                    { "type": 6, "name": "Egresado" } ])}else{setSegment(r.data)}
            },
                error => {
                    console.log(error.errors);
                })
    }
    useEffect(() => {
        refreshUsers()
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({target}) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const onSubmit = async (e) => {
        e.preventDefault();
        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        let url =  `bo/graduatesusers?${filtername}${filterlastname}${filteremail}${filterdocument}`
        const response = handleFetch({
            url: url,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                    setUsers(r.data)
                    setTotal(r.total)
                },
                error => {
                    console.log(error.errors);
                })
    }
    const clearAll = () => {
        setValue("id", '');
        setValue("firstName", '');
        setValue("lastName", '');
        setValue("userEmail", '');
        setValue("documentType", '');
        setValue("userDocument", '');
        setValue("type", '');
        setValue("descriptionplan", '');
        setValue("duration", '0');
        setValue("price", '0');
        setValue("title", '');
        setValue("namesegment", '');
        setValue("descriptionsegment", '');
        setValue("typesegment", '0');
        setValue("plansegment", '0');
        setValue("limitusersseg", '0');
        setValue("pricecourse", '0');
        setValue("canCertificate", false);
    }
    const handleSelectTipeDocumentChange = (selectedOption) => {
        if (selectedOption.target.value === 'DIE' || selectedOption.target.value === 'PA' || selectedOption.target.value === 'TI') {
            setShowAge(true);
        } else {
            setShowAge(false);
        }
        if (selectedOption.target.value === 'PA') {
            setDocPattern(alphanumericPattern);
            setDocMessage(alphanumericMessage);
        } else {
            setDocPattern(numericPattern);
            setDocMessage(numericMessage);
        }
    }
    // Función para retornar datos obtenidos en formulario
    const getRequestData = (formValues) => {
        return {
            name: formValues.firstName,
            lastname: formValues.lastName,
            email: formValues.userEmail,
            identificationType: formValues.documentType,
            identificationNumber: formValues.userDocument,
            type: 1
        }
    }  
    // Función mostrar modal mostrar tipo de usuarios eanistas y empresas b2b
    const handleOpenModalTypeUsersCSV = async () => {
        setShowModalTypeUsersCSV(true);
    }
    // Handle para asignar usuario al B2B
    const handleCreateUser = (formValues) => {
        let url = `bo/graduatesusers`
        handleFetch({
            url: url,
            method: 'POST',
            data: getRequestData(formValues),
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha añadido el usuario exitosamente al B2B.',
                    position: { horizontal: 'left' },
                });
                clearAll();
                refreshUsers();
                setShowModalCreateUser(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al agregar usuario al B2B. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    // Función mostrar modal en caso de registro usuario b2b
    const handleOpenModalCreateUser = async () => {
        setValue("firstName", '');
        setValue("lastName", '');
        setValue("userEmail", '');
        setValue("documentType", '');
        setValue("userDocument", '');
        setValue("descriptionplan", 'aux');
        setValue("duration", '0');
        setValue("price", '0');
        setValue("title", 'aux');
        setValue("namesegment", 'aux');
        setValue("descriptionsegment", 'aux');
        setValue("typesegment", '0');
        setValue("plansegment", '0');
        setValue("limitusersseg", '0');
        setValue("urlb2b", 'aux');
        setValue("pricecourse", '0');
        setValue("canCertificate", false);
        setShowModalCreateUser(true);
    }
    // Función mostrar modal mostrar tipo de usuarios eanistas y empresas b2b
    const handleCloseModalCreateUser = () => {
        setShowModalCreateUser(false);        
        clearAll();
    }
    // Función mostrar modal en caso de registro de usuarios b2b por lote
    const handleOpenModalCreateUserBatch = async () => {
        setShowModalCreateUserBatch(true);
    }
    const handleCloseModalCreateUserBatch = () => {
        setShowModalCreateUserBatch(false);
    }
    const handleCSVUpload = async (data) => {
        let band = false     
        const updatedValues = [];
        const errorValues = [];
        if(data[0].includes('name') && data[0].includes('lastname') && data[0].includes('email') && data[0].includes('identificationType') 
        && data[0].includes('identificationNumber') && data[0].includes('type')){
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Formato del CSV es Correcto',
                message: 'Se está procesando la carga del archivo, por favor espere',
                position: { horizontal: 'left' },
            });
            try {
                for (let i = 1; i < data.length; i++) {
                    const row = data[i];
                    await handleFetch({
                    url: `bo/graduatesusers`,
                    method: 'POST',
                    data: { name: row[0],
                            lastname: row[1],
                            email: row[2],
                            identificationType: row[3],
                            identificationNumber: row[4],
                            type: row[5] || 1}
                    })
                    .then((response) => {
                        console.log(response);
                        updatedValues.push({
                            name: response.name,
                            lastname: response.lastname,
                            email: response.email,
                            operation: 'USUARIO REGISTRADO EXITOSAMENTE',
                        });
                    })
                    .catch((error) => {
                        console.log("ERROR",error)
                        if(error?.errors[0]?.message === "email must be unique"){
                            setToastProperties({
                                show: true,
                                type: 'error',
                                message: 'Existen usuarios en el CSV ya registrados en Base de datos.',
                                position: { horizontal: 'left' },
                            });
                        }else{
                            errorValues.push({
                                name: row[0],
                                lastname: row[1],
                                email: row[2],
                                operation: 'ERROR AL REGISTRAR USUARIO',
                                });
                            setToastProperties({
                                show: true,
                                type: 'error',
                                message: 'Error al agregar usuarios al B2B. Intenta nuevamente.',
                                position: { horizontal: 'left' },
                            });

                        }
                    });
                }
                } catch (error) {
                console.error(error);
                }
            const csvData = [...updatedValues, ...errorValues];
            const csvContent = Papa.unparse(csvData, {
            quotes: true,
            quoteChar: '"',
            });
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'usuarios_graduados_lote_asignados.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            refreshUsers();
            setShowModalCreateUserBatch(false);
        }
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
        <Modal
                open={showModalCreateUserBatch}
                onClose={handleCloseModalCreateUserBatch}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-12'>
                            <div className='AcceptDialog__body'>Agregar Usuarios Graduados Por Lote a Base de Datos</div>
                            <div className="mt-2 mb-2">Haga clic en <a href={apiConfig.mercadoPago.returnUrl.replace('cuenta-creada','csv-graduados-lote.csv')} className="text_primary fw-bold B2BAdmin__link-to-csv">CSV</a> para descargar la plantilla en la que podrá incluir a los usuarios</div>
                        </div>
                        <div className='col-12'>
                            <div className="mt-1 mb-3 B2BAdmin__text-description">Para ver el listado de tipos de usuario y Códigos por Empresa haz <Link to={{}} className="text_primary fw-bold" onClick={handleOpenModalTypeUsersCSV}>click aquí</Link></div>
                        </div>
                        <div className='col-12'>
                            <CSVReader onFileLoaded={handleCSVUpload} />
                        </div>
                        <div className='B2BAdmin__text-description mt-3'><span className='auth__required-input'>* Esta ventana emergente se cerrará al finalizar la carga</span></div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={showModalCreateUser}
                onClose={handleCloseModalCreateUser}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleCreateUser)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar nuevo Graduado</div>
                                <div className="mb-3">
                                    <label className='auth__form-label'>Nombres <span className='auth__required-input'>*</span></label>
                                    <input
                                        type='text'
                                        name='firstName'
                                        className={`form-control auth__input 
                                ${errors.firstName ? "auth__input--error" : ""}`}
                                        placeholder={CONSTANTS.LABEL_NAME_PLACEHOLDER}
                                        {...register("firstName", {
                                            required: CONSTANTS.MESSAGE_NAME_REQUIRED,
                                            pattern: {
                                                value: namePattern,
                                                message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                            }
                                        })}
                                    />
                                    {errors.firstName && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.firstName.message}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className='auth__form-label'>Apellidos <span className='auth__required-input'>*</span></label>
                                    <input
                                        type='text'
                                        name='lastName'
                                        className={`form-control auth__input 
                                ${errors.lastName ? "auth__input--error" : ""}`}
                                        placeholder={CONSTANTS.LABEL_LASTNAME_PLACEHOLDER}
                                        {...register("lastName", {
                                            required: CONSTANTS.MESSAGE_LASTNAME_REQUIRED,
                                            pattern: {
                                                value: namePattern,
                                                message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                            }
                                        })}
                                    />
                                    {errors.lastName && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.lastName.message}
                                        </span>
                                    )}
                                </div>
                                
                                <div className="mb-3">
                                    <label className='auth__form-label'>Correo <span className='auth__required-input'>*</span></label>
                                    <input
                                        type='text'
                                        name='userEmail'
                                        className={`form-control auth__input 
                                ${errors.userEmail ? "auth__input--error" : ""}`}
                                        placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                                        {...register("userEmail", {
                                            required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                                            pattern: {
                                                value: CONSTANTS.PATTERN_EMAIL,
                                                message: CONSTANTS.MESSAGE_EMAIL_INVALID
                                            }
                                        })}
                                    />
                                    {errors.userEmail && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.userEmail.message}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className='auth__form-label'>Tipo de documento <span className='auth__required-input'>*</span></label>
                                    <select
                                        defaultValue=""
                                        className={`form-select auth__select 
                                ${errors.documentType ? "auth__input--error" : ""}`}
                                        aria-label="Default select example"
                                        name='documentType'
                                        {...register("documentType", {
                                            required: CONSTANTS.MESSAGE_DOCUMENT_TYPE_REQUIRED,
                                            onChange: handleSelectTipeDocumentChange
                                        })}
                                    >
                                        <option value="">Selecciona el tipo de documento</option>
                                        <option value="CC">Cédula de Ciudadanía</option>
                                        <option value="CE">Cédula de Extranjería</option>
                                        <option value="DIE">Documento de Identidad Extranjera</option>
                                        <option value="PA">Pasaporte</option>
                                        <option value="TI">Tarjeta de Identidad</option>
                                        <option value="PEP">Permiso Especial de Permanencia</option>
                                    </select>
                                    {errors.documentType && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.documentType.message}
                                        </span>
                                    )}
                                </div>
                                {
                                    showAge ? (
                                        <div className='show-form-age'>
                                            <div className="form-check">
                                                <input className="form-check-input auth__form-check"
                                                    type="checkbox"
                                                    value=""
                                                    {...register("olderThan12", { required: CONSTANTS.MESSAGE_OLDER_THAN_12_REQUIRED })}
                                                />
                                                <label className='form-check-label auth__form-label' style={{ fontSize: "12px" }}>
                                                    Declaro ser mayor de 12 años.
                                                </label>
                                            </div>
                                            <div>
                                                <label className='form-check-label auth__form-label' style={{ fontSize: "12px", paddingBottom: "12px" }}>
                                                    Si eres menor de 12 años requieres autorización expresa de tus padres o tutores,
                                                    pídeles que se comuniquen con nosotros <Link to='/contactanos' className='text_primary'>aquí.</Link>
                                                </label>
                                            </div>
                                            {errors.olderThan12 && (
                                                <div role="alert" className='auth__inputError'>
                                                    {errors.olderThan12.message}
                                                </div>
                                            )}
                                        </div>
                                    ) : null
                                }
                                <div className="mb-3">
                                    <label className='auth__form-label'>Número de documento <span className='auth__required-input'>*</span></label>
                                    <input
                                        type='text'
                                        name='userDocument'
                                        className={`form-control auth__input 
                                ${errors.userDocument ? "auth__input--error" : ""}`}
                                        placeholder={CONSTANTS.LABEL_DOCUMENT_PLACEHOLDER}
                                        {...register("userDocument", {
                                            required: CONSTANTS.MESSAGE_DOCUMENT_REQUIRED,
                                            pattern: {
                                                value: docPattern,
                                                message: docMessage
                                            }
                                        })}
                                    />
                                    {errors.userDocument && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.userDocument.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Agregar usuario</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCreateUser}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%"}}>Usuarios Graduados</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{marginTop: "30px"}}>
                        <Filters
                            downloadCSV={true}
                            filename={`listusers_graduados.csv`}
                            onSubmit={onSubmit}
                            data={usersFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='text' name='identificationNumber' value={dataFilter?.identificationNumber || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Correo</span>
                                <div>
                                    <input type='text' name='email' value={dataFilter?.email || ''} onChange={handleChangeFilter} placeholder="Correo electrónico" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "10px", textAlign: "right" }}>
                            { isAdmin && <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalCreateUser}>
                                    <span>Agregar Graduado</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalCreateUserBatch}>
                                    <span>Agregar Graduados Lote</span>
                                </Link>
                            </div> }
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{width: "90%", overflowX: "auto"}}>
                            {
                                data.loading?
                                    <Loading />
                                    :
                                    <UsersTable users={users} total={total} dataFilter={dataFilter} segment={segment} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} users Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({users, total, dataFilter, segment}) =>{

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name": "NOMBRE COMPLETO",
            "sort_name": "title",
            "width": "20%"
        },
        {
            "name": "CORREO ELECTRÓNICO",
            "sort_name": "enabled",
            "width": "25%"
        },
        {
            "name": "NÚMERO DE DOCUMENTO",
            "sort_name": "document",
            "width": "20%"
        },
        {
            "name": "FECHA INSCRIPCIÓN",
            "sort_name": "highlighted",
            "width": "15%"
        },
        {
            "name": "",
            "sort_name": "",
            "width": "10%"
        }
    ];

    const [usersSorted, setUsersSorted] = useState(users)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))    
    const [toastProperties, setToastProperties] = useState({show: false});
    const navigate = useNavigate();
    
    const refreshUsers = () => {
        let url =  `bo/graduatesusers`
        const responseusersb2b = handleFetch({
            url: url,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responseusersb2b
            .then((r) => {
                setUsersSorted(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }

    useEffect(() => {
        setUsersSorted(users)
        setTotalSorted(total)
    }, [users, total])

    const resetState = () =>{
        setUserHovered(Array(users.length).fill(false))
    }

    const handleActiveRow = (index)=>{
        let auxArray = Array(users.length).fill(false);
        auxArray[index] =  true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken }= useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        let url =  `bo/graduatesusers?${filtername}${filterlastname}${filteremail}${filterdocument}&$skip=${result}`
        setPage(event.selected)
            const response = handleFetch({
                url: url,
                method: 'GET',
                headers: {Authorization: `Bearer ${authToken}`}
            });
            response
                .then((r) => {
                        setUsersSorted(r.data)
                        setTotalSorted(r.total)
                    },
                    error => {
                        console.log(error.errors);
                        if(error.errors) {

                        }
                    })
    };
    const handleDeleteCourse = (id, event) => {
        event.preventDefault();
        let url =  `bo/graduatesusers/${id}`
        handleFetch({
            url: url,
            method: 'DELETE',
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha eliminado el usuario del B2B Exitosamente.',
                    position: { horizontal: 'left' },
                });
            refreshUsers(1);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al eliminar el usuario del B2B. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    return(
        <>
        <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        <table className='UserAdmin__table' >
            <thead className='UserAdmin__table-header' style={{height: "52px"}}>
            <tr style={{cursor: "pointer"}}>
                {headers.map((header, index)=>{
                    return(
                        <th key={index} className="overflow" style={{width: header.width, paddingLeft: "16px"}}>{header.name}</th>
                    )
                })}
            </tr>
            </thead>
            <tbody>
            {
                usersSorted.length!==0 ?
                    usersSorted.map((user, index)=>{
                        const segmentName = segment.find(
                            (item) => parseInt(item.type) === parseInt(user?.type),
                        );
                        return(
                            <tr onMouseLeave={resetState} onMouseEnter={()=>{handleActiveRow(index)}} className={`UserAdmin__table-row ${index%2 ===0 ? "UserAdmin__table-pair-row": "UserAdmin__table-odd-row" }`} key={index}>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user.name} {user.lastname}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user.email}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user.identificationType} {user.identificationNumber}</td>
                                <td className="overflow" style={{paddingLeft: "16px", textAlign: "center"}}>{new Date(user.createdAt).toISOString().split('T')[0]}</td>                        
                                <td className="overflow" style={{paddingLeft: "16px", textAlign: "center"}}><Link to={'#'} className="FilterAdmin__search-button" onClick={(event) => handleDeleteCourse(user.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></td>                        
                            </tr>
                        );
                    })
                    :
                    <tr>
                        <td colSpan="6" style={{padding: "16px", textAlign: "center"}}><strong>No se encontraron registros</strong></td>
                    </tr>
            }
            </tbody>
        </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
    </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{minHeight: "300px"}}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage}/>
            </div>
        </div>
    );
}