import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { useLocation, Link } from 'react-router-dom';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { useForm as hookUseForm } from 'react-hook-form';
import { Toast } from '../../controls/UIControls';
import * as CONSTANTS from '../../../assets/constants';
import Modal from '@mui/material/Modal';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2025<br>
 * Listado sección planes de estudio
 * @interface SucessStoriesSection
 */
export const SucessStoriesSection = () => {
    const { register, handleSubmit, setValue, formState: { errors } } = hookUseForm({ reValidateMode: "onChange" });
    const [waiting, setWaiting] = useState(false);
    const [data, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const location = useLocation();
    const { pathname } = location;
    const [showModalUpdated, setShowModalUpdated] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [viewActive, setViewActive] = useState(false);
    const [editSucSto, setEditSucSto] = useState(false)    
    const [ideditImg, setIdeditImg] = useState(0)
    const refreshCatalog = () => {
        // consulta de endpoint para historico de cursos inscritos por el usuario, se pasa por parámetro id del usuario
        const responsech = handleFetch({
            url: `bo/sucessstories`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responsech
            .then(async (r) => {
                setFilteredData(r?.data);
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    }
    // Función mostrar modal en caso de agregar
    const handleOpenModal = async () => {
        setIdeditImg(0)
        setEditSucSto(false);
        setViewActive(false);
        setShowModalUpdated(true);
    }
    const handleOpenModalEdit = (id, event) => {
        event.preventDefault();
        setValue("image", id.image);
        setValue("name", id.name);
        setValue("text", id.text);
        setEditSucSto(true);
        setIdeditImg(id.id);
        setShowModalUpdated(true);
    }
    
    const handleCloseModal = () => {
        setIdeditImg(0);
        setValue("image", "");
        setValue("name", "");
        setValue("text", "");
        setEditSucSto(false);
        setViewActive(false);
        setShowModalUpdated(false);
    }
    const getRequestDataImg = (formValues) => {
        return {
            image: formValues.image,
            name: formValues.name,
            text: formValues.text,
        }
    }
    // Handle para asignar casos de exito
    const handleAssignCourse = (formValues) => {
        setWaiting(true)
        handleFetch({
                url: 'bo/sucessstories' + (editSucSto ? `/${ideditImg}` : ''),
                method: editSucSto ? 'PATCH' : 'POST', 
                data: getRequestDataImg(formValues),
                headers: {Authorization: `Bearer ${authToken}`}
            })
            .then(r=>{
                setToastProperties({
                    show:true,
                    type:'success',
                    title: 'Operación exitosa',
                    message: 'El caso de éxito se guardó correctamente.',
                    position: {horizontal:'left'},
                })
                refreshCatalog();
            })
            .catch((error) => {
                console.log(error);
                setToastProperties({
                    show:true,
                    type:'error',
                    message: 'Error al guardar el caso de éxito. Intenta nuevamente.',
                    position: {horizontal:'left'},
                    })
            });
            setIdeditImg(0);
            setValue("image", "");
            setValue("name", "");
            setValue("text", "");
            setEditSucSto(false);
            setViewActive(false);
            setShowModalUpdated(false);
            setWaiting(false)
    }
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    //Handle Borrar curso de Casos de exito
    const handleDeleteCourse = (id, event) => {
        event.preventDefault();
        handleFetch({
            url:`bo/sucessstories/${id}`,
            method: 'DELETE',
            headers: { Authorization: `Bearer ${authToken}` }
        }).then(() => {
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Operación exitosa',
                message: 'Se ha eliminado el caso de éxito Exitosamente.',
                position: { horizontal: 'left' },
            });
            handleCloseModal();
        })
        .catch(() => {
            setToastProperties({
                show: true,
                type: 'error',
                message: 'Error al eliminar el caso de éxito. Intenta nuevamente.',
                position: { horizontal: 'left' },
            });
        });
    }
    useEffect(() => {
            refreshCatalog();
        }, [])
    const { authToken, getSessionUser } = useAuthToken(true);
    const sessionUser = getSessionUser();
    const isAdmin = sessionUser?.type === 0 ||  sessionUser?.type === 1;
    const headers = [
        {
            "name": "IMAGEN",
            "sort_name": "image",
            "width": "15%"
        },
        {
            "name": "NOMBRE",
            "sort_name": "name",
            "width": "25%"
        },
        {
            "name": "TESTIMONIO",
            "sort_name": "text",
            "width": "50%"
        },
        {
            "name": "ACCIÓN",
            "sort_name": "action",
            "width": "10%"
        }
    ];
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Casos de Éxito</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/casosexito'} className={`${pathname === '/admin/casosexito' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Casos de Éxito</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/cursosrecomendados'} className={`${pathname === '/admin/cursosrecomendados' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Cursos recomendados</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/landing'} className={`${pathname === '/admin/landing' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Landing</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "10px", textAlign: "right" }}>
                            { isAdmin && <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModal(event)}>
                                    <span>Añadir Caso de éxito</span>
                                </Link>
                            </div> }
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            <div className='mb-3'>
                                <table className='UserAdmin__table' >
                                    <thead className='UserAdmin__subtable-header' style={{ height: "52px" }}>
                                        <tr style={{ cursor: "pointer" }}>
                                            {headers.map((header, index) => {
                                                return (
                                                    <th key={index} style={{ width: header.width, paddingLeft: "16px", textAlign: "center" }}>{header.name}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filteredData.length !== 0 ?
                                                filteredData.map((cert, index) => {
                                                    return (
                                                        <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                            <td className="overflow" style={{ paddingLeft: "16px" }}><img src={cert?.image} border="0" height="100" /></td>
                                                            <td className="overflow" style={{ paddingLeft: "16px" }}>{cert.name}</td>
                                                            <td className="overflow" style={{ paddingLeft: "16px" }}>{cert.text}</td>
                                                            <td className="row justify-content-center" style={{ marginTop: "35px", textAlign: "center" }}>
                                                                <Link to={{}}  className="UserAdmin__button-container" onClick={(event) => handleOpenModalEdit(cert, event)}>
                                                                    <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                                </Link>
                                                                <Link to={{}} className="UserAdmin__button-container" onClick={(event) => handleDeleteCourse(cert.id, event)}>
                                                                    <i className='fa-solid fa-trash UserAdmin__color-primary'></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                :
                                                <tr>
                                                    <td colSpan="3" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={showModalUpdated}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleAssignCourse)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>{editSucSto===true?'Editar':'Agregar'} Caso de Éxito</div>
                                <div>
                                    <div className='mb-3'>
                                        <label className="form-label auth__form-label">Imágen de la persona</label>
                                        <input 
                                            placeholder={CONSTANTS.LABEL_URLIMG_SUCCESSSTORIES_PLACEHOLDER}
                                            {...register("image",{
                                                    required:CONSTANTS.MESSAGE_VALUE_INVALID})}
                                            className={`form-control PlanFormContainer__input ${errors.image?'PlanFormContainer__input--error':''}`}>
                                        </input>
                                        {errors.image && (
                                            <span role="alert" className='auth__inputError'>
                                                {errors.image.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className='mb-3'>
                                        <label className="form-label auth__form-label">Nombre de la persona</label>
                                        <input 
                                            placeholder={CONSTANTS.LABEL_NAME_SUCCESSSTORIES_PLACEHOLDER}
                                            {...register("name",{
                                                required:CONSTANTS.MESSAGE_VALUE_INVALID})} 
                                            className={`form-control PlanFormContainer__input ${errors.name?'PlanFormContainer__input--error':''}`}>
                                        </input>
                                        {errors.name && (
                                            <span role="alert" className='auth__inputError'>
                                                {errors.name.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className='mb-3'>
                                        <label className="form-label auth__form-label">Testimonio</label>
                                        <input 
                                            placeholder={CONSTANTS.LABEL_TEXT_SUCCESSSTORIES_PLACEHOLDER}
                                            {...register("text",{
                                                required:CONSTANTS.MESSAGE_VALUE_INVALID})}
                                            className={`form-control PlanFormContainer__input ${errors.text?'PlanFormContainer__input--error':''}`}>
                                        </input>
                                        {errors.text && (
                                            <span role="alert" className='auth__inputError'>
                                                {errors.text.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center mt-2'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit" disabled={waiting}>{editSucSto===true?'Editar':'Agregar'} caso de éxito{waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        </>
    )
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}