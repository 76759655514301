import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useForm as hookUseForm, Controller  } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Toast, PwdEye } from '../../controls/UIControls';
import Modal from '@mui/material/Modal';
import * as CONSTANTS from '../../../assets/constants';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2025<br>
 * Listado sección usuarios backoffice
 * @interface ListProfileSection
 */
export const ListProfileSection = () => {

    const { register, handleSubmit, setValue, getValues, control, formState: { errors } } = hookUseForm({ reValidateMode: "onChange" });
    const [bousers, setBousers] = useState([]);
    const [bousersFull, setBousersFull] = useState([]);
    const [b2b, setB2b] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });    
    const [showModalCreateUserBO, setShowModalCreateUserBO] = useState(false);
    const [page, setPage] = useState(0);
    const [typeDiscount, setTypeDiscount] = useState(0)
    const [isEditing, setIsEditing] = useState(false)
    const [idEdit, setIdEdit] = useState(0)
    const [dataFilter, setDataFilter] = useState({
        name: '',
        type: '',
        since: '',
        price: '',
    })
    const { authToken, getSessionUser } = useAuthToken(true);
    const sessionUser = getSessionUser();
    const isAdmin = sessionUser.type === 0 ||  sessionUser.type === 1;
    
    const tipousuario = [
        { 0: ["Super Administrador"] },
        { 1: ["Soporte"] },
        { 2: ["Administrador"] },
        { 3: ["Observador"] }
    ];
    // Handle Refrescar Descuentos
    const refreshBOUsers = () => {
        let filtername = dataFilter.name !== '' ? `&code=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''        
        let filterprice = dataFilter.price !== '' ? "&price=" + dataFilter.price : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        const response = handleFetch({
            url: `bo/bousers?${filtername}${filtertype}${filtersince}${filterprice}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setBousers(r?.data)
                setTotal(r?.total)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const refreshB2B = () => {
        // consulta de endpoint para los B2B disponibles
        const responseb2b = handleFetch({
            url: `bo/companiesb2b`,
            method: 'GET',            
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responseb2b
            .then((r) => {
                setB2b(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    // Handle cerrar toast
    
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    // Consulta de endpoint listado de usuarios backoffice
    useEffect(() => {
        refreshBOUsers()
        refreshB2B()
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]

    // Función mostrar modal en caso de registro plan b2b
    const handleOpenModalCreateUserBO = async () => {
        setIsEditing(false)
        setValue("name", '');
        setValue("lastname", '');
        setValue("email", '');
        setValue("password", '');
        setValue("type", 1);
        setValue("companyId", 1);
        setShowModalCreateUserBO(true);
    }
    // Función mostrar modal en caso de registro plan b2b
    const handleCloseModalCreateUserBO = async () => {
        setShowModalCreateUserBO(false);
    }
    // Handle para crear nuevos usuarios backoffice
    const handleCreateUserBO = (formValues) => {
        const method = isEditing ? 'PATCH' : 'POST';
        const url = 'bo/bousers' + (isEditing ? `/${idEdit}` : '');
        handleFetch({
            url: url,
            method: method,
            data: getRequestData(formValues),
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha añadido el usuario de backoffice exitosamente.',
                    position: { horizontal: 'left' },
                });
                refreshBOUsers()
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al agregar el usuario de backoffice. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
            setShowModalCreateUserBO(false);
    }
    // Función para retornar datos obtenidos en formulario
    const getRequestData = (formValues) => {
        return {
            name: formValues.name,
            lastname: formValues.lastname,
            email: formValues.email,
            companyId: formValues.companyId,
            password: formValues.password,
            type: formValues.type,
        }
    } 
    // Header para listado de usuarios backoffice
    const headers = [
        {
            "name": "NOMBRE COMPLETO",
            "sort_name": "name",
            "width": "20%"
        },
        {
            "name": "CORREO ELECTRONICO",
            "sort_name": "email",
            "width": "25%"
        },
        {
            "name": "COMPANIA B2B",
            "sort_name": "b2b",
            "width": "25%"
        },
        {
            "name": "TIPO",
            "sort_name": "type",
            "width": "20%"
        }
    ];
    // Función validar tipo de descuento
    const handleSelectChange = (selectedOption) => {
        if(selectedOption.target.value == 0) {
            setTypeDiscount(0);
        }else {
            setTypeDiscount(1);
        }
    }
    // Función mostrar modal en caso de editar usuario BO
    const handleOpenModalEditSegment = (id, event) => {
        event.preventDefault();
        setIsEditing(true)
        setIdEdit(id);
        const userbodata = bousers.find(
            (item) => parseInt(item.id) === id,
        );
        setValue("name", userbodata.name);
        setValue("lastname", userbodata.lastname);
        setValue("email", userbodata.email);
        setValue("type", userbodata.type);
        setValue("companyId", userbodata.companyId);
        setShowModalCreateUserBO(true);
    }
    const handleDeleteSegment = (id, event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/bousers/${id}`,
            method: 'DELETE',            
            headers: {Authorization: `Bearer ${authToken}`} 
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha eliminado el usuario BO Exitosamente.',
                    position: { horizontal: 'left' },
                });
                refreshBOUsers()
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al eliminar el usuario BO seleccionado. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    // Función para activar filtros, recibe parametros del mismo
    const onSubmit = async (e) => {
        e.preventDefault();
        refreshBOUsers();
    }
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&code=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''        
        let filterprice = dataFilter.price !== '' ? "&price=" + dataFilter.price : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/bousers?&$skip=${result}${filtername}${filtertype}${filtersince}${filterprice}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setBousers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalCreateUserBO}
                onClose={handleCloseModalCreateUserBO}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleCreateUserBO)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>{isEditing ? 'Editar' : 'Agregar'} usuario de backoffice</div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                                <label className="form-label auth__form-label">Nombre <span className='auth__required-input'>*</span></label>
                                            <input 
                                                placeholder={CONSTANTS.LABEL_NAME_PLACEHOLDER}
                                                {...register("name",{required:CONSTANTS.MESSAGE_NAME_REQUIRED})} 
                                                className={`form-control PlanFormContainer__input ${errors.name?'PlanFormContainer__input--error':''}`}>
                                            </input>
                                            {errors.name && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.name.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label auth__form-label">Apellido <span className='auth__required-input'>*</span></label>
                                                <input 
                                                    placeholder={CONSTANTS.LABEL_LASTNAME_PLACEHOLDER}
                                                    type="lastname"
                                                    {...register("lastname",{
                                                        required:CONSTANTS.MESSAGE_LASTNAME_REQUIRED})} 
                                                    className={`form-control PlanFormContainer__input ${errors.lastname?'PlanFormContainer__input--error':''}`}
                                                ></input>                              
                                                {errors.lastname && (
                                                        <span role="alert" className='auth__inputError'>
                                                            {errors.lastname.message}
                                                        </span>
                                                    )}
                                        </div>    
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                                <label className="form-label auth__form-label">Correo electrónico <span className='auth__required-input'>*</span></label>
                                                <input 
                                                    placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                                                    {...register("email", { required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                                                        pattern: {
                                                            value:  CONSTANTS.PATTERN_EMAIL ,
                                                            message:  CONSTANTS.MESSAGE_EMAIL_INVALID
                                                        }})}
                                                className={`form-control PlanFormContainer__input ${errors.email?'PlanFormContainer__input--error':''}`}>
                                            </input>
                                            {errors.email && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.email.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label auth__form-label">Contraseña <span className='auth__required-input'>*</span></label>
                                            <Controller
                                                name="password"
                                                control={control}
                                                rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                                                render={({ field: { onChange, onBlur, name }, fieldState: { invalid } }) => {
                                                    return (
                                                        <PwdEye
                                                            className={`form-control auth__input ${invalid ? "auth__input--error" : ""}`}
                                                            placeholder={CONSTANTS.LABEL_PASSWORD_PLACEHOLDER}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            name={name}
                                                            error={invalid}
                                                        >
                                                        </PwdEye>)
                                                }
                                                }
                                            />
                                        </div>    
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                        <label className="form-label auth__form-label">Compañía B2B</label>
                                            <select {...register("companyId")} onChange={handleSelectChange} className="form-select PlanFormContainer__input">
                                                { b2b.length !== 0 && b2b.map((companie) => {
                                                        return (<option key={companie.id} value={companie.id}>{companie.name}</option>)
                                                    })}
                                            </select>
                                        </div>
                                        <div className="col-6">
                                                <label className="form-label auth__form-label">Tipo</label>
                                            <select {...register("type")} onChange={handleSelectChange} className="form-select PlanFormContainer__input">
                                            {tipousuario.length > 1 && tipousuario.slice(1).map((item, index) => {
                                                const key = Object.keys(item)[0];
                                                return (
                                                    <option key={key} value={key}>{item[key][0]}</option>
                                                );
                                            })}

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">{isEditing ? 'Editar' : 'Agregar'} Usuario BO</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCreateUserBO}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Perfiles de usuarios Backoffice</h1>
                        </div>
                    </div>
                    {/*<div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listbousers.csv"}
                            onSubmit={onSubmit}
                            data={bousers}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Códigos de descuento</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Códigos de descuento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Tipo de descuento</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='type'
                                        value={dataFilter?.type || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value='0'>Precio</option>
                                        <option value='1'>Porcentaje</option>
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Precio</span>
                                <div>
                                    <input type='number' name='price' value={dataFilter?.price || ''} onChange={handleChangeFilter} placeholder="Precio" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>*/}
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "10px", textAlign: "right" }}>
                            { isAdmin && <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalCreateUserBO}>
                                    <span>Agregar usuario BO</span>
                                </Link>
                            </div> }
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "1px", overflowX: "auto" }}>
                            <table className='UserAdmin__table' >
                                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                                    <tr style={{ cursor: "pointer" }}>
                                        {headers.map((header, index) => {
                                            return (
                                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                                            )
                                        })}
                                        <th style={{ width: "13%" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bousers.length != 0 ?
                                            bousers.map((bou, index) => {
                                                  const tipoNombre = tipousuario.find(obj => obj[bou.type])?.[bou.type]?.[0];
                                                  const companyName = b2b.find(
                                                    (item) => parseInt(item.id) === bou.companyId,
                                                  );
                                                return (
                                                    <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{bou.name} {bou.lastname}</td>
                                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{bou.email}</td>
                                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{companyName?.name}</td>
                                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{tipoNombre}</td>
                                                        <td className="d-flex justify-content-center" style={{ paddingLeft: "16px", textAlign: "center" }}>{ isAdmin && <><Link to={'#'} className="FilterAdmin__option-button mt-2" onClick={(event) => handleOpenModalEditSegment(bou.id, event)}><i className='fa-solid fa-pen UserAdmin__color-red'></i></Link> <Link to={'#'} className="FilterAdmin__option-button mt-2" onClick={(event) => handleDeleteSegment(bou.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></> }</td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            <tr>
                                                <td colSpan="6" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                                            </tr>
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                itemsPerPage={50}
                                totalElements={total}
                                onChangePage={handlePageClick}
                                forcePage={page}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}