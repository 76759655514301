import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useFetch } from '../../hooks/useFetch';
import { MessageDialog, AuthFormContainerPage } from '../controls/UIControls';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthToken } from '../../hooks/useAuthToken';
import { UserContext } from '../../context/UserContext';
import * as CONSTANTS from '../../assets/constants';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para mensaje de error
 * @param {props} props Contenido para mensaje de error (className, message)
 * @function ErrorMessage
 */
const ErrorMessage = (props) => {
    return (
        <div className={`auth__errorMessage ${props.className}`}>
            <span role="alert" dangerouslySetInnerHTML={{ __html: props.message }}>
            </span>
        </div>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función de búsqueda de usuario eanista
 * @param {props} props Props utilizados para el formulario de búsqueda
 * @function SearchUserForm
 */
const SearchUserForm =  (props) => {
    const navigate = useNavigate();
    const numericPattern = CONSTANTS.PATTERN_NUMERIC;
    const alphanumericPattern = CONSTANTS.PATTERN_ALPHANUMERIC;

    const numericMessage=CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const alphanumericMessage=CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [userDocument, setUserDocument] = useState('');
    const [fetchData, handleFetch] = useFetch();
    const [loginError, setLoginError] = useState('');
    const {user, setUser} = useContext(UserContext);
    const [docPattern, setDocPattern] = useState(numericPattern);
    const [docMessage, setDocMessage] = useState(numericMessage);

    const {getSessionUser} = useAuthToken();

    const [dialogProperties, setDialogProperties] = useState({open:false});

    //Función de retorno a sección de contacto si un estudiante no aparece en base de datos
    const afterCloseErrorDialog = () => {
        setDialogProperties({open:false});
        navigate("/contactanos", { state: { eanuser: {subject: "Soy Comunidad Eanista", message: "Soy Comunidad Eanista, necesito ayuda"} } });
    }
    const handleSelectChange = (selectedOption) => {
        if(selectedOption.target.value === 'PA' || selectedOption.target.value === 'DNI') {
            setDocPattern(alphanumericPattern);
        } else {
            setDocPattern(numericPattern);
        }
    }
    // Función retorno al verificar usuario
    const afterUserVerified = (usr) => {
        const user = getSessionUser();
        if(user!==undefined) {
            switch(user.type) {
                case CONSTANTS.USER_TYPE_EAN_STUDENT:
                case CONSTANTS.USER_TYPE_EAN_PAYROLL_TEACHER:
                case CONSTANTS.USER_TYPE_EAN_SUBJECT_TEACHER:
                case CONSTANTS.USER_TYPE_EAN_COLLABORATOR:
                    if(usr.identificationNumber == user.document){
                        navigate('/cuenta-creada', {state:{user, plan: usr.plan}});
                    }else{
                        setDialogProperties({open:true,
                            title: 'No podemos encontrarte',
                            message:'Actualmente no te encuentras registrado \n \n \n' +
                                'como parte de la Comunidad Eanista (estudiante activo, docente y/o colaborador)',
                            messagethree: 'No soy Comunidad Eanista y',
                            messagelink: 'quiero adquirir un plan',
                            link: '/planes',
                            afterClose: afterCloseErrorDialog,
                            button_text: 'Si soy Comunidad Eanista',
                            showCloseButton:true,
                            cancelClose: ()=>setDialogProperties({open:false}),
                            style:'error',
                        });
                    }
                    break;
                default:
                    navigate('/planes');
            }
        } else {
            navigate(`../actualizar`, { state: { plan: usr.plan, eanuser: usr } });
        }
    }
    // Handle para consulta si existe usuario eanista, recibe por parámetro número de documento
    const handleSearchUser = (formValues) => {
        const response = handleFetch({
            url: `users/eanx_users?identificationNumber=${formValues.userDocument}`,
            method: 'GET',
        });
        response
            .then((r) => {
                    afterUserVerified(r);
                },
                error => {
                    setDialogProperties({open:true,
                        title: 'No podemos encontrarte',
                        message:'Actualmente no te encuentras registrado \n \n \n' +
                            'como parte de la Comunidad Eanista (estudiante activo, docente y/o colaborador)',
                        messagethree: 'No soy Comunidad Eanista y',
                        messagelink: 'quiero adquirir un plan',
                        link: '/planes',
                        afterClose: afterCloseErrorDialog,
                        button_text: 'Si soy Comunidad Eanista',
                        showCloseButton:true,
                        cancelClose: ()=>setDialogProperties({open:false}),
                        style:'error',
                    });
                })
    }
    return (
        <>
        <MessageDialog {...dialogProperties}></MessageDialog>
        <form onSubmit={ handleSubmit(handleSearchUser) }>
            <div className="mb-1">
                <label className='auth__form-label'>Tipo de identificación</label>
                <select
                    defaultValue='CC'
                    className={`form-select auth__select`}
                    name='documentType'
                    onChange={handleSelectChange}
                >
                    <option value="">Selecciona el tipo de documento</option>
                    <option value="CC">Cédula de Ciudadanía</option>
                    <option value="CE">Cédula de Extranjería</option>
                    <option value="DIE">Documento de Identidad Extranjera</option>
                    <option value="PA">Pasaporte</option>
                    <option value="TI">Tarjeta de Identidad</option>
                    <option value="PEP">Permiso Especial de Permanencia</option>
                    <option value="DNI">Documento nacional de identidad</option>
                    <option value="IPJ">Identificador de persona jurídica</option>
                    <option value="CERC">Certificado Cabildo</option>
                    <option value="NIP">Número Identificación Personal</option>
                    <option value="NSE">Número por Secretaría de Educación</option>
                    <option value="NUIP">Número Único Identificación Personal</option>
                    <option value="REGC">Registro Civil</option>
                    <option value="SNP">Registro SNP</option>
                    <option value="TP">Tarjeta profesional</option>
                    <option value="Visa">Visa</option>
                    <option value="NIT">N.I.T.</option>
                    <option value="PPT">Permiso por Protección Temporal</option>
                </select>
                <ErrorMessage />
            </div>
            <div className="mb-1">
                <label className='auth__form-label'>Número de identificación</label>
                <input
                    type='text'
                    name='userDocument'
                    className={`form-control auth__input 
                                ${errors.userDocument?"auth__input--error":""}`}
                    placeholder={CONSTANTS.LABEL_DOCUMENT_PLACEHOLDER}
                    {...register("userDocument", {
                        required: CONSTANTS.MESSAGE_DOCUMENT_REQUIRED,
                        pattern: {
                            value: docPattern,
                            message: docMessage
                        } })}
                />
                {errors.userDocument && (
                    <span role="alert" className='auth__inputError'>
                    {errors.userDocument.message}
                    </span>
                )}
                <ErrorMessage />
            </div>
            <div>
                <span style={{fontSize: "14px", etterSpacing: "-1%", textAlign: "justify"}}>
                    <input type="checkbox" value="" {...register("acceptPolicy", {required:CONSTANTS.MESSAGE_ACCEPT_POLICY_REQUIRED})}/> Tengo a disposición la <a href='https://universidadean.edu.co/sites/default/files/institucion/acuerdos/politica-tratamiento-de-datos-personales.pdf' className='auth__link'>Política de tratamiento de datos personales</a>&nbsp;
                    y el <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/aviso-de-privacidad' className='auth__link'>Avíso de privacidad</a> de la Universidad Ean
                    y con fundamento en ello, <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/autorizacion-para-uso-de-datos-personales' className='auth__link'>autorizo</a> a la Universidad Ean de manera voluntaria, explícita, informada e inequívoca el
                    tratamiento de mis datos personales.</span>
            </div>
            {errors.acceptPolicy && (
                <div role="alert" className='auth__inputError'>
                    {errors.acceptPolicy.message}
                </div>
            )}

            <button
                type="submit"
                className={`mb-1 auth__btn-form`}
            >
                <span>Enviar</span>
            </button>
            <button className='ActionButton ActionButton--secondary' onClick={()=>{navigate('/')}}>Volver al inicio</button>
            <div className='text-center mt-4'>
                No soy Comunidad Eanista, <Link className='auth__link' to="/planes">adquirir un plan</Link>
            </div>
        </form>
    </>
    )
}
/**
* Creado por Equilibrio Agency 2022<br>
* Página verificación de usuario
* @param {string} setAuthToken Token de sesión
* @return {HTMLElement} Elementos HTML de cada componente
* @interface IdVerificationPage
*/
export const IdVerificationPage = ({setAuthToken}) => {
    const searchUserForm = (<>
        <SearchUserForm setAuthToken={ setAuthToken } />
    </>)
    return (
        <>
            <AuthFormContainerPage title="Identifícate" content={searchUserForm}></AuthFormContainerPage>
        </>
    )
}
