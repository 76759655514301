import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useLocation, Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { AcceptDialog, Toast } from '../../controls/UIControls';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2024<br>
 * Listado sección certificados
 * @interface CertificationGeneSection
 */
export const CertificationGeneSection = () => {

    const [certificates, setCertificates] = useState([]);
    const [certificatesFull, setCertificatesFull] = useState([]);    
    const [fullcatalog, setFullcatalog] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const location = useLocation();
    const { pathname } = location;

    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const [dataFilter, setDataFilter] = useState({
        name: '',
        lastname: '',
        document: '',
        course: '',
        since: '',
    })
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        setToastProperties(location.state?.toastProperties || { show: false });
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? `&lastname=` + dataFilter.lastname : ''
        let filterdocument = dataFilter.document !== '' ? `&document=` + dataFilter.document : ''
        let filtercourse = dataFilter.course !== '' ? `&course=` + dataFilter.course : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        const response = handleFetch({
            url: `bo/reportscourses?${filtername}${filterlastname}${filterdocument}${filtercourse}${filtersince}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setCertificates(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecsv = handleFetch({
            url: `bo/reportscourses?$limit=50000${filtername}${filterlastname}${filterdocument}${filtercourse}${filtersince}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecsv
            .then((r) => {
                setCertificatesFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecatalog = handleFetch({
            url: `catalog?$limit=500&$skip=0`,
            method: 'GET',
        });
        responsecatalog
            .then((rc) => {
                setFullcatalog(rc.data);
            },
            error => {
                console.log(error.errors);
                if (error.errors) {

                }
            })
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? `&lastname=` + dataFilter.lastname : ''
        let filterdocument = dataFilter.document !== '' ? `&document=` + dataFilter.document : ''
        let filtercourse = dataFilter.course !== '' ? `&course=` + dataFilter.course : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        const response = handleFetch({
            url: `bo/reportscourses?${filtername}${filterlastname}${filterdocument}${filtercourse}${filtersince}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setCertificates(r.data)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecsv = handleFetch({
            url: `bo/reportscourses?$limit=50000${filtername}${filterlastname}${filterdocument}${filtercourse}${filtersince}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecsv
            .then((r) => {
                setCertificatesFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const vars = [
        {
            "name": "certificates",
            "title": "Certificados",
        },
        {
            "name": "badges",
            "title": "Insignias",
        },
        {
            "name": "courses",
            "title": "Cursos",
        },
        {
            "name": "certificates",
            "title": "Progreso de usuarios",
        },
        {
            "name": "",
            "title": "Error",
        }
    ]
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Certificados Generados</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/certieanx'} className={`${pathname == '/admin/certieanx' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados EanX</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/plantillascertieanx'} className={`${pathname == '/admin/plantillascertieanx' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Plantillas EanX</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/certigenerados'} className={`${pathname == '/admin/certigenerados' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados Generados</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listcertifications.csv"}
                            onSubmit={handleSubmit}
                            data={certificatesFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='text' name='document' value={dataFilter?.document || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Curso realizado</span>
                                <div>
                                    <select name="course" className="form-select FilterAdmin__input"
                                        value={dataFilter?.course || ''} onChange={handleChangeFilter}>
                                            <option value="" selected>Seleccionar un curso</option>
                                        {
                                            fullcatalog.length !== 0 &&
                                            fullcatalog.slice()
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((data, index) => {
                                                return (
                                                    <option value={data?.courseId} key={data?.id}>{data?.name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'  style={{ marginTop: "10px"}}>
                        <div className='' style={{ width: "90%", marginTop: "1px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable certificates={certificates} total={total} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} certificates Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ certificates, total, dataFilter }) => {

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name": "NOMBRE COMPLETO",
            "sort_name": "name",
            "width": "25%"
        },
        {
            "name": "DOCUMENTO",
            "sort_name": "document",
            "width": "15%"
        },
        {
            "name": "NOMBRE CURSO",
            "sort_name": "title",
            "width": "35%"
        },
        {
            "name": "ESTADO",
            "sort_name": "type",
            "width": "10%"
        },
        {
            "name": "FECHA CREACIÓN",
            "sort_name": "date",
            "width": "10%"
        }
    ];

    const [certificatesSorted, setCertificatesSorted] = useState(certificates)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(certificates.length).fill(false))
    const [toastProperties, setToastProperties] = useState({ show: false });
    const [dialogProperties, setDialogProperties] = useState({ open: false });
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }

    useEffect(() => {
        setCertificatesSorted(certificates)
        setTotalSorted(total)
    }, [certificates, total])

    const resetState = () => {
        setUserHovered(Array(certificates.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(certificates.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? `&lastname=` + dataFilter.lastname : ''
        let filterdocument = dataFilter.document !== '' ? `&document=` + dataFilter.document : ''
        let filtercourse = dataFilter.course !== '' ? `&course=` + dataFilter.course : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/reportscourses?&$skip=${result}${filtername}${filterlastname}${filterdocument}${filtercourse}${filtersince}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setCertificatesSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <AcceptDialog {...dialogProperties}></AcceptDialog>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        certificatesSorted.length != 0 ?
                        certificatesSorted.map((cert, index) => {
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert['webuser.name']} {cert['webuser.lastname']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert['webuser.document']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert['course.title']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert['attr.type'] == 1 ? 'Certificado' : 'Insignia'}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{new Date(cert.createdAt).toISOString().split('T')[0]}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}><a href={cert['users_studies_certification.certificationUrl']}><i className='fa-solid fa-download UserAdmin__color-primary'></i></a></td>
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="5" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}