import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useFetch } from '../../hooks/useFetch';
import { useUserPlan } from '../../hooks/useUserPlan';
import { PlanCard } from '../landing/PlanCard';

const carousel_properties_mobile = {
    preventMovementUntilSwipeScrollTolerance: true,
    showStatus: false,
    swipeScrollTolerance: 100,
    swipeable: true,
    showThumbs: false,
    autoPlay: true,
    interval: 5000,
    transitionTime: 1000,
    infiniteLoop: true
}

const indicatorStyles = {
  background: '#6E7274',
  width: 8,
  height: 8,
  display: 'inline-block',
  margin: '0 8px',
  borderRadius: "100px"
};

const DURATION_DAYS = 1;
const DURATION_WEEKS = 2;
const DURATION_MONTHS = 3;
const DURATION_YEARS = 4;
const CURRENCY = 'COP';
const CURRENCY_SYMBOL = '$';
const STYLE_NORMAL = 0;
const STYLE_HIGHLIGHT = 1;

export const PlanPageGraduates = () => {
  const [plans, setPlans] = useState([]);
  const [plansWithoutFree, setPlansWithoutFree] = useState([]);
  const [plansWithoutFreeReverse, setPlansWithoutFreeReverse] = useState([]);
  const [data, handleFetch] = useFetch();
  const {checkUserPlan} = useUserPlan();
  const { isLogged, authToken } = useAuthToken();
  function sliceIntoChunks(arr, chunkSize) {

    const res = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }

    return res;
  }

  const plans_sliced_two =  sliceIntoChunks(plansWithoutFree, 2);
  const plans_sliced_three =  sliceIntoChunks(plansWithoutFree, 3);
  const plans_sliced_four =  sliceIntoChunks(plansWithoutFree, 4);
  const [planSelected, setPlanSelected] = useState(plans.map(p => p.selected));
  
  const onCardEnter = (index) =>{
    let newArr = [false, false, false, false];
    newArr[index] = true;
    setPlanSelected(newArr);
  } 
  
  const resetState = () =>{
    setPlanSelected([false, false, true, false]);
  }

  const getDuration = plan => {
    let d = plan.duration.toString();
    switch(plan.durationPeriod) {
      case DURATION_DAYS: // Días
        d+=" día"+((plan.duration>1)?"s":"");
        break;
      case DURATION_WEEKS: // Semanas
        d+=" semana"+((plan.duration>1)?"s":"");
        break;
      case DURATION_MONTHS: // Meses
        d+=" mes"+((plan.duration>1)?"es":"");
        break;
      case DURATION_YEARS: // Años
        d+=" año"+((plan.duration>1)?"s":"");
        break;
    }
    return d;
  };

  // Obtiene el precio mensual o total
  const getPrice = (plan, byMonth = false) => {
    let formattedPrice;

    const fn = Intl.NumberFormat('es-CO', 
                                  {
                                    maximumFractionDigits:0,
                                  });
    if(byMonth) {
      if(plan.durationPeriod<DURATION_MONTHS ||  // La duración está codificada de manera que un período más corto tiene un código menor
              (plan.durationPeriod===DURATION_MONTHS && plan.duration === 1)) {
        formattedPrice = false;
      } else {
        let monthsPerPeriod;
        switch(plan.durationPeriod) {
          case DURATION_MONTHS:
            monthsPerPeriod = 1;
            break;
          case DURATION_YEARS:
            monthsPerPeriod = 12;
            break;
        }
        //formattedPrice =  CURRENCY + ' '+ CURRENCY_SYMBOL +fn.format(plan.price/(plan.duration*monthsPerPeriod));
        formattedPrice =  CURRENCY + ' '+ CURRENCY_SYMBOL +fn.format(plan.price);
      }
    } else {
      formattedPrice = CURRENCY + ' '+ CURRENCY_SYMBOL +fn.format(plan.price);
    }
    return formattedPrice;
  }

  const  getStyle = (plan) => {
    let style;
    switch(plan.call_to_action_style) {
      case STYLE_HIGHLIGHT:
        style="Green";
        break;
      case STYLE_NORMAL:
      default:
        style="White";
    }
    return style;
  }

  let hasPlan = false;

  useEffect(()=> {
    handleFetch({
      url:'bo/plans?$sort[price]=1&$sort[durationPeriod]=1&$sort[duration]=1',
      method: 'GET'
    })
    .then (async plans => {
      let availPlans = plans.map((plan)=> {
        return {
            "button_description": plan.call_to_action_text,
            "button_color": getStyle(plan),
            "description": plan.description,
            "duration": getDuration(plan),
            "durationText": plan.durationText,
            "id": plan.id,
            "name": plan.title.toUpperCase(),
            "price": getPrice(plan),
            "selected": plan.highlighted,
            "top": plan.highlighted,
            "monthly_price": getPrice(plan, true),
            "plan_price": plan.price,
            "plan_duration": plan.duration,
            "plan_duration_period":plan.durationPeriod,
            "benefits": plan.benefits,
          }
      });
      let filtrados = availPlans.filter(el => el.name.toLowerCase().includes("graduados"));
      let availPlanstwo = filtrados;
      if(isLogged()) {
        hasPlan = await checkUserPlan(authToken);
      }
      availPlanstwo = availPlanstwo.filter(p => p.plan_price>0);
      setPlansWithoutFree(availPlanstwo);
      let aux = availPlanstwo;
      let auxd = availPlanstwo;
      aux.sort((a, b) => {
        if (a.selected && !b.selected) {
          return 1;
        } else if (!a.selected && b.selected) {
          return -1;
        }
        return 0;
      });
      auxd.sort((a, b) => {
        if (a.plan_price && b.plan_price) {
          return a.plan_price - b.plan_price;
        } else if (a.plan_price) {
          return -1;
        } else if (b.plan_price) {
          return 1;
        }
        return 0;
      });      
      setPlansWithoutFree(aux)
      setPlansWithoutFreeReverse(auxd);
      setPlans(availPlans);
    });
  },[]);

  return (
    <div style={{marginTop: '70px'}}>
      <div className='container mt-3'>
          <div className='row justify-content-center text-center'>
              <div className='col-12 col-md-8 col-lg-6 '>
                  <p className='plan__h3'>Planes para Graduados</p>
              </div>
          </div>
      </div>
      <div className='container-fluid d-none d-lg-flex justify-content-center'>
        <div className='row plan__container-width'>
        <Carousel autoFocus={true} showThumbs={false} showStatus={false} useKeyboardArrows className="presentation-mode" 
        showArrows={true}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
              return (
                  <li
                      style={{ ...indicatorStyles, background: '#3BAC53' }}
                      aria-label={`Selected: ${label} ${index + 1}`}
                      title={`Selected: ${label} ${index + 1}`}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                  />
              );
          }
          return (
              <li
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
              />
          );
      }}>
          { plans_sliced_four.map((plan_, index) =>{
            return(<div key={"content-"+index} className='d-flex justify-content-center h-full' >
                {
                    plan_.map( (plan,index2) => (
                      <PlanCard key={plan.id} {...plan} planId={plan.id} index={parseInt(index+""+index2,2)} isPlanSelected={planSelected[parseInt(index+""+index2,2)]} onCardEnter={onCardEnter} resetState={resetState}/>
                      ))
                }
                </div>)})
          }
        </Carousel>
        </div>
      </div>
      <div className=' d-none d-sm-block d-lg-none'>
        <Carousel {...carousel_properties_mobile}
          showArrows={false}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                  return (
                    
                      <li
                          style={{ ...indicatorStyles, background: '#3BAC53' }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                      />
                  );
              }
              return (
                  <li
                      style={indicatorStyles}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                  />
              );
          }}
        
        
        
        >
          {

          
           plans_sliced_two.map((plans, index) =>{

            return(<div className='d-flex justify-content-center h-full' key={index}>
                        {
                            plans.map( (plan,index2) => (
                              <PlanCard key={plan.id} {...plan} planId={plan.id} index={parseInt(index+""+index2,2)} isPlanSelected={planSelected[parseInt(index+""+index2,2)]} onCardEnter={onCardEnter} resetState={resetState}/>
                              ))
                        }
                    </div>
            )

            })
        
          }
        </Carousel>
      </div>
      <div className='row justify-content-center'>
        <div className='col-10'>
      <div className=' d-block d-sm-none'>
        <Carousel {...carousel_properties_mobile}
        
          showArrows={false}
        
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                  return (
                    
                      <li
                          style={{ ...indicatorStyles, background: '#3BAC53' }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                      />
                  );
              }
              return (
                  <li
                      style={indicatorStyles}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                  />
              );
          }}



        >
        {
            plansWithoutFreeReverse.map((plan, index) =>(
              <div className='row justify-content-center CourseCategoryCard__mb-100px' key={plan.id}>
                <PlanCard key={plan.id} {...plan} planId={plan.id} index={index} isPlanSelected={planSelected[index]} onCardEnter={onCardEnter} resetState={resetState}/>
              </div>
              ))
          }
        </Carousel>
      </div>
      </div>
      </div>
    </div>
  )
}
export { PlanPageGraduates as default } from "./PlanPageGraduates.js";
