import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Toast } from '../../controls/UIControls';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Listado reportes cupones de descuento utilizados
 * @interface ReportsCouponSection
 */
export const ReportsCouponSection = ({dataB2B}) => {

    const [users, setUsers] = useState([]);
    const [usersFull, setUsersFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const location= useLocation();
    const [toastProperties, setToastProperties] = useState({show: false});
    const navigate = useNavigate();
    
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }
    const [dataFilter, setDataFilter] = useState({
        identificationNumber: '',
        email: '',
        lastname: '',
        name: '',
    })
    const {authToken}= useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        setToastProperties(location.state?.toastProperties || {show:false});
        window.history.replaceState(null,'');
        fetchData();
    }, [total])
    const fetchData = async () => {
        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        const response = handleFetch({
            url: `bo/discounts_users?${filtername}${filterlastname}${filteremail}${filterdocument}`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                console.log("r.data",r.data)
                    setUsers(r.data)
                    setTotal(r.total)
                },
                error => {
                    console.log(error.errors);
                })
      };
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({target}) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        const response = handleFetch({
            url: `bo/discounts_users?${filtername}${filterlastname}${filteremail}${filterdocument}`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                    setUsers(r.data)
                    setTotal(r.total)
                },
                error => {
                    console.log(error.errors);
                })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
        <Link to={`/admin/descuentos`} className='px-4 px-sm-5 text_primary fw-bold'>Volver a Descuentos</Link>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%"}}>Reportes de cupones de descuentos</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{marginTop: "30px"}}>
                        <Filters
                            downloadCSV={true}
                            filename={`listusers_b2b_${dataB2B?.name}.csv`}
                            onSubmit={handleSubmit}
                            data={usersFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='text' name='identificationNumber' value={dataFilter?.identificationNumber || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Correo</span>
                                <div>
                                    <input type='text' name='email' value={dataFilter?.email || ''} onChange={handleChangeFilter} placeholder="Correo electrónico" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{width: "90%", marginTop: "17px", overflowX: "auto"}}>
                            {
                                data.loading?
                                    <Loading />
                                    :
                                    <UsersTable users={users} total={total} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} users Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({users, total, dataFilter}) =>{

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name":"NOMBRE COMPLETO",
            "sort_name":"name",
            "width": "15%"
        },
        {
            "name":"CORREO ELECTRÓNICO",
            "sort_name":"email",
            "width": "15%"
        },
        {
            "name":"N. DOCUMENTO",
            "sort_name": "document",
            "width": "15%"
        },
        {
            "name":"COMPRA",
            "sort_name":"purchase",
            "width": "15%"
        },
        {
            "name": "CUPÓN",
            "sort_name": "discount",
            "width": "15%"
        },
        {
            "name": "DESCUENTO",
            "sort_name": "amount",
            "width":"15%"
        },
        {
            "name": "FECHA USO",
            "sort_name": "date",
            "width":"10%"
        }
    ];
    const [usersSorted, setUsersSorted] = useState(users)
    const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))    
    const [toastProperties, setToastProperties] = useState({show: false});
    const navigate = useNavigate();
    
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }

    useEffect(() => {
        setUsersSorted(users)
    }, [users, total])

    const resetState = () =>{
        setUserHovered(Array(users.length).fill(false))
    }

    const handleActiveRow = (index)=>{
        let auxArray = Array(users.length).fill(false);
        auxArray[index] =  true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken }= useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        event.preventDefault()
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        setPage(event.selected)
            const response = handleFetch({
                url: `bo/discounts_users?${filtername}${filterlastname}${filteremail}${filterdocument}&$skip=${result}`,
                method: 'GET',
                headers: {Authorization: `Bearer ${authToken}`}
            });
            response
                .then((r) => {
                        setUsersSorted(r.data)
                    },
                    error => {
                        console.log(error.errors);
                        if(error.errors) {

                        }
                    })
    };
    return(
        <>
        <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        <table className='UserAdmin__table' >
            <thead className='UserAdmin__table-header' style={{height: "52px"}}>
            <tr style={{cursor: "pointer"}}>
                {headers.map((header, index)=>{
                    return(
                        <th key={index} className="overflow" style={{width: header.width, paddingLeft: "16px"}}>{header.name}</th>
                    )
                })}
            </tr>
            </thead>
            <tbody>
            {
                usersSorted.length!==0 ?
                    usersSorted.map((user, index)=>{
                        return(
                            <tr onMouseLeave={resetState} onMouseEnter={()=>{handleActiveRow(index)}} className={`UserAdmin__table-row ${index%2 ===0 ? "UserAdmin__table-pair-row": "UserAdmin__table-odd-row" }`} key={index}>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user['webuser.name']} {user['webuser.lastname']}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user['webuser.email']}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user['webuser.documentType']} {user['webuser.document']}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user['course.title'] ? user['course.title'] : user['plan.title']}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user['discount.code']}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user['discount.price']} {user['discount.type'] === 0 ? 'COP' : '%'}</td>                        
                                <td className="overflow" style={{paddingLeft: "16px"}}>{new Date(user.usedAt).toISOString().split('T')[0]}</td>
                            </tr>
                        );
                    })
                    :
                    <tr>
                        <td colSpan="6" style={{padding: "16px", textAlign: "center"}}><strong>No se encontraron registros</strong></td>
                    </tr>
            }
            </tbody>
        </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
    </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{minHeight: "300px"}}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage}/>
            </div>
        </div>
    );
}