import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useLocation, Link } from 'react-router-dom';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { AcceptDialog, Toast } from '../../controls/UIControls';
import Modal from '@mui/material/Modal';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2025<br>
 * Listado sección planes de estudio
 * @interface CertificationSection
 */
export const StudyPlansSection = () => {

    const [waiting, setWaiting] = useState(false);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const [fullcatalog, setFullcatalog] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const location = useLocation();
    const { pathname } = location;
    const [showModalUpdated, setShowModalUpdated] = useState(false);
    const [filtro, setFiltro] = useState('');
    const [selectedCursos, setSelectedCursos] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredWithoutPlansData, setFilteredWithoutPlansData] = useState([]);
    const [studyplanSelected, setStudyplanSelected] = useState('');
    const [viewActive, setViewActive] = useState(false);
    const refreshCatalog = () => {
        // consulta de endpoint para historico de cursos inscritos por el usuario, se pasa por parámetro id del usuario
        let url = `bo/b2bcatalog?$limit=500&company=1`
        const responsech = handleFetch({
            url: url,
            method: 'GET',
        });
        responsech
            .then(async (r) => {
                setTotal(r?.data.length);
                setFullcatalog(r?.data);
                let datafilt = r?.data.filter(item => item["course.name"].toLowerCase().includes("plan de estudio"));
                const filteredDatas = r?.data.filter(item => !item["course.name"].toLowerCase().includes("plan de estudio"));
                setFilteredData(datafilt);
                setFilteredWithoutPlansData(filteredDatas);
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    }
    const handleCursoChange = (cursoId) => {
        setSelectedCursos((prevSelectedCursos) => {
            if (prevSelectedCursos.includes(cursoId)) {
                return prevSelectedCursos.filter((id) => id !== cursoId);
            } else {
                return [...prevSelectedCursos, cursoId];
            }
        });
    };
    const [selectAll, setSelectAll] = useState(false);
    const handleSelectAll = () => {
        let updatedItems = fullcatalog.map((item) => (item?.courseId));
        setSelectedCursos(selectAll != true ? updatedItems : []);
        setSelectAll(!selectAll);
    };
    const handleInputChange = (e) => {
        setFiltro(e.target.value);
    };
    const compararFullCatalog = (a, b) => {
        var tituloA = a["course.title"]?.toUpperCase();
        var tituloB = b["course.title"]?.toUpperCase();
        if (tituloA < tituloB) {
            return -1;
        }
        if (tituloA > tituloB) {
            return 1;
        }
        return 0;
    };
    //Ordenar array del catálogo completo de cursos
    const ordenarFullcatalog = () => {
        let arrayOrdenado = [...fullcatalog].sort(compararFullCatalog);
        setFullcatalog(arrayOrdenado)
    };
    // Función mostrar modal en caso de agregar
    const handleOpenModal = async () => {
        setFiltro('');
        setSelectedOption('');
        setViewActive(false);
        ordenarFullcatalog();
        setSelectAll(false);
        setShowModalUpdated(true);
    }
    const handleCloseModal = () => {
        setFiltro('');
        setSelectedOption('');
        setViewActive(false);
        setShowModalUpdated(false);
    }
    // Handle para asignar curso al Plan de estudios
    const handleAssignCourse = async (event) => {
        event.preventDefault();
        setWaiting(true)
        try {
            for (let i = 0; i < selectedCursos.length; i++) {
                await handleFetch({
                    url: `bo/studyplans_courses`,
                    method: 'POST',                   
                    headers: { Authorization: `Bearer ${authToken}` },
                    data: {
                        companyId: 1,
                        segmentId: 1,
                        courseId: selectedCursos[i],
                        studyplanId: studyplanSelected,
                    },
                })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        setToastProperties({
                            show: true,
                            type: 'error',
                            message: 'Error al agregar cursos al Plan de Estudios. Intenta nuevamente.',
                            position: { horizontal: 'left' },
                        });
                    });
            }
        } catch (error) {
            console.error(error);
        }
        setToastProperties({
            show: true,
            type: 'success',
            title: 'Operación exitosa',
            message: 'Se ha añadido curso exitosamente al Plan de Estudios.',
            position: { horizontal: 'left' },
        });
        setSelectedOption('');
        setFiltro('');
        setViewActive(false);
        setSelectedCursos([]);
        refreshCatalog();
        setWaiting(false)
        setShowModalUpdated(false);
    }
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setStudyplanSelected(event.target.value); 
        catalogFull();
        refreshCatalog();
        event.target.value!='' ? setViewActive(true) : setViewActive(false);        
    };
    //const cursosFiltrados = fullcatalog.filter((curso) => curso.title.toLowerCase().includes(filtro.toLowerCase()));
    const cursosFiltrados = filteredWithoutPlansData?.filter((curso) => curso["course.title"]?.toLowerCase().includes(filtro.toLowerCase()));

    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const { authToken, getSessionUser } = useAuthToken(true);
    const sessionUser = getSessionUser();
    const isAdmin = sessionUser?.type === 0 ||  sessionUser?.type === 1;
    const catalogFull = () => {
        const responsecatalog = handleFetch({
            url: `catalog?$limit=500&$skip=0`,
            method: 'GET',
        });
        responsecatalog
            .then(async (r) => {
                setFullcatalog(r.data);
            },
            error => {
                console.log(error.errors);
            })
    }
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        catalogFull();
        refreshCatalog();
    }, [total])
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Planes de Estudio EanX</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/planesdeestudios'} className={`${pathname == '/admin/planesdeestudios' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Planes de Estudio</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/certificarplanesestudios'} className={`${pathname == '/admin/certificarplanesestudios' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Estudiantes por Certificar</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/estudiantesplanesestudios'} className={`${pathname == '/admin/estudiantesplanesestudios' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Estudiantes Certificados</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "10px", textAlign: "right" }}>
                            { isAdmin && <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModal(event)}>
                                    <span>Añadir Cursos a Plan de Estudios</span>
                                </Link>
                            </div> }
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "1px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable studyplans={filteredData} total={filteredData?.length} fullcatalog={fullcatalog} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={showModalUpdated}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleAssignCourse}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar Cursos a Plan de Estudios</div>
                                <div>
                                    <select value={selectedOption} onChange={handleOptionChange} className="form-select auth__select">
                                    <option value="">Seleccione un Plan de estudio</option>
                                        {
                                            filteredData.length !== 0 &&
                                            filteredData.map((data, index)=>{
                                                    return(
                                                        <option value={data?.courseId} key={index}>{data["course.name"]}</option>
                                                    );
                                                })
                                        }
                                    </select>
                                    {viewActive && <div className='d-flex mt-3'>
                                        <input
                                            type="text"
                                            placeholder="Buscar curso"
                                            className="form-control PlanFormContainer__input"
                                            value={filtro}
                                            onChange={handleInputChange}
                                        />
                                    </div>}
                                    {viewActive && (
                                        <div className='B2BAdmin__framecourses'>
                                            <div className='d-flex'>
                                                <input
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                /> <label className='mx-2 fw-bold fs-8 B2BAdmin__divcourse'>Seleccionar Todos</label>
                                            </div>
                                            {cursosFiltrados?.map((curso, index) => (
                                                    <div key={curso.id} className='d-flex'>
                                                    <input
                                                        type="checkbox"
                                                        id={curso.courseId}
                                                        checked={selectedCursos.includes(curso.courseId)}
                                                        onChange={() => handleCursoChange(curso.courseId)}
                                                    />
                                                    <label htmlFor={curso.courseId} className='mx-2 fs-8 B2BAdmin__divcourse'>
                                                        {curso["course.name"]}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center mt-2'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit" disabled={waiting}>Agregar cursos {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} studyplans Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ studyplans, total, fullcatalog }) => {

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name": "NOMBRE PLAN",
            "sort_name": "title",
            "width": "90%"
        },
        {
            "name": "ACCIÓN",
            "sort_name": "action",
            "width": "10%"
        }
    ];

    const [studyplansSorted, setStudyplansSorted] = useState(studyplans)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(studyplans?.length||0).fill(false))
    const [toastProperties, setToastProperties] = useState({ show: false });
    const [showModalChangeStatus, setShowModalChangeStatus] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState([]);
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }

    useEffect(() => {
        setStudyplansSorted(studyplans)
        setTotalSorted(total)
    }, [studyplans, total])

    const resetState = () => {
        setUserHovered(Array(studyplans.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(studyplans.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/certificates?&$skip=${result}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setStudyplansSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    // Función mostrar modal para cambiar estado de certificados e insignias
    const handleOpenModalChangeStatus = async (id) => {
        setShowModalChangeStatus(true);
        await handleFetch({
            url: `bo/studyplans_courses?studyplanId=${id}`,
            method: 'GET',                   
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then((response) => {
                setSelectedPlan(response?.data)
                console.log(response);
            })
            .catch((error) => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al agregar cursos al Plan de Estudios. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    // Función ocultar modal para cambiar estado de certificados e insignias
    const handleCloseModalChangeStatus = () => {
        setShowModalChangeStatus(false);
    }
    //Handle Borrar curso de Plan de estudios
    const handleDeleteCourse = (id, event) => {
        event.preventDefault();
        handleFetch({
            url:`bo/studyplans_courses/${id}`,
            method: 'DELETE',
            headers: { Authorization: `Bearer ${authToken}` }
        }).then(() => {
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Operación exitosa',
                message: 'Se ha eliminado el curso del Plan de Estudios Exitosamente.',
                position: { horizontal: 'left' },
            });
            handleCloseModalChangeStatus();
        })
        .catch(() => {
            setToastProperties({
                show: true,
                type: 'error',
                message: 'Error al eliminar el curso del Plan de Estudios. Intenta nuevamente.',
                position: { horizontal: 'left' },
            });
        });
    }
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalChangeStatus}
                onClose={handleCloseModalChangeStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleCloseModalChangeStatus}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Listado de Cursos en Plan de Estudios</div>
                                <table className='UserAdmin__table' >
                                    <tbody>
                                        {
                                            selectedPlan?.length != 0 ?
                                            selectedPlan?.map((cert, index) => {
                                                    const courseName = fullcatalog.find((item) => item?.courseId === cert?.courseId);
                                                    return (
                                                        <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                            <td className="overflow" style={{ paddingLeft: "16px", width: "90%" }}>{courseName?.name || courseName["course.name"]}</td>
                                                            <td className="overflow" style={{ paddingLeft: "16px", width: "10%" }}>
                                                                <div className='UserAdmin__button-container' >
                                                                    <Link to={{}} onClick={(event) => handleDeleteCourse(cert.id, event)}>
                                                                        <i className='fa-solid fa-trash UserAdmin__color-primary'></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                :
                                                <tr>
                                                    <td colSpan="2" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Cambiar Estado</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalChangeStatus}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        studyplansSorted?.length != 0 ?
                        studyplansSorted?.map((cert, index) => {
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert["course.name"]}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>
                                            <div className='UserAdmin__button-container' >
                                                <Link to={{}} onClick={(event) => handleOpenModalChangeStatus(cert.courseId, event)}>
                                                    <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="3" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}