import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { useForm as hookUseForm } from 'react-hook-form';
import { useLocation, Link } from 'react-router-dom';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { ErrorMessage, SubmitFormButton, Toast } from '../../controls/UIControls';
import * as CONSTANTS from '../../../assets/constants';
import Modal from '@mui/material/Modal';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección edición de landing
 * @interface LandingSection
 */
export const LandingSection = () => {
    const { register, handleSubmit, setValue, watch, getValues, formState: { errors } } = hookUseForm({ reValidateMode: "onChange" });
    const [landingData, setLandingData] = useState({});
    const [carouselData, setCarouselData] = useState([]);
    const { authToken } = useAuthToken(true);
    const [fetchData, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });    
    const [showModalCreateImg, setShowModalCreateImg] = useState(false);
    const [editImgCarousel, setEditImgCarousel] = useState(false)    
    const [ideditImg, setIdeditImg] = useState(0)
    const [selectActive, setSelectActive] = useState(false);
    const location = useLocation();
    const { pathname } = location;

    const handleSelectorChange = () => {
        setSelectActive(!selectActive);
    };
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const refreshImgs = () => {
        //consulta de endpoint de imagenes de carrusel disponibles
        const responsec = handleFetch({
            url: `bo/carouselhome`,
            method: 'GET',
        });
        responsec
            .then((r) => {
                setCarouselData(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    // Consulta de endpoint listado de encuestas
    useEffect(() => {
        const response = handleFetch({
            url: `bo/landing`,
            method: 'GET',
        });
        response
            .then((r) => {
                setLandingData(r.data)
                if(r.data){
                    setValue("titleModal", r.data[0].titleModal);
                    setValue("activeModal", r.data[0].activeModal);
                    setValue("imageModal", r.data[0].imageModal);
                    setValue("linkModal", r.data[0].linkModal);
                }
            },
                error => {
                    console.log(error.errors);
                })
        refreshImgs();
    }, [])   
    // Función submit para guardar información
    const onSubmit = (data) => {
            handleFetch({
                url: 'bo/landing/1',
                method: 'PATCH',
                data: data,
            })
            .then(() => {
                setToastProperties({
                            show: true,
                            type: 'success',
                            title: 'Operación exitosa',
                            message: 'La información para el landing se guardó correctamente.',
                            position: { horizontal: 'left' },
                        })
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al guardar información del landing. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    // Header para historial de planes
    const headers = [
        {
            "name": "IMAGEN ESCRITORIO",
            "width": "30%"
        },
        {
            "name": "IMAGEN MOBILE",
            "width": "30%"
        },
        {
            "name": "ENLACE",
            "width": "14%"
        },
        {
            "name": "ACTIVO",
            "width": "13%"
        },
        {
            "name": "ACCIONES",
            "width": "13%"
        }
    ];
    // Función mostrar modal en caso de registro plan b2b
    const handleOpenModalCreateImg = async () => {
        setValue("carouselImg", '');
        setValue("carouselImgMobile", '');
        setValue("carouselLink", '');
        setValue("carouselVideo", '');
        setShowModalCreateImg(true);
    }
    // Función mostrar modal en caso de editar plan b2b
    const handleOpenModaleditImg = (id, event) => {
        event.preventDefault();
        const img = carouselData.find(
            (item) => parseInt(item.id) === id,
        );
        setValue("carouselImg", img.carouselImg);
        setValue("carouselImgMobile", img.carouselImgMobile);
        setValue("carouselLink", img.carouselLink);
        setValue("carouselActive", img.carouselActive);
        setValue("carouselVideo", img.carouselVideo);
        if(img.carouselVideo){
            setSelectActive(true)
        }
        setEditImgCarousel(true);
        setIdeditImg(id);
        setShowModalCreateImg(true);
    }
    
    const handleDeleteImg = (id, event) => {
        event.preventDefault();
        handleFetch({
            url:`bo/carouselhome/${id}`,
            method: 'DELETE',
        }).then(() => {
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Operación exitosa',
                message: 'Se ha eliminado el plan del B2B Exitosamente.',
                position: { horizontal: 'left' },
            });
            refreshImgs();
        })
        .catch(() => {
            setToastProperties({
                show: true,
                type: 'error',
                message: 'Error al eliminar el plan del B2B. Intenta nuevamente.',
                position: { horizontal: 'left' },
            });
        });
    }
    const handleCreateImg = (formValues) => {
        handleFetch({
                url: 'bo/carouselhome' + (editImgCarousel ? `/${ideditImg}` : ''),
                method: editImgCarousel ? 'PATCH' : 'POST', 
                data: getRequestDataImg(formValues),
            })
            .then(r=>{
                setToastProperties({
                    show:true,
                    type:'success',
                    title: 'Operación exitosa',
                    message: 'La imagen para el carrucel se guardó correctamente.',
                    position: {horizontal:'left'},
                })
                refreshImgs();
            })
            .catch((error) => {
                console.log(error);
                if(error.code===409){
                   setToastProperties({
                    show:true,
                    type:'error',
                    message: error.message,
                    position: {horizontal:'left'},
                   })
                }else{
                    setToastProperties({
                        show:true,
                        type:'error',
                        message: 'Error al guardar la imagen para el carrucel. Intenta nuevamente.',
                        position: {horizontal:'left'},
                        })
                    }
            });
            //clearAll();
            setShowModalCreateImg(false);
            setEditImgCarousel(false);
    }
    const handleCloseModalCreateImg = () => {
        setShowModalCreateImg(false);        
        setShowModalCreateImg(false);        
        setValue("carouselImg", 'aux');
        setValue("carouselImgMobile", 'aux');
        setValue("carouselLink", 'aux');
        setShowModalCreateImg(false);
        setValue("carouselImg", 'aux');
        setValue("carouselImgMobile", 'aux');
        setValue("carouselLink", 'aux');
        setIdeditImg(0);
        setEditImgCarousel(false);
    }
    // Función para retornar datos obtenidos en formulario
    const getRequestDataImg = (formValues) => {
        return {
            carouselImg: formValues.carouselImg,
            carouselImgMobile: formValues.carouselImgMobile,
            carouselLink: formValues.carouselLink,
            carouselActive: formValues.carouselActive,
            carouselVideo: formValues.carouselVideo || "",
            carouselOrder: parseInt(formValues.carouselOrder),
        }
    }
    const getVideoUrl = videoUrl => {
        const regex = /[?&]v=([^&]+)/;
        const match = videoUrl.match(regex);
        if (match) return match[1];
        else return null;
      }
    const checkboxenabled = watch('activeModal');
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalCreateImg}
                onClose={handleCloseModalCreateImg}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleCreateImg)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar imágen a Carrusel</div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Imágen o vídeo</label>
                                    <div className="form-check form-switch">
                                        <input 
                                            onChange={handleSelectorChange}
                                            type="checkbox" 
                                            checked={selectActive}
                                            defaultChecked
                                            className="form-check-input PlanFormContainer__check-input"
                                        >
                                        </input>
                                    </div>
                                </div>
                                {!selectActive ? <><div className='mb-3'>
                                    <label className="form-label auth__form-label">Imágen completa</label>
                                    <input 
                                        placeholder={CONSTANTS.LABEL_URLIMG_CAROUSEL_PLACEHOLDER}
                                        {...register("carouselImg")} 
                                        className={`form-control PlanFormContainer__input ${errors.carouselImg?'PlanFormContainer__input--error':''}`}>
                                    </input>
                                    {errors.carouselImg && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.carouselImg.message}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Imágen Mobile</label>
                                    <input 
                                        placeholder={CONSTANTS.LABEL_URLIMG_CAROUSEL_PLACEHOLDER}
                                        {...register("carouselImgMobile")} 
                                        className={`form-control PlanFormContainer__input ${errors.carouselImgMobile?'PlanFormContainer__input--error':''}`}>
                                    </input>
                                    {errors.carouselImgMobile && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.carouselImgMobile.message}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Link para Imágen</label>
                                    <input 
                                        placeholder={CONSTANTS.LABEL_URLLINK_CAROUSEL_PLACEHOLDER}
                                        {...register("carouselLink")} 
                                        className={`form-control PlanFormContainer__input ${errors.carouselLink?'PlanFormContainer__input--error':''}`}>
                                    </input>
                                    {errors.carouselLink && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.carouselLink.message}
                                        </span>
                                    )}
                                </div></> :
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Video de Youtube</label>
                                    <input 
                                        placeholder={CONSTANTS.LABEL_URLVIDEO_CAROUSEL_PLACEHOLDER}
                                        {...register("carouselVideo")} 
                                        className={`form-control PlanFormContainer__input ${errors.carouselVideo?'PlanFormContainer__input--error':''}`}>
                                    </input>
                                    {errors.carouselVideo && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.carouselVideo.message}
                                        </span>
                                    )}
                                </div> }
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Posición en listado</label>
                                    <select {...register("carouselOrder")} className="form-select PlanFormContainer__input">
                                    {carouselData.length !== 0 &&
                                        (() => {
                                            const options = [];
                                            for (let i = 0; i <= (carouselData?.length-1) ; i++) {
                                                options.push(
                                                    <option value={i} key={i}>{i+1}</option>
                                                );
                                            }
                                            return options;
                                        })()}
                                    </select>
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Estado del Carrusel<span className='auth__required-input'>*</span></label>
                                    <div className="form-check form-switch">
                                        <input 
                                            {...register("carouselActive")} 
                                            type="checkbox" 
                                            defaultChecked
                                            className="form-check-input PlanFormContainer__check-input"
                                        >
                                        </input>
                                    </div>
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Agregar imágen</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCreateImg}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <div className='row justify-content-center'>
                <div className='col-12 text-center'>
                    <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Landing principal</h1>
                </div>
                <div className='col-12 d-flex justify-content-center'>
                    <div className='LandingHeader__text-and-border'>
                        <Link to={'../admin/casosexito'} className={`${pathname == '/admin/casosexito' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Casos de Éxito</Link>
                    </div>
                    <div className='LandingHeader__text-and-border'>
                        <Link to={'../admin/cursosrecomendados'} className={`${pathname == '/admin/cursosrecomendados' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Cursos recomendados</Link>
                    </div>
                    <div className='LandingHeader__text-no-border'>
                        <Link to={'../admin/landing'} className={`${pathname == '/admin/landing' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Landing</Link>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='UserFormContainer row m-2 p-3 col-10 col-md-10'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12">
                                    <label className="form-label PlanFormContainer__label">MODAL LANDING</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Título para el Modal</label>
                                    <input
                                        name='titleModal'
                                        placeholder={CONSTANTS.LABEL_TITLE_MODAL_PLACEHOLDER}
                                        {...register("titleModal", { required: CONSTANTS.MESSAGE_TITLEMODAL_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.titleModal ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.titleModal ? errors.titleModal.message : ''} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Estado del Modal</label>
                                    <div className="form-check form-switch">
                                        <label className="form-check-label">{ checkboxenabled === true ? "Activo" : "Inactivo"}</label>
                                        <input 
                                            {...register("activeModal")} 
                                            type="checkbox" 
                                            defaultChecked
                                            className="form-check-input PlanFormContainer__check-input"
                                        >
                                        </input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                <label className="form-label UserFormContainer__label">Imágen para Modal</label>
                                    <input
                                        name='imageModal'
                                        placeholder={CONSTANTS.LABEL_URLIMG_MODAL_PLACEHOLDER}
                                        {...register("imageModal", { required: CONSTANTS.MESSAGE_IMGMODAL_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.imageModal ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.imageModal ? errors.imageModal.message : ''} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Enlace para Modal</label>
                                    <input
                                        name='linkModal'
                                        placeholder={CONSTANTS.LABEL_URLLINK_MODAL_PLACEHOLDER}
                                        {...register("linkModal", { required: CONSTANTS.MESSAGE_LINKMODAL_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.linkModal ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.linkModal ? errors.linkModal.message : ''} />
                                </div>
                            </div>
                        </div><hr />
                        <div className='mb-3 mt-4'>
                                    <div className='row'>
                                        <div className="col-12 col-md-6">
                                            <label className="form-label PlanFormContainer__label">Imágenes para Carrusel Landing</label>
                                        </div>
                                        <div className="col-12 col-md-6 text-end">
                                        { carouselData.length < 4 && <Link to={{}} className="FilterAdmin__search-button" onClick={handleOpenModalCreateImg}>
                                                <span>Agregar elemento</span>
                                            </Link> }
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: "100%", marginTop: "17px", overflowX: "auto" }}>
                                    <div className='mb-3'>
                                        <table className='UserAdmin__table' >
                                            <thead className='UserAdmin__subtable-header' style={{ height: "52px" }}>
                                                <tr style={{ cursor: "pointer" }}>
                                                    {headers.map((header, index) => {
                                                        return (
                                                            <th key={index} style={{ width: header.width, paddingLeft: "16px", textAlign: "center" }}>{header.name}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    carouselData.length !== 0 ?
                                                        carouselData.map((data, index) => {
                                                            return (
                                                                <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data?.carouselVideo?<iframe width="320" style={{ height: "100px" }} src={`https://www.youtube.com/embed/${getVideoUrl(data?.carouselVideo)}?controls=1&rel=0&showinfo=0`} title="YouTube video player" className="" frameBorder="0" allowFullScreen></iframe>: <img src={data?.carouselImg} border="0" height="100" /> }</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}><img src={data?.carouselImgMobile} border="0" height="100" /></td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}><a href={data?.carouselLink}>{data?.carouselVideo?data?.carouselVideo:data?.carouselLink}</a></td>
                                                                    <td className="d-flex position-relative" style={{marginTop: "50px", paddingLeft: "16px"}}>
                                                                        <div className="aligncenter form-check form-switch">
                                                                            <input 
                                                                                name="carouselActive"
                                                                                value={data?.carouselActive}
                                                                                type="checkbox"
                                                                                className="form-check-input PlanFormContainer__check-input"
                                                                                defaultChecked={data?.carouselActive}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}><Link to={'#'} className="FilterAdmin__search-button" onClick={(event) => handleOpenModaleditImg(data.id, event)}><i className='fa-solid fa-pen UserAdmin__color-red'></i></Link> <Link to={'#'} className="FilterAdmin__search-button" onClick={(event) => handleDeleteImg(data.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></td>
                                                                </tr>
                                                            );
                                                        })
                                                        : <tr>
                                                            <td colSpan="7" style={{ padding: "16px", textAlign: "center" }}><strong>No existen registros</strong></td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        <SubmitFormButton
                            main_title="Guardar"
                            wait_title="Guardando..."
                            loading={fetchData.loading}>
                        </SubmitFormButton>
                    </form>
                    <ErrorMessage message={fetchData.error ? ((!!fetchData.error.code) ? CONSTANTS.MESSAGE_INFO_ERROR : CONSTANTS.MESSAGE_GENERIC_ERROR) : ''} />
                </div>
            </div>
        </>
    )
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}