import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { CourseDialogLogin } from './CourseDialogLogin';
import { useAuthToken } from '../../hooks/useAuthToken';
import { AcceptDialog, Toast } from '../controls/UIControls';
import { Dialog } from '@mui/material';
import { useCourseTaker } from '../../hooks/useCourseTaker';
import { apiConfig } from '../../api/apiConfig';
const landingImages = require.context('../../assets/images', true);
const loadingImage = landingImages('./icons/loading_spin_ean.svg');

export const CourseSPDetailSection = ({courseID}) => {
  
  return (
    <div>
      <CoursesLoader courseID={courseID} />
    </div>
  )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función de precarga de cursos
 * @param {string} courseID ID del curso a cargar
 * @function CoursesLoader
 */
const CoursesLoader = ({courseID}) => {
  const [data, handleFetch] = useFetch();
  const {authToken, getSessionUser} = useAuthToken();
  const currUser = getSessionUser();
  const [coursespurchased, setCoursespurchased] = useState([])
  const [coursesondemand, setCoursesondemand] = useState([])
  const [coursesSP, setCoursesSP] = useState([])
  const [coursesUserSP, setCoursesUserSP] = useState([])
  const [course, setCourse] = useState(
    {
      "attr.reviews":"",
      contentData: [{name: '', lessons: [{name: ''}]}],
      description: "",
      duration:"",
      imageURL:"",
      instructorData: {
        avatar_url: "",
        bio: "",
        id: "",
        name: "",
        title: "",
      },
      title: "",
    }
  );
// Función para consulta de curso individual
  const coursesFetcher = () => {
      fetcherFunction(handleFetch, courseID)
      .then( r => {
        setCourse(r.data[0]);
      },
        error => {
          console.log(error);
        });
        fetcherCoursesSPFunction(handleFetch)
        .then( r => {
          setCoursesSP(r.data);
        },
          error => {
            console.log(error);
          }); 
      fetcherCoursesSP(handleFetch,currUser)
      .then( r => {
        setCoursesUserSP(r.data);
      },
        error => {
          console.log(error);
        }); 
                
  }
  /**
 * Creado por Equilibrio Agency 2024<br>
 * Función para consultar cursos comprados
 * @param {function} fetcher Función handle para llamado de endpoint
 * @param {function} authToken Token de usuario
 * @function fetcherCoursesFunction
 */
const fetcherCoursesFunction = (fetcher, authToken) => {
  return fetcher({
      url:`courses/coursespurchased?$limit=100`,
      method: 'GET',
      headers: {Authorization: `Bearer ${authToken}`}
  });    
}  /**
 * Creado por Equilibrio Agency 2025<br>
 * Función para consultar cursos comprados
 * @param {function} fetcher Función handle para llamado de endpoint
 * @param {function} authToken Token de usuario
 * @function fetcherCoursesonDemandFunction
 */
const fetcherCoursesonDemandFunction = (fetcher, authToken) => {
  return fetcher({
      url:`courses/courseondemand?companyId=${apiConfig.company}&$limit=100`,
      method: 'GET',
      headers: {Authorization: `Bearer ${authToken}`}
  });
}
 /**
 * Creado por Equilibrio Agency 2025<br>
 * Función para consultar cursos comprados
 * @param {function} fetcher Función handle para llamado de endpoint
* @param {function} currUser Datos de usuario
 * @function fetcherCoursesSP
 */
const fetcherCoursesSP = (fetcher,currUser) => {
  return fetcher({
      url:`catalog/spusers?userId=${currUser?.id}&$limit=100`,
      method: 'GET',
  });
}
/**
 * Creado por Equilibrio Agency 2025<br>
 * Función para consultar cursos comprados
 * @param {function} fetcher Función handle para llamado de endpoint
 * @function fetcherCoursesSPFunction
 */
const fetcherCoursesSPFunction = (fetcher) => {
  return fetcher({
      url:`catalog/spcourses?studyplanId=${courseID}`,
      method: 'GET',
  });
}
// Función para consulta de cursos adquiridos
const coursesPurchasedFetcher = () => {
  fetcherCoursesFunction(handleFetch, authToken)
  .then( r => {
    setCoursespurchased(r);
  },
    error => {
      console.log(error);
    });
  fetcherCoursesonDemandFunction(handleFetch, authToken)
  .then( r => {
    setCoursesondemand(r.data);
  },
    error => {
      console.log(error);
    });           
}
  useEffect(() => {
    coursesFetcher()
    if(authToken){coursesPurchasedFetcher()} 
  }, []);
  return (   
    <>     
      <div
          className='justify-content-center justify-content-sm-start position-relative'
      >
          {
              data.loading ?
              <Loading></Loading>
              :
            <CourseDetailed course={course} coursesUserSP={coursesUserSP} coursespurchased={coursespurchased} coursesondemand={coursesondemand} coursesSP={coursesSP} />
          }
          
      </div>
    </>
  );
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para consultar curso
 * @param {function} fetcher Función handle para llamado de endpoint
 * @param {string} courseID ID del curso a cargar
 * @function fetcherFunction
 */
const fetcherFunction = (fetcher, courseID) => {
  return fetcher({
      url:`catalog/b2bcatalog?&company=1&courseId=${courseID}`,
      method: 'GET',
  });
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
  const loaderImage = props.loaderImage || loadingImage;
  return (
      <div style={{minHeight: "300px"}}>
        <div className='row text-center position-absolute CatalogPage--loader'>
            <img alt='Loader' src={loaderImage}/>
        </div>
      </div>
  );
}
// Función detalle de curso
const CourseDetailed = ({course, coursesUserSP, coursespurchased, coursesondemand,coursesSP}) =>{

  const [showDescription, setShowDescription] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorCourseDialog, setErrorCourseDialog] = useState(false);
  const {authToken} = useAuthToken();
  const [showCourses, setShowCourses] = useState([]);  
  const [showContent, setShowContent] = useState([false]);  
  const [datas, handleFetch] = useFetch();
  const [toastProperties, setToastProperties] = useState({ show: false });
  const afterCloseToast = () => {
    setToastProperties({ show: false });
}
  // Función mostrar u ocultar contenido
  const handleContentShown = (index) =>{
    if(showContent[index]){

      let auxArr = Array(course.contentData?.length).fill(false);
      setShowContent(auxArr);
    }else{
      let auxArr = Array(course.contentData?.length).fill(false);
      auxArr[index] = true;
      setShowContent(auxArr);

    }
  }

  useEffect(() => {
    setShowContent(Array(course.contentData?.length).fill(false))
  }, [course.contentData])
  
  const show_correct_time = (time_minutes) =>{
    return Math.floor(time_minutes/60)+"h "+time_minutes%60+"m";
  }

  const { isLogged } = useAuthToken ();

  const [data,courseTaker] = useCourseTaker();
  
  
  const onCourseSubscription = () => {
    if(!isLogged()) {
      setOpenDialog(true);
    } else {
      let foundCourse = coursespurchased.some(dato => dato.courseId === course.courseId);
      const courseExists = coursesondemand.some(item => {
        const itemCourseId = item['courseId']?.trim().toLowerCase();
        const currentCourseId = course?.courseId?.trim().toLowerCase();
        return itemCourseId && currentCourseId && itemCourseId === currentCourseId;
      });
      if (courseExists) {
        if (foundCourse) {
          courseTaker(course, () => setErrorDialog(true));
        } else {
          setErrorCourseDialog(true);
        }
      } else {
        courseTaker(course, () => setErrorDialog(true));
      }
    } 
  }
  // Función para contar vídeos
  const getVideosCounter = course => {
    let videosCounter = 0;
    if(course['course.contentData']) {
      videosCounter = course['course.contentData'].reduce((counter, unit) => {
        counter+=unit.lessons.reduce((vCounter, lesson) => {
          if(lesson.type && 'ivideo' == lesson.type) vCounter++;
          return vCounter;
        },0);
        return counter;
      },0);
    }
    return videosCounter;
  }

  const nVideos = getVideosCounter(course);
  const getVideoUrl = videoUrl => {
    const regex = /[?&]v=([^&]+)/;
    const match = videoUrl.match(regex);
    if (match) return match[1];
    else return null;
  }
  const [iframeHeight, setIframeHeight] = useState('200');
  const iframeRef = useRef(null);
  const ajustarAlturaIframe = () => {
    if (iframeRef.current) {
      const iframeWidth = iframeRef.current.offsetWidth;
      const aspectRatio = 9 / 16;
      const newHeight = Math.floor(iframeWidth * aspectRatio);
      setIframeHeight(`${newHeight}px`);
    }
  };
  useEffect(() => {
    handleFetch({
      url:'courses/taken',
      method: 'GET',
      headers: {Authorization: `Bearer ${authToken}`} 
    }).then((r) => {
        setShowCourses(r.data)
          },
          error => {
              console.log(error.errors);
          })
    ajustarAlturaIframe();
    const handleResize = () => {
      ajustarAlturaIframe();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Función cursos individuales comprados
  const IndividualCoursesPurchasedData = ({coursesSP}) =>{
    const onCourseRedirection = (course) => {
        courseTaker(course, () => setErrorDialog(true) );
    }
    const noLoginRedirection = (course, e) => {
      e.preventDefault();
      window.open(`/curso/${course}`, '_top')
    }
    const downloadCertificate = (url, e) => {
      e.preventDefault();
      window.open(`${url}`, '_top')
    }
    const validateCertificate = (courseId,course, e) => {
      e.preventDefault();
      const filteredCourses = course.filter(c => c.courseId === courseId);
      handleFetch({
        url: `catalog/spusers/${filteredCourses[0].id}`,
        method: 'PATCH',
        data: {
          userId: filteredCourses[0].userId, 
          courseId: filteredCourses[0].courseId
        }
      })
      .then(r=>{
          setToastProperties({
              show:true,
              type:'success',
              title: 'Operación exitosa',
              message: 'Se ha validado el curso correctamente.',
              position: {horizontal:'left'},
          })
      })
      .catch((error) => {
          console.log(error);
          setToastProperties({
            show:true,
            type:'error',
            message: error.message || 'Error al validar el curso. Intenta nuevamente.',
            position: {horizontal:'left'},
          })
      });
    }
    const existsInPurchased = (course, coursespurchased) => {
      return coursespurchased.some(purchased => purchased.courseId === course.courseId);
    };
    const filteredCourses = coursesUserSP.filter(c => c.studyplanId === course.courseId);
    let sppurchased = existsInPurchased(course, coursespurchased)
    return(
        <><Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <div className='row mb-4'>
                <div className='col-12'>
                    {coursesSP?.length===0 &&
                        <div className="col mx-sm-3">
                            <div className="text-center text_primary my-1" >
                                <i className="fa-solid fa-book fa-4x mb-3"></i>
                                <p style={{fontSize: "20px"}}>No hay cursos relacionados a este Plan de Estudios <br /><Link className='ProfilePage__link-to-course' to='/cursos' style={{fontWeight: "600"}}>Visita nuestro catálogo</Link></p>
                            </div>
                        </div>
                    }
                    {coursesSP?.map((course, index)=>{
                      const certCourses = showCourses.filter(c => c.courseId === course.courseId);
                      return(
                      <div className='col-12'>
                        <div className='row' onClick={(e) => {noLoginRedirection(course.courseId, e) }}>
                          <div key={index} className='d-block d-md-flex ProfilePage__course-in-studyplan-row'>
                            <div className='d-flex justify-content-center me-md-3' style={{ marginBottom: '15px' }}>
                              <img className='ProfilePage__in-progress-course-image' src={course['course.imageUrl']} onClick={(e) => {noLoginRedirection(course.courseId, e) }} />
                            </div>
                            <div className='col-12 col-md-9'>
                              <div className='row' onClick={(e) => {noLoginRedirection(course.courseId, e) }}>
                                <span className='ProfilePage__course-title'>{course['course.title']}</span>
                              </div>
                              <div className='row'>
                                <span className='ProfilePage__course-instructor-name'>Por {course['course.description']}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        { (isLogged()&&sppurchased) ? <div className='row'>
                          <div className='col-12 d-flex justify-content-left'>
                              <Link to="#" onClick={(e) => {noLoginRedirection(course.courseId, e) }} className="FilterAdmin__option-button">
                                  <span>Ver curso</span>
                              </Link>
                              <Link to="#" onClick={(e) => {validateCertificate(course.courseId,filteredCourses, e) }}  className="FilterAdmin__option-button">
                                  <span>Validar certificado</span>
                              </Link>
                              { certCourses && certCourses[0] ? <Link to="#" onClick={(e) => {downloadCertificate(certCourses[0]['user_study.users_studies_certification.certificationUrl'], e) }} className="FilterAdmin__option-button">
                                  <span>Ver certificado</span>
                              </Link> : ''}
                          </div>
                        </div> : "" }
                      </div>)
                    }           
                    )}
                </div>
            </div>
        </>
    )
}
  return(
  <>
    <AcceptDialog 
        open={errorCourseDialog} 
        message="Debes comprar este curso para acceder a su contenido"
        style='error'
        afterClose={()=>setErrorCourseDialog(false)}
      />
    <AcceptDialog 
          open={errorDialog} 
          message="Ocurrió un error en la suscripción al curso, por favor intenta más tarde"
          style='error'
          afterClose={()=>setErrorDialog(false)}></AcceptDialog>
    <CourseDialogLogin openDialog={openDialog} course={course} afterDialogClose = {()=>setOpenDialog(false)}></CourseDialogLogin>
      <Dialog open={data.loading}  
          disableScrollLock={ true }
          classes={{paper:'CourseDetail__wait-message'}}>
        <div >
            <img alt='Loader' src={loadingImage}/>
            <div>Espera un momento. En breve serás redireccionado a la página del curso... </div>  
        </div>
      </Dialog>
    
    <div className='row justify-content-center'>
      <div className='col-10 col-xl-9 ps-0' style={{marginTop: "30px", marginBottom: "10px"}}>
        <h1 className='CourseDetail__title'>{course['course.title']}</h1>
      </div>
      <div className='col-10 col-xl-9'>
        <div className='row'>
          <div className='col-8 d-none d-lg-flex' style={{alignItems:"flex-start", paddingLeft: "0px"}} >
            <div className='' style={{width: "95%"}}>
              <div className='col-12' style={{padding: "0px"}}>
                {course && course['course.videoUrl']!==null ? <iframe ref={iframeRef} width="100%" style={{ height: iframeHeight }} src={`https://www.youtube.com/embed/${course['course.videoUrl'] ? getVideoUrl(course['course.videoUrl']) : ''}?controls=1&rel=0&showinfo=0`} title="YouTube video player" className="video-iframe" frameBorder="0" allowFullScreen></iframe> : <img alt={course.title+" banner"} src={course['course.imageUrl']} style={{maxWidth: "100%", borderRadius: "8px", minWidth: "100%"}}/> }
              </div>

            </div>
          </div>
          <div className='col-12 col-lg-4'>
            <div className='row justify-content-center justify-content-lg-start'>
              <div className='col-12 px-0  d-lg-none' style={{marginBottom: "15px"}}>
                <img alt={course['course.title']+" banner"} src={course['course.imageUrl']} style={{maxWidth: "100%", borderRadius: "8px"}}/>
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='row justify-content-center'>

                      <div className='col-12 ps-0 d-flex flex-wrap'>
                        <div className='col-12'>
                          <p className='CourseDetail__instructor-name-text'>Por <span className='CourseDetail__instructor-name-course-description'>{course['course.instructorData']?.name}</span></p>
                        </div>
                      </div>
                      <div className='col-12 ps-0 d-flex flex-wrap' style={{color: "grey", fontSize: "14px"}}>
                        <div className='row'>
                          <div className='col-5 col-sm-4 col-md-3 col-lg-5 col-xl-4 co-xxl-3 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-display"></i><p className='ms-2'>{nVideos} videos</p>
                          </div>
                          <div className='col-6 col-sm-5 col-md-3 col-lg-6 col-xl-6 col-xxl-5 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-certificate"></i><p className='ms-2'>Con certificado</p>
                          </div>
                          <div className='col-5 col-sm-4 col-md-3 col-lg-5 col-xl-5 col-xxl-4 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-clock"></i><p className='ms-2'>{show_correct_time(course['course.duration'])}</p>
                          </div>
                          <div className='col-4 col-sm-3 col-md-2 col-lg-4 col-xl-3 col-xxl-3 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-language"></i><p className='ms-2'>Español</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 ps-0'>
                    {
                      (!!course['course.description'])?
                      course['course.description']?.length>360
                        ?
                        <>

                        {
                          showDescription
                          ?
                            <>
                              <p className='' style={{fontSize: "16px", fontWeight: 400, margin: "8px 0px"}}>{course['course.description']}</p>
                              <p className='CourseDetail__see-more' onClick={() => {setShowDescription(false)}}>Ver menos</p>
                            </>
                          :
                            <>
                              <p className='' style={{fontSize: "16px", fontWeight: 400, margin: "8px 0px"}}>{course['course.description'].substring(0,360)+"..."}</p>
                              <p className='CourseDetail__see-more' onClick={() => {setShowDescription(true)}}>Ver más</p>
                            </>
                          }
                        </>
                        :
                        <>
                          <p className='' style={{fontSize: "16px", fontWeight: 400, margin: "8px 0px"}}>{course['course.description']}</p>
                        </>
                      :
                      <></>
                    }
                  </div>
                  <div className='col-12 px-0' style={{marginTop: "20px", marginBottom: "20px"}}>
                    <button className='CourseDetail__suscription-button border-0' onClick={onCourseSubscription}>        
                      Iniciar Curso
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className='col-10 col-xl-9 ps-0' style={{marginTop: "30px"}}>
      </div>
      <div className='col-10 col-xl-9 ps-0' style={{marginTop: "30px"}}>
        <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>Cursos para el Plan de estudio</p>
      </div>
      <div className='col-10 col-xl-9 ps-0'>
        <hr className='CourseDetail__separation-line' style={{marginBottom: "5px"}} />
        <IndividualCoursesPurchasedData coursesSP={coursesSP}/>
      </div> 
    </div>
  </>
  );
}