import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Toast } from '../../controls/UIControls';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Listado sección usuarios B2B
 * @interface B2BUserSection
 */
export const B2BUserSection = ({dataB2B}) => {

    const [users, setUsers] = useState([]);
    const [segment, setSegment] = useState([]);
    const [registered, setRegistered] = useState([]);
    const [usersFull, setUsersFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const location= useLocation();
    const [toastProperties, setToastProperties] = useState({show: false});
    const navigate = useNavigate();
    
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }
    const [dataFilter, setDataFilter] = useState({
        identificationNumber: '',
        email: '',
        lastname: '',
        name: '',
    })
    const {authToken}= useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        if(!dataB2B?.id){
            navigate('/admin/b2b');
        }
        setToastProperties(location.state?.toastProperties || {show:false});
        window.history.replaceState(null,'');

        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        let url = parseInt(dataB2B?.id)!==1 ? `bo/b2buser?&companyId=${dataB2B?.id}${filtername}${filterlastname}${filteremail}${filterdocument}`: `bo/eanxuser?${filtername}${filterlastname}${filteremail}${filterdocument}`
        const response = handleFetch({
            url: url,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                    setUsers(r.data)
                    setTotal(r.total)
                },
                error => {
                    console.log(error.errors);
                })
        const responsesegment = handleFetch({
            url: `bo/segment?&companyId=${dataB2B?.id}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsesegment
            .then(async (r) => {
                if(r.data.length == 0){setSegment([
                    { "type": 1, "name": "Público General"},
                    { "type": 2, "name": "Estudiante Eanista"},
                    { "type": 3, "name": "Docente de Planta" },
                    { "type": 4, "name": "Docente de Cátedra"},
                    { "type": 5, "name": "Colaborador" },
                    { "type": 6, "name": "Egresado" } ])}else{setSegment(r.data)}
            },
                error => {
                    console.log(error.errors);
                })
                fetchData();
    }, [total])
    const fetchData = async () => {
        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        try {
          let urlregusers = parseInt(dataB2B?.id) !== 1 ? `bo/registeredusers?&type=b2buser` : `bo/registeredusers`;      
          const responseregistereds = await handleFetch({
            url: urlregusers,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
          });
      
          let _registered = responseregistereds;
      
          setRegistered(_registered);
      
          let urlcsv = parseInt(dataB2B?.id) !== 1
            ? `bo/b2buser?$limit=50000&companyId=${dataB2B?.id}${filtername}${filterlastname}${filteremail}${filterdocument}`
            : `bo/eanxuser?$limit=50000${filtername}${filterlastname}${filteremail}${filterdocument}`;
          const responsefull = await handleFetch({
            url: urlcsv,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
          });
          let _filteredUsers = responsefull.data.map((item1) => {
            let encontrado = _registered.find((item2) => item1.email.toLowerCase() === item2.email.toLowerCase() && item1.identificationType === item2.identificationType && item1.identificationNumber === item2.identificationNumber);
            return {
              ...item1,
              registrado: encontrado ? "SI" : "NO"
            };
          });
          setUsersFull(_filteredUsers);
        } catch (error) {
          console.error(error);
        }
      };
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({target}) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        let url = parseInt(dataB2B?.id)!==1 ? `bo/b2buser?&companyId=${dataB2B?.id}${filtername}${filterlastname}${filteremail}${filterdocument}`: `bo/eanxuser?${filtername}${filterlastname}${filteremail}${filterdocument}`
        const response = handleFetch({
            url: url,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                    setUsers(r.data)
                    setTotal(r.total)
                },
                error => {
                    console.log(error.errors);
                })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
        <Link to={`/admin/b2b/editar/${dataB2B?.id}`} className='px-4 px-sm-5 text_primary fw-bold'>Volver a B2B</Link>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%"}}>Usuarios de B2B {dataB2B?.name}</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{marginTop: "30px"}}>
                        <Filters
                            downloadCSV={true}
                            filename={`listusers_b2b_${dataB2B?.name}.csv`}
                            onSubmit={handleSubmit}
                            data={usersFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='text' name='identificationNumber' value={dataFilter?.identificationNumber || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Correo</span>
                                <div>
                                    <input type='text' name='email' value={dataFilter?.email || ''} onChange={handleChangeFilter} placeholder="Correo electrónico" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{width: "90%", marginTop: "17px", overflowX: "auto"}}>
                            {
                                data.loading?
                                    <Loading />
                                    :
                                    <UsersTable dataB2B={dataB2B} users={users} total={total} dataFilter={dataFilter} segment={segment} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} users Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({dataB2B, users, total, dataFilter, segment}) =>{

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name":"NOMBRE COMPLETO",
            "sort_name":"title",
            "width": "20%"
        },
        {
            "name":"CORREO ELECTRÓNICO",
            "sort_name":"enabled",
            "width": "25%"
        },
        {
            "name":"N. DOCUMENTO",
            "sort_name": "document",
            "width": "15%"
        },
        {
            "name": "SEGMENTACIÓN",
            "sort_name": "segment",
            "width": "15%"
        },
        {
            "name": "FECHA INSCRIPCIÓN",
            "sort_name": "highlighted",
            "width":"15%"
        },
        {
            "name": "ACCIONES",
            "sort_name": "highlighted",
            "width":"10%"
        }
    ];

    const [usersSorted, setUsersSorted] = useState(users)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))    
    const [toastProperties, setToastProperties] = useState({show: false});
    const navigate = useNavigate();
    
    const refreshUsers = (id) => {
        let url = parseInt(dataB2B?.id)!==1 ? `bo/b2buser?&companyId=${id}`: `bo/eanxuser`
        const responseusersb2b = handleFetch({
            url: url,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responseusersb2b
            .then((r) => {
                setUsersSorted(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }

    useEffect(() => {
        setUsersSorted(users)
        setTotalSorted(total)
    }, [users, total])

    const resetState = () =>{
        setUserHovered(Array(users.length).fill(false))
    }

    const handleActiveRow = (index)=>{
        let auxArray = Array(users.length).fill(false);
        auxArray[index] =  true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken }= useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=`+dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname="+dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=`+dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber="+dataFilter.identificationNumber : ''
        let url = parseInt(dataB2B?.id)!==1 ? `bo/b2buser?&companyId=${dataB2B?.id}${filtername}${filterlastname}${filteremail}${filterdocument}&$skip=${result}`: `bo/eanxuser?${filtername}${filterlastname}${filteremail}${filterdocument}&$skip=${result}`
        setPage(event.selected)
            const response = handleFetch({
                url: url,
                method: 'GET',
                headers: {Authorization: `Bearer ${authToken}`}
            });
            response
                .then((r) => {
                        setUsersSorted(r.data)
                        setTotalSorted(r.total)
                    },
                    error => {
                        console.log(error.errors);
                        if(error.errors) {

                        }
                    })
    };
    const handleDeleteCourse = (id, event) => {
        event.preventDefault();
        let url = parseInt(dataB2B?.id)!==1 ? `bo/b2buser/${id}`: `bo/eanxuser/${id}`
        handleFetch({
            url: url,
            method: 'DELETE',
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha eliminado el usuario del B2B Exitosamente.',
                    position: { horizontal: 'left' },
                });
            refreshUsers(dataB2B?.id);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al eliminar el usuario del B2B. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    return(
        <>
        <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        <table className='UserAdmin__table' >
            <thead className='UserAdmin__table-header' style={{height: "52px"}}>
            <tr style={{cursor: "pointer"}}>
                {headers.map((header, index)=>{
                    return(
                        <th key={index} className="overflow" style={{width: header.width, paddingLeft: "16px"}}>{header.name}</th>
                    )
                })}
            </tr>
            </thead>
            <tbody>
            {
                usersSorted.length!==0 ?
                    usersSorted.map((user, index)=>{
                        const segmentName = segment.find(
                            (item) => parseInt(item.type) === parseInt(user?.type),
                        );
                        return(
                            <tr onMouseLeave={resetState} onMouseEnter={()=>{handleActiveRow(index)}} className={`UserAdmin__table-row ${index%2 ===0 ? "UserAdmin__table-pair-row": "UserAdmin__table-odd-row" }`} key={index}>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user.name} {user.lastname}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user.email}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user.identificationType} {user.identificationNumber}</td>
                                <td className="overflow" style={{paddingLeft: "16px", textAlign: "center"}}>[{user.type}] {segmentName?.name}</td>
                                <td className="overflow" style={{paddingLeft: "16px", textAlign: "center"}}>{new Date(user.createdAt).toISOString().split('T')[0]}</td>                        
                                <td className="overflow" style={{paddingLeft: "16px", textAlign: "center"}}><Link to={'#'} className="FilterAdmin__search-button" onClick={(event) => handleDeleteCourse(user.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></td>                        
                            </tr>
                        );
                    })
                    :
                    <tr>
                        <td colSpan="6" style={{padding: "16px", textAlign: "center"}}><strong>No se encontraron registros</strong></td>
                    </tr>
            }
            </tbody>
        </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
    </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{minHeight: "300px"}}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage}/>
            </div>
        </div>
    );
}