import React, { useState, useEffect, useRef, Suspense } from 'react';
import 'react-phone-input-2/lib/material.css';
import { useFetch } from '../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { LandingHeaderB2B } from './LandingHeaderB2B'
import { Footer } from '../footer/Footer';
import { apiConfig } from '../../api/apiConfig';
import Marquee from "react-fast-marquee";
import WebChatZoomCrm from '../../assets/zoom-messenger-react/lib/addBubble.js'
import Modal from '@mui/material/Modal';
const CourseCategorySection = React.lazy(() => import('../landing/CourseCategorySection'));
const landingImages = require.context('../../assets/images', true);

export const LandingHomePage = () => {
    const [handleFetch] = useFetch();
    const [showModalMailSending, setShowModalMailSending] = useState(false);
    const [buttonMailSending, setButtonMailSending] = useState(false);
    const [showModalMailSendingError, setShowModalMailSendingError] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyname, setCompanyname] = useState('');
    const [position, setPosition] = useState('');
    const [phone, setPhone] = useState('');
    const [asunto, setAsunto] = useState('');
    const [terms, setTerms] = useState(false);    
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1200);
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        companyname: '',
        position: '',
        phone: '',
        asunto: '',
        terms: false,
        });
    useEffect(() => {
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1200);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    const handleInputChange = (e, setFunc) => {
        setFunc(e.target.value);
        setErrors({
            ...errors,
            [e.target.name]: '',
            });
    };
    const navigate = useNavigate();
    // Handle cerrar modal cuando se envía correo electrónico
    const handleCloseModal = () => {
        setShowModalMailSending(false);
        navigate('/empresas');
    }
    // Handle cerrar modal cuando se envía correo electrónico en caso de error
    const handleCloseModalError = () => {
        setShowModalMailSendingError(false);
    }
    // Función para enviar datos de formulario de contacto
    const sendContactRequest = (e) => {
        e.preventDefault();
        let valid = true;
        const newErrors = {};
        const isValidPhone = (phone) => phone.replace(/\D/g, "").length >= 11;
        if (name.trim() === '') {
            newErrors.name = 'El nombre es obligatorio';
            valid = false;
        }
        if (email.trim() === '') {
            newErrors.email = 'El correo electrónico es obligatorio';
            valid = false;
        }else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'El correo electrónico no es válido';
            valid = false;
        }
        if (companyname.trim() === '') {
            newErrors.companyname = 'El nombre de compañia es obligatorio';
            valid = false;
        }
        if (position.trim() === '') {
            newErrors.position = 'La posición es obligatoria';
            valid = false;
        }
        if (phone.trim() === '') {
            newErrors.phone = 'El telefono es obligatorio';
            valid = false;
        }
        if (isValidPhone(phone)) {
            newErrors.phone = 'El telefono debe ser válido';
            valid = false;
        }
        if (phone.trim() === '') {
            newErrors.asunto = 'El Asunto es obligatorio';
            valid = false;
        }
        if (terms === false) {
            newErrors.terms = 'Debe aceptar los términos y condiciones';
            valid = false;
        }
        if (!valid) {
            setErrors(newErrors);
            } else {
            setButtonMailSending(true)
            const response = handleFetch({
                url:'contactus/sendmailb2b',
                method: 'POST', 
                data: {
                    name: name,
                    email: email,
                    companyname: companyname,
                    position: position,
                    phone: phone,
                    asunto: asunto
                }
            });
            response
                .then((r) => {
                    const responsetwo = handleFetch({
                        url:'contactus/requestsb2b',
                        method: 'POST', 
                        data: {
                            name: name,
                            email: email,
                            companyname: companyname,
                            position: position,
                            phone: phone,
                            asunto: asunto
                        }
                    });
                    responsetwo
                        .then((rt) => {
                            navigate('/empresas/gracias');
                            },
                            error => {
                                console.log(error.errors);
                                showModalMailSendingError(true)
                            })
                    },
                    error => {
                        console.log(error.errors);
                        showModalMailSendingError(true)
                    })
            }  
    };
    const [alturaIframe, setAlturaIframe] = useState(window.innerHeight);
    const targetRef = useRef(null);
    const secondSectionRef = useRef(null);

    useEffect(() => {
        const ajustarAlturaIframe = () => {
          if (window.innerWidth > 768) {
            setAlturaIframe(window.innerHeight);
          } else {
            setAlturaIframe(300);
          }
        };
        window.addEventListener('resize', ajustarAlturaIframe);
        return () => {
        window.removeEventListener('resize', ajustarAlturaIframe);
        };
    }, []);


    return (
    <>
    <ModalMailSending handleCloseModal={handleCloseModal} showModalMailSending={showModalMailSending}/>
            <ModalMailSendingError handleCloseModal={handleCloseModalError} showModalMailSendingError={showModalMailSendingError}/>
            <LandingHeaderB2B />
            <div className='container-fluid landing__FormSection py-4'>
            <div className='container my-5 my-sm-2'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-12 col-md-6 p-5 px-sm-2 py-sm-2 d-flex flex-column'>
                        
                        <div className='col-12 col-md-12 col-lg-9 p-5 px-sm-2  mx-auto d-flex flex-column align-items-center'>
                            {/*<div className='col-12 text-center'>
                                <img alt='¿QUIERES QUE HABLEMOS?' src={landingImages('./landingB2B/imgForm.png')} className="img-fluid" width="250" height="100%" />
                            </div>*/}
                            <div className='col-12 landingb2b__title-top-banner'>
                            CONTENIDOS DE FORMACIÓN A LA MEDIDA DE TU EMPRESA
                            </div>
                            <div className='col-12 my-4 my-sm-2 landingb2b__paragraph-text landingb2b__white'>
                            Impulsa las competencias de tu equipo de trabajo con cursos certificados o soluciones de aprendizaje que se alinean a los objetivos de tu corporación.<br /> 
                            </div>
                            {/*    <span className="fw-bold">¡Juntos encontraremos la solución perfecta para tus necesidades!</span>
                            
                            <div className='col-12 landingb2b__text-respaldo-ean landingb2b__white d-none d-md-block'>
                                CON EL RESPALDO DE LA UNIVERSIDAD EAN <img alt='EAN UNIVERSIDAD' src={landingImages('./landingB2B/eanunilogo.png')} className="img-fluid" width="120" height="50" />
                            </div>*/}
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='col-12 col-md-12 col-lg-10 landing__FormContent p-5 px-sm-2 d-flex flex-column'>
                            <div id="contacto"></div>
                            <div className='col-12 mb-4 d-flex justify-content-center align-items-center'>
                                <p className='landingb2b__h3-box'>&#9632;</p> <p className='landingb2b__h3 text-center'>AGENDAR CONSULTORÍA</p> <p className='landingb2b__h3-box'>&#9632;</p>
                            </div>
                            <form onSubmit={sendContactRequest}></form>
                            <div className="input-container mt-3">
                                <input type="text" id="name" name="name" className="input-container-input" 
                                    value={name} onChange={(e) => handleInputChange(e, setName)} required />
                                <label htmlFor="name" className="input-container-label">Nombre completo <span className='auth__required-input'>*</span></label>
                            </div>
                            <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.name}</label></div>
                            <div className="input-container">
                                <input type="email" id="email" className="input-container-input" 
                                    value={email} onChange={(e) => handleInputChange(e, setEmail)} required />
                                <label htmlFor="email" className="input-container-label">Email corporativo <span className='auth__required-input'>*</span></label>
                            </div>
                            <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.email}</label></div>
                            <div className="input-container">
                                <input type="text" id="companyname" name="companyname" className="input-container-input" 
                                    value={companyname} onChange={(e) => handleInputChange(e, setCompanyname)} required />
                                <label htmlFor="companyname" className="input-container-label">Nombre de la empresa <span className='auth__required-input'>*</span></label>
                            </div>
                            <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.companyname}</label></div>
                            <div className="input-container">
                                <select name="position" id="position" className="input-container-input" value={position} onChange={(e) => handleInputChange(e, setPosition)} required>
                                    <option value="" selected></option>
                                    <option value="CEO">CEO</option>
                                    <option value="Gerente">Gerente</option>
                                    <option value="Director">Director</option>
                                    <option value="Ejecutivo">Ejecutivo</option>
                                    <option value="Analista">Analista</option>
                                    <option value="Otro">Otro</option>
                                </select>
                                <label htmlFor="position" className="input-container-label">Cargo <span className='auth__required-input'>*</span></label>
                            </div>
                            <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.position}</label></div>
                            <div className="input-container">
                                <input type="tel" id="phone" className="input-container-input" 
                                    value={phone} onChange={(e) => handleInputChange(e, setPhone)} required />
                                <label htmlFor="phone" className="input-container-label">Teléfono <span className='auth__required-input'>*</span></label>
                            </div>
                            <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.phone}</label></div>
                            <div className="input-container mt-3">
                                <select name="asunto" id="asunto" className="input-container-input" value={asunto} onChange={(e) => handleInputChange(e, setAsunto)} required>
                                    <option value="" selected></option>
                                    <option value="Formación a la medida">Formación a la medida</option>
                                    {/*<option value="Proveedores de contenido">Proveedores de contenido</option>*/}
                                    <option value="Licencias">Licencias de cursos</option>
                                    {/*<option value="Fábrica de contenidos">Fábrica de contenidos</option>*/}
                                    <option value="Otro">Otro</option>
                                </select>
                                <label htmlFor="asunto" className={`input-container-label`}>Servicio de interés</label>
                            </div>
                            <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.asunto}</label></div>
                            <div className="d-flex justify-content-center align-items-center check-terms mt-3">
                                <input className="form-check-input-b2bform auth__form-check-b2bform" type="checkbox" 
                                    value={terms} onChange={(e) => handleInputChange(e, setTerms)} required  />
                                <label className='form-check-label auth__form-label' style={{marginLeft: "10px", fontSize: "12px"}} >
                                    Al enviar el formulario, aceptas los <a href='https://eanx.co/terminos-condiciones.pdf' className='auth__link'>Términos y Condiciones</a> y Políticas de privacidad de EanX
                                </label>
                            </div>
                            <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.terms}</label></div>
                            <div className='row justify-content-center text-center mt-5 mt-sm-2'>
                                <button type="submit" disabled={buttonMailSending} className='col-12 landingb2b__button-primary px-5 py-3 border border-white' onClick={sendContactRequest}>
                                    ENVIAR FORMULARIO</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div id="contWebChatZoomCrm">
        <WebChatZoomCrm
            elementId="contWebChatZoomCrm"
            backgroundColor="#3BAC53"
            color="#FFFFFF"
            socialGatewayUrl="https://socialgateway.zoomcem.com/modules/zoomWebChat/zoomMessengerLog.php?params=N3hMWEdVWndFVytYQnE4cU4xSm1pMlhRaHVvSXVPRmVuNjQrdGFvS0o4cVdaZXA1SG80azh6WDc2U3g5T3RMODdEME84bmdqNkZqMjNta1BIN1Rza3BhWHdzVDRSdERBUG1PSTlFWGcwM3BrVE5KU213ODUxWTc3ZEk4UmNKejhjN0NEd3RpQ1FQSTdPRHFNbWpZRjVtc1pvUmhyMUJUL2dsQldWeXhUUDVqSXdySWxtZ3YxVmxlMEJYVElLdkdMUnEva1B6YXBFQ3NUNzk0a2lKRkdFanIyaTAvQ01ibVRsVDFXKzlmNmJ1T0czcnM2d01zb1FhTDJyUC9XUDZicjZwQ0cvUUpCaTlCclM1RWY3eE5vYVNodGlTeXc5QlRJaTBtbFVldGs5S2FSWmd6VVRxS2dQQlFhNnpJSXV3UHZBNi81RkpuNytrYVU2Nm1nbzQ4dVJReS9RdlVxWEFZdUgrbFdtN2lZUlgzRXRnTGhiZWR4RzdBWkphNW41NDJFOEl0M2RHUXJRQTV1aGF0L1IwS290ZVVDUzl2TWQ0MVFTNDdoTFQxRDR2Z0VGVW1aWDYydSs1R2FiMHJaaHlkUlVOcWk4UlM4ZU01ZzdRUGYxV0ptVXpraEt0aUkyQzdSZmhQOGxJR0t1c0IyVmxVNWcxK1BraWFDYjcxUzJkdnA2Nko1RzBYNi9CU0FNZWhEc2FZcVRsdm05WUVWWWFnTFB1bHc3WXdDYmU3NWEvQjU2aVBVbTgyeFUrQzZNd3hZUXplbStvSXpBakhWQlB0M1N2b1dWeS8xSFFSdklkL0pwMEhMajJpWHJhVWxiZ2hYdU51c2dQMFcvaTdaMHNBS0p5aVNMMnpYeHByYVhQV08wWXlMVHR3VWNGR1c3U0FwWEE5emswVzdoMzBBbUxEOTMvdTBKSmxUdHlSdWEyVDZZSjVqb2Z6aHN3c1UzNit0eiszMWc4SGZCWnoreW9HUGloRFR4KzNnWXh1VjZPUkllbXhFUURkcm1hY2hiV1JpTXl6dzAzbFpVdGhqSWdub2JtcEZqZ2FYY0lQRXBtcjhkY2w0cnh0UHprWkRRR3ZCYktlR2l2VFAwREk0b3VxWExWZEVBL2FUYkJaK09jWTg5MUZ0d1dVeDhYckxFQjhKU2d4R2FJcGdjUlNSU0VkcWtCYk5XdFNqQjdTclJLM0t6L0xib0pqOHIwZVYwd2JxZGFCREtoaHlmN1BhbDVGSnZqZ1M3Y1kvcVptcnJFVGlBcjFJSGw5UUN2NjJHYVFDZE4reFBTUUNRSFEwN3VyTEQvaUprYUZzMDZ6azJTRTZ2VHo3aGdtY3ZOczQySXh5R0o4dVdOMG4zV3h1cWdDbTBUMnF3V2hwZXBSTkU4RmFRbWxGWTJlY0t1Wi9vRjBWNlYreE1UbjQ3Z2tudndmM2YxU1hMTFdNdmtkZW9nNEJOYWExaGZ1RDdrOU5tMmFRSi9WQ3h4Q3BaVW42eGdUQVRQeXlmVkEwOEdyazN5elJuS0NoS2RJdXI2OENIazVvK2JjM2Q3R2ZBclQrSWpQT2V6VkFEb0pFc0Zoc0NXeVZvT2t1NnkrMzUxZnNrc2tZRjlPQStoK045ditZWkVhdlUzenJjT0VDbWtsNlpRVWhTTDNqZVE9PQ=="/>
        </div>
        <LandingHeaderB2B />
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-12 mb-lg-2 mb-xl-4'>
                    <p className='landingb2b__h3 mb-3'>QUIENES YA NOS ELIGIERON</p>
                </div>
            </div>
        </div>
        <div className='container-fluid d-flex justify-content-center landing__bestoptions py-5'>
            <div className='col-12 py-3'>
                <div className='d-flex flex-column flex-md-row justify-content-center align-items-center'>
                <Marquee>
                    <div className="wrapper text-center">
                        <img alt='Armada de Colombia' src={landingImages('./landingB2B/armada-de-colombia.png')} width="100" height="100" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='Aris Mining' src={landingImages('./landingB2B/aris-mining.png')} width="120" height="90" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='ICETEX' src={landingImages('./landingB2B/icetex.png')} width="150" height="50" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='USAID' src={landingImages('./landingB2B/usaid.png')} width="150" height="70" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='Colsubsidio' src={landingImages('./landingB2B/colsubsidio.png')} width="150" height="60" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='BMC' src={landingImages('./landingB2B/bmc.png')} width="180" height="80" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='Keralty' src={landingImages('./landingB2B/keralty.png')} width="140" height="60" />
                    </div>
                </Marquee>
                </div>
            </div>
        </div>
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-9 col-md-12 mb-4'>
                    <p className='landingb2b__h3' ref={secondSectionRef}>¿QUÉ PODEMOS HACER POR TU EMPRESA?</p>
                    <p className='landingb2b__paragraph-text'>Escuchamos tus necesidades y nos adaptamos a ellas para brindarte la experiencia<br />
                    de e-learning que tus trabajadores o tus clientes realmente necesitan.</p>
                </div>
            </div>
        </div>
        <div className='container-fluid d-flex justify-content-center landing__bgservices'>
            <div className='container'>
            <div className='row'>
                <div className='col-12 p-5 mt-3 pt-4'>
                    <div className='row justify-content-center text-center'>
                        <div className='col-12 col-md-5 col-lg-4 px-2'>
                            <img className='landingb2b__servicios-eanx-b2b' alt='Formación a la medida' src={landingImages('./landingB2B/formacion.png')} width="150" height="100" />
                            <p className='landing__title-servicios-b2b'>FORMACIÓN A LA MEDIDA</p>
                            <p className='landingb2b__paragraph'>Diseñamos contenidos innovadores sobre temas
                                específicos para tus procesos de capacitación,
                                con el uso de herramientas multimedia, inteligencia
                                artificial y de gamificación que rompen con los
                                cursos tradicionales. </p>
                        </div>
                        <div className='col-12 col-md-5 col-lg-4 px-3'>
                            <img className='landingb2b__servicios-eanx-b2b' alt='Licencias' src={landingImages('./landingB2B/licencias.png')} width="150" height="150" />
                            <p className='landing__title-servicios-b2b'>LICENCIAS DE CURSOS</p>
                            <p className='landingb2b__paragraph'>Catálogo por suscripción para acceder a una
                                gran variedad de cursos online cortos,
                                certificados y garantizados por la Universidad
                                Ean, para la adquisición de nuevas habilidades o
                                actualización de conocimientos de manera
                                asincrónica y en poco tiempo.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>


        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-12'>
                    <p className='landingb2b__h3'>TE CONTAMOS MÁS EN 1 MINUTO</p>
                </div>
            </div>
        </div>
        <div className='container-fluid mx-0 px-0'>
            <div className='justify-content-center text-center'>
                <iframe width="90%" height={alturaIframe} src="https://www.youtube.com/embed/-ygFuMabYuU?si=CAzcCChyzXjXvjs-" title="YouTube video player" frameBorder="0" allowFullScreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </div>
        </div>
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-12'>
                    <p className='landingb2b__h3'>¿POR QUÉ EANX?</p>
                </div>
            </div>
        </div>
        <div className='container-fluid d-flex justify-content-center py-4 landing__bgservices'>
            <div className='col-10'>
                <div className="container">
                    <div className="row">
                        {[
                            { title: "Contenidos", text: "a la medida", titleFirst: true },
                            { title: "Microlearning", text: "efectivo", titleFirst: true },
                            { title: "Gamificación", text: "Y Engagement", titleFirst: true },
                            { title: "Plataforma", text: "Intuitiva", titleFirst: true },
                            { title: "Respaldo de la", text: "Universidad Ean", titleFirst: false },
                            { title: "Cursos", text: "online certificados", titleFirst: true }
                        ].map((item, index) => (
                            <div key={index} className="col-12 col-sm-6 col-lg-4 mb-4 mt-4">
                                <div className="text-center">
                                    {item.titleFirst ? (
                                        <>
                                            <div className="text-center mx-auto title-two-conoce-eanx">{item.title}</div>
                                            <div className="text-center mx-auto mb-3 text-conoce-eanx">{item.text}</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-center mx-auto text-conoce-eanx">{item.text}</div>
                                            <div className="text-center mx-auto mb-3 title-two-conoce-eanx">{item.title}</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='container'>
                <div className='row justify-content-center text-center'>
                    <div className='col-12 col-md-8 col-lg-9'>
                        <p className='landingb2b__h3'>EXPLORA NUESTRO<br/>
                        CATÁLOGO DE RECURSOS</p>
                        <p className='landing__paragraph-text'>Trabajamos con herramientas de inteligencia artificial innovadoras para lograr una experiencia <br/>
                        de aprendizaje verdaderamente inmersiva y transformadora en tu empresa.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-12'>
                    <div style={{ width: '80vw', height: alturaIframe }}>
                        <embed
                            src={apiConfig.mercadoPago.returnUrl.replace('cuenta-creada','/productos-eanx.pdf#toolbar=0&navpanes=0&scrollbar=0')}
                            type="application/pdf"
                            width="100%"
                            height={alturaIframe}
                            toolbar="false"
                            navpanes="false"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='container'>
                <div className='row justify-content-center text-center'>
                    <div className='col-12'>
                        <p className='landingb2b__h3'>CONOCE NUESTRAS ACADEMIAS</p>
                        <p className='landing__paragraph-text'>Cursos Experto y Cápsulas Express sobre temas de alta demanda<br/>
                        en el mercado laboral que impulsan el talento de tu equipo.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid mt-4'>
            <div className='container'>
            <Suspense fallback={<div>Loading...</div>}>
            <div className='row justify-content-center text-center'>
                <div className='col-12'><CourseCategorySection /></div>
            </div>
            </Suspense>
            </div>
        </div>
        
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-12'>
                    <p className='landingb2b__h3 greenb2b'>También diseñamos RUTAS DE APRENDIZAJE <br/>
                        para cada uno de tus objetivos</p>
                </div>
            </div>
        </div>
        
        

        <div className='container-fluid landingb2b__container-schedule-consulting'>
            <div className='row justify-content-center text-center padbot'>
                <div className='col-10 col-md-3 col-lg-4 col-xl-4 landingb2b__schedule-consulting'>
                    <a href='#contacto'>AGENDA TU CONSULTORÍA</a>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}
// Función para mostrar mensaje exitoso en Modal de envío de solicitud
const ModalMailSending = ({showModalMailSending, handleCloseModal}) =>{

    return(
        <Modal
            open={showModalMailSending}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-4 text-center'>
                        <img src={landingImages('./icons/Nuestros-Expertos.png')} style={{width: "65px", marginTop: "25px"}} alt="padlock" />
                    </div>
                    <div className='ProfilePage__modal-info-container'>
                        <div className='ProfilePage__modal-title'>Solicitud enviada</div>
                        <p className='ProfilePage__modal-text-description'>
                            Hemos recibido tu solicitud y estamos trabajando en ella, en cuanto tengamos una respuesta, nos comunicaremos contigo. No olvides revisar tu correo electrónico.
                        </p>
                        <div style={{marginTop: "10px", marginBottom: "30px",textAlign: "center"}}>
                            <button
                                className='col-12 landingb2b__button-primary px-5 py-3 border border-white'
                                onClick={handleCloseModal}
                                style={{marginLeft: "0px"}}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
// Función para mostrar mensaje de error en Modal de envío de solicitud
const ModalMailSendingError = ({showModalMailSendingError, handleCloseModal}) =>{

    return(
        <Modal
            open={showModalMailSendingError}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-4 text-center'>
                        <img src={landingImages('./error.png')} style={{width: "65px", marginTop: "25px"}} alt="padlock" />
                    </div>
                    <div className='ProfilePage__modal-info-container'>
                        <div className='ProfilePage__modal-title'>Error al enviar</div>
                        <p className='ProfilePage__modal-text-description'>
                            Ha ocurrido un error al tratar de enviar tu información, por favor intenta nuevamente.
                        </p>
                        <div style={{marginTop: "10px", marginBottom: "30px",textAlign: "center"}}>
                            <button
                                className='col-12 landingb2b__button-primary px-5 py-3 border border-white'
                                onClick={handleCloseModal}
                                style={{marginLeft: "0px"}}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}