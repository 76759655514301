import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { useLocation, Link } from 'react-router-dom';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Toast } from '../../controls/UIControls';
import Modal from '@mui/material/Modal';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2025<br>
 * Listado sección planes de estudio
 * @interface RecomCoursesSection
 */
export const RecomCoursesSection = () => {
    const [waiting, setWaiting] = useState(false);
    const [data, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const location = useLocation();
    const { pathname } = location;
    const [showModalUpdated, setShowModalUpdated] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [fullcatalog, setFullcatalog] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const refreshCatalog = () => {
        // consulta de endpoint para historico de cursos inscritos por el usuario, se pasa por parámetro id del usuario
        const responsech = handleFetch({
            url: `bo/recomcourses`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responsech
            .then(async (r) => {
                setFilteredData(r?.data);
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    }
    const compararFullCatalog = (a, b) => {
        var tituloA = a["course.title"]?.toUpperCase();
        var tituloB = b["course.title"]?.toUpperCase();
        if (tituloA < tituloB) {
            return -1;
        }
        if (tituloA > tituloB) {
            return 1;
        }
        return 0;
    };
    //Ordenar array del catálogo completo de cursos
    const ordenarFullcatalog = () => {
        let arrayOrdenado = [...fullcatalog].sort(compararFullCatalog);
        setFullcatalog(arrayOrdenado)
    };
    // Función mostrar modal en caso de agregar
    const handleOpenModal = async () => {
        ordenarFullcatalog();
        setShowModalUpdated(true);
    }
    const handleCloseModal = () => {
        setShowModalUpdated(false);
    }
    // Handle para asignar casos de exito
    const handleAssignCourse = () => {
        const selectedCourse = fullcatalog.find(course => course.courseId === selectedOption);
        setWaiting(true)
        handleFetch({
                url: 'bo/recomcourses',
                method: 'POST', 
                data: {
                    description: selectedCourse['course.description'],
                    image: selectedCourse['course.imageUrl'],
                    instructor: selectedCourse['course.instructorData']?.name,
                    level: selectedCourse['course.name'],
                    name: selectedCourse['course.name'],
                    courseId: selectedCourse.courseId,
                    newcourse: false
                },
                headers: {Authorization: `Bearer ${authToken}`}
            })
            .then(r=>{
                setToastProperties({
                    show:true,
                    type:'success',
                    title: 'Operación exitosa',
                    message: 'El curso recomendado se guardó correctamente.',
                    position: {horizontal:'left'},
                })
                refreshCatalog();
            })
            .catch((error) => {
                console.log(error);
                setToastProperties({
                    show:true,
                    type:'error',
                    message: 'Error al guardar el curso recomendado. Intenta nuevamente.',
                    position: {horizontal:'left'},
                    })
            });
            setShowModalUpdated(false);
            setWaiting(false)
    }
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const { authToken, getSessionUser } = useAuthToken(true);
    const sessionUser = getSessionUser();
    const isAdmin = sessionUser?.type === 0 ||  sessionUser?.type === 1;
    const catalogFull = () => {
        const responsecatalog = handleFetch({
            url: `bo/b2bcatalog?$limit=500&company=1`,
            method: 'GET',
        });
        responsecatalog
            .then(async (r) => {
                setFullcatalog(r.data);
            },
            error => {
                console.log(error.errors);
            })
    }
    //Handle Borrar curso de Cursos Recomendados
    const handleDeleteCourse = (id, event) => {
        event.preventDefault();
        handleFetch({
            url:`bo/recomcourses/${id}`,
            method: 'DELETE',
            headers: { Authorization: `Bearer ${authToken}` }
        }).then(() => {
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Operación exitosa',
                message: 'Se ha eliminado el curso recomendado Exitosamente.',
                position: { horizontal: 'left' },
            });
            refreshCatalog();
            handleCloseModal();
        })
        .catch(() => {
            setToastProperties({
                show: true,
                type: 'error',
                message: 'Error al eliminar el curso recomendado. Intenta nuevamente.',
                position: { horizontal: 'left' },
            });
        });
    }
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        catalogFull();
        refreshCatalog();      
    };
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        catalogFull();
        refreshCatalog();
    }, [])
    const headers = [
        {
            "name": "IMAGEN",
            "sort_name": "image",
            "width": "15%"
        },
        {
            "name": "NOMBRE DEL CURSO",
            "sort_name": "name",
            "width": "25%"
        },
        {
            "name": "TESTIMONIO",
            "sort_name": "text",
            "width": "50%"
        },
        {
            "name": "ACCIÓN",
            "sort_name": "action",
            "width": "10%"
        }
    ];
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Cursos recomendados</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/casosexito'} className={`${pathname === '/admin/casosexito' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Casos de Éxito</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/cursosrecomendados'} className={`${pathname === '/admin/cursosrecomendados' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Cursos recomendados</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/landing'} className={`${pathname === '/admin/landing' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Landing</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "10px", textAlign: "right" }}>
                            { isAdmin && <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModal(event)}>
                                    <span>Añadir Curso Recomendado</span>
                                </Link>
                            </div> }
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            <div className='mb-3'>
                                <table className='UserAdmin__table' >
                                    <thead className='UserAdmin__subtable-header' style={{ height: "52px" }}>
                                        <tr style={{ cursor: "pointer" }}>
                                            {headers.map((header, index) => {
                                                return (
                                                    <th key={index} style={{ width: header.width, paddingLeft: "16px", textAlign: "center" }}>{header.name}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filteredData.length !== 0 ?
                                                filteredData.map((cert, index) => {
                                                    return (
                                                        <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                            <td className="overflow" style={{ paddingLeft: "16px" }}><img src={cert?.image} border="0" height="100" /></td>
                                                            <td className="overflow" style={{ paddingLeft: "16px" }}>{cert.name}</td>
                                                            <td className="overflow" style={{ paddingLeft: "16px" }}>{cert.description}</td>
                                                            <td className="row justify-content-center" style={{ marginTop: "35px", textAlign: "center" }}>
                                                                <Link to={{}} className="UserAdmin__button-container" onClick={(event) => handleDeleteCourse(cert.id, event)}>
                                                                    <i className='fa-solid fa-trash UserAdmin__color-primary'></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                :
                                                <tr>
                                                    <td colSpan="3" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={showModalUpdated}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                <form onSubmit={handleAssignCourse}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar Curso a Recomendados</div>
                                <div>
                                    <select value={selectedOption} onChange={handleOptionChange} className="form-select auth__select">
                                    <option value="">Selecciona un curso</option>
                                        {
                                            fullcatalog.length !== 0 &&
                                            fullcatalog.map((data, index)=>{
                                                    return(
                                                        <option value={data?.courseId} key={index}>{data["course.name"]}</option>
                                                    );
                                                })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center mt-2'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit" disabled={waiting}>Agregar cursos {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        </>
    )
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}