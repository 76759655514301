import { useEffect } from "react";
import "../assets/styleZoom.css";
import "../assets/all.css";

import $ from "jquery";

const WebChatZoomCrm = (props) => {
    const idDom = props.elementId
    const socialGatewayUrl = props.socialGatewayUrl
    const backgroundColor = props.backgroundColor;
    const urlImagenChat_minimized = props.urlImagenChat_minimized;
    const color = props.color;
    const icon = props.icon;
    const ctaStartMessage = props.ctaStartMessage;
  const initiate = () => {
    $(document).ready(function () {
      /* ADD STYLE ZOOM CSS */
    //   let style = document.createElement("link");
    //   style.rel = "stylesheet";
    //   style.type = "text/css";
    //   style.href = "../assets/styleZoom.css";
    //   document.head.appendChild(style);

      /* ADD STYLE ZOOM CSS */
    //   let style1 = document.createElement("link");
    //   style1.rel = "stylesheet";
    //   style1.type = "text/css";
    //   style1.href = "../assets/all.css";
    //   document.head.appendChild(style1);

    if(icon){

    }else{
      
    }
      /* ADD CONTAINER BUBBLE CHATS */
      var bubble = document.createElement("div");
      bubble.classList.add("bubblesChat");
      if(ctaStartMessage){
        bubble.innerHTML += '<div id="zoom_messenger_plugin_iframe_minimized_ctaMessage_classic" class="zoom_messenger_plugin_iframe_minimized_ctaMessage" style="bottom:15px;"><p>'+ctaStartMessage+'</p></div>'
      }
      bubble.innerHTML +=
        '<div id="zoom_messenger_plugin_iframe_minimized_classic" class="zoom_messenger_plugin_iframe_minimized disactive spinBordersClassic" style="background-color:'+backgroundColor+'; color:'+color+';"><div id="zoom_messenger_plugin_iframe_minimized_encabezado_classic"><img src="'+urlImagenChat_minimized+'" style="margin-top: 8px; visibility:hidden"><p style="float:right; color:'+color+'"><i id="zoom_messenger_plugin_iframe_btnExpand" class="fas fa-expand"></i><i id="zoom_messenger_plugin_iframe_btnHome" class="fas fa-home"></i><i id="zoom_messenger_plugin_iframe_btnClassic" class="fas fa-comments fa-flip-horizontal"></i><span>Atención en línea</span> <i id="zoom_messenger_plugin_iframe_btnClose" class="fas fa-chevron-down"></i></p></div><iframe id="zoom_messenger_plugin_iframe" class="zoom_messenger_plugin_iframe disactive" scrolling="no" style="display:none;" src="'+socialGatewayUrl+'" frameBorder="0"></iframe></div>';
        document.getElementById(idDom).append(bubble);
    });

    var expandIframe = false;
    var headerIndex;
    var headerDisplay;
    window.onload = function () {
      // console.log("Arranco");
      //Personalización de la presentación cuando existe un error en el formulario.

      var resizeHandle = document.getElementsByClassName(
        "zoom_messenger_plugin_iframe_minimized"
      );

      resizeHandle = resizeHandle[0];

      var expandBtn = document.getElementById(
        "zoom_messenger_plugin_iframe_btnExpand"
      );
      if (expandBtn) {
        expandBtn.addEventListener("mousedown", initialiseResize, false);
      }
      var w;
      var h;

      function initialiseResize(e) {
        console.log("initialize");

        window.addEventListener("mousemove", startResizing, false);
        window.addEventListener("mouseup", stopResizing, false);
        window.event.stopPropagation();
      }

      function startResizing(e) {
        w = resizeHandle.offsetLeft + parseInt(resizeHandle.style.width, 10);
        h = resizeHandle.offsetTop + parseInt(resizeHandle.style.height, 10);
        resizeHandle.style.transition = "none";
        var width = w - e.clientX;
        var height = h - e.clientY;
        resizeHandle.style.width = width + "px";
        resizeHandle.style.height = height + "px";
      }

      function stopResizing(e) {
        resizeHandle.style.transition = "1s";
        window.removeEventListener("mousemove", startResizing, false);
        window.removeEventListener("mouseup", stopResizing, false);
      }
    };

    $(function () {
      $("#zoom_messenger_plugin_iframe_btnHome").on("click", function () {
        window.location.reload();
      });

      $("#zoom_messenger_plugin_iframe_btnExpand").on("click", function(){
        
        if(expandIframe==false){
            expandIframe = true;
            $(".zoom_messenger_plugin_iframe_minimized").css({"border-radius":"5px 10px 0px 20px"}).animate({height:"70%", width:"93%"},100);
        }else{
            expandIframe = false;
            $(".zoom_messenger_plugin_iframe_minimized").css({"border-radius":"5px 10px 0px 20px"}).animate({height:"457px", width:"300px"},100);
        }

      });

      setTimeout(() => {        
        $(".zoom_messenger_plugin_iframe_minimized_ctaMessage").css({"display":"block"})
        $(".zoom_messenger_plugin_iframe_minimized_ctaMessage").animate(
            {opacity: "1"},
            1000,
        );
      }, 4000);

      $(".zoom_messenger_plugin_iframe_minimized_ctaMessage").on("click", function(event){
          // console.log("click cta");
          $(this).animate({opacity:"0"}, 700,function() {
              $(this).css({display:"none"})
          })
      })

      $("#zoom_messenger_plugin_iframe_btnClose").on("click", function(event){
        event.stopPropagation();
        let widthScreen = window.screen.width;
        if($("#zoom_messenger_plugin_iframe_minimized").hasClass("active")){
            if(widthScreen<=550){
                $('header').css({"z-index":headerIndex});
                $('header').css({"visibility":headerDisplay});
                $("body").css({"overflow":"initial"});
            }
            $("#zoom_messenger_plugin_iframe_btnClose").css({"display":"none"});
            $("#zoom_messenger_plugin_iframe_btnExpand").css({"display":"none"});
            $("#zoom_messenger_plugin_iframe_minimized").removeClass("active").addClass("disactive");
            
            $("#zoom_messenger_plugin_iframe_minimized").css({"border-radius":"5px 10px 0px 50px"}).animate({height:"50px", width:"300px"},100, function(){
                setTimeout(() => {
                    $("#zoom_messenger_plugin_iframe_minimized_encabezado img").css({"display":"initial"});
                    $("#zoom_messenger_plugin_iframe_minimized_encabezado p").css({"float":"right", "text-align":"inherit"});
                    $("iframe.zoom_messenger_plugin_iframe").css({"display":"none"});
                }, 700);
            });
        }

        if($("#zoom_messenger_plugin_iframe_minimized_flotante").hasClass("active")){
            if(widthScreen<=550){
                $('header').css({"z-index":headerIndex});
                $('header').css({"visibility":headerDisplay});
                $("body").css({"overflow":"initial"});
            }
            $("#zoom_messenger_plugin_iframe_btnClose").css({"display":"none"});
            $("#zoom_messenger_plugin_iframe_btnExpand").css({"display":"none"});
            $("#zoom_messenger_plugin_iframe_minimized_flotante").removeClass("active").addClass("disactive spinBorders");
            
            $("#zoom_messenger_plugin_iframe_minimized_flotante").css({"border-radius":"50px 50px 0px 50px"}).animate({height:"110px", width:"110px"},100, function(){
                setTimeout(() => {
                    $("#zoom_messenger_plugin_iframe_minimized_encabezado_flotante img").css({"display":"initial"});
                    $("#zoom_messenger_plugin_iframe_minimized_encabezado_flotante p").css({"float":"right", "text-align":"inherit"});
                    $("iframe.zoom_messenger_plugin_iframe").css({"display":"none"});
                }, 700);
            });
            $("#zoom_messenger_plugin_iframe_minimized_encabezado_flotante p").animate({"font-size":"17px", "margin-bottom":"0"})
        }

        if($("#zoom_messenger_plugin_iframe_minimized_classic").hasClass("active")){
            if(widthScreen<=550){
                $('header').css({"z-index":headerIndex});
                $('header').css({"visibility":headerDisplay});
                $("body").css({"overflow":"initial"});
            }
            $("#zoom_messenger_plugin_iframe_btnClose").css({"display":"none"});
            $("#zoom_messenger_plugin_iframe_btnExpand").css({"display":"none"});
            $("#zoom_messenger_plugin_iframe_minimized_classic").removeClass("active").addClass("disactive spinBordersClassic");
            
            $("#zoom_messenger_plugin_iframe_minimized_classic").css({"border-radius":"50%"}).animate({height:"60px", width:"70px"},100, function(){
                setTimeout(() => {
                    $("#zoom_messenger_plugin_iframe_minimized_encabezado_classic img").css({"display":"initial"});
                    $("#zoom_messenger_plugin_iframe_minimized_encabezado_classic p").css({"float":"right", "text-align":"inherit"});
                    $("#zoom_messenger_plugin_iframe_minimized_encabezado_classic p span").css({"visibility":"hidden"});
                    $("#zoom_messenger_plugin_iframe_btnClassic").css({"visibility":"visible"});
                    $("iframe.zoom_messenger_plugin_iframe").css({"display":"none"});
                }, 700);
            });
            $("#zoom_messenger_plugin_iframe_minimized_encabezado_classic p").animate({"font-size":"17px", "margin-bottom":"0"})
        }
      });

      $(".zoom_messenger_plugin_iframe_minimized").on("click", function(event){
        $(".zoom_messenger_plugin_iframe_minimized_ctaMessage").animate({opacity:"0"}, 700,function() {
            $(".zoom_messenger_plugin_iframe_minimized_ctaMessage").css({display:"none"})
        })
      })

      $("#zoom_messenger_plugin_iframe_minimized").on("click", function(event){
        let widthScreen = window.screen.width;
        let heightScreen = "100%";
        headerIndex = $('header').css("z-index");
        headerDisplay = $('header').css("visibility");
        if($(this).hasClass("disactive")){
            $(this).removeClass("disactive").addClass("active");
            $("#zoom_messenger_plugin_iframe_minimized_encabezado img").css({"display":"none"});
            $("#zoom_messenger_plugin_iframe_minimized_encabezado p").css({"float":"inherit", "text-align":"center"});
            $("iframe.zoom_messenger_plugin_iframe").css({"display":"initial"});            
            if(widthScreen<=550){
                $('header').css({"cssText":"z-index: -1","cssText":"visibility: hidden !important"});
                $("body").css({"overflow":"hidden"});
                $("#zoom_messenger_plugin_iframe_minimized").css({"border-radius":"0"}).animate({height:heightScreen, width:"100%"},100);
                // $("#zoom_messenger_plugin_iframe_btnHome").css({"display":"inline-block"});
                $("#zoom_messenger_plugin_iframe_btnClose").removeClass("fa-chevron-down").addClass("fa-home zoom_messenger_plugin_iframe_leftIcon");
                $("#zoom_messenger_plugin_iframe_btnClose").css({"display":"inline-block"});
            }else{
                $("#zoom_messenger_plugin_iframe_btnClose").css({"display":"inline-block"});
                $("#zoom_messenger_plugin_iframe_btnExpand").css({"display":"inline-block"})
                $("#zoom_messenger_plugin_iframe_minimized").css({"border-radius":"5px 10px 0px 20px"}).animate({height:"457px"},100);
            }            
        }

      });

      $("#zoom_messenger_plugin_iframe_minimized_flotante.disactive").on("click", function(event){
        
        let widthScreen = window.screen.width;
        let heightScreen = "100%";
        headerIndex = $('header').css("z-index");
        headerDisplay = $('header').css("visibility");
        if($(this).hasClass("disactive")){

            $(this).removeClass("disactive spinBorders").addClass("active");
            $("#zoom_messenger_plugin_iframe_minimized_encabezado_flotante img").css({"display":"none"});
            $("#zoom_messenger_plugin_iframe_minimized_encabezado_flotante p").css({"float":"inherit", "text-align":"center", "line-height": "26px"});
            $("iframe.zoom_messenger_plugin_iframe").css({"display":"initial"});
            if(widthScreen<=550){
                $('header').css({"cssText":"visibility: hidden !important; z-index: -1"});
                $("body").css({"overflow":"hidden"});
                $("#zoom_messenger_plugin_iframe_minimized_flotante").css({"border-radius":"0"}).animate({height:heightScreen, width:"100%"},100);
                // $("#zoom_messenger_plugin_iframe_btnHome").css({"display":"inline-block"});
                $("#zoom_messenger_plugin_iframe_btnClose").removeClass("fa-chevron-down").addClass("fa-home zoom_messenger_plugin_iframe_leftIcon");
                $("#zoom_messenger_plugin_iframe_btnClose").css({"display":"inline-block"});
            }else{
                $("#zoom_messenger_plugin_iframe_btnExpand").css({"display":"inline-block"});
                $("#zoom_messenger_plugin_iframe_minimized_flotante").css({"border-radius":"5px 10px 0px 20px"}).animate({height:"457px", width:"300px"},100);
                $("#zoom_messenger_plugin_iframe_btnClose").css({"display":"inline-block"});
            }
            
            $("#zoom_messenger_plugin_iframe_minimized_encabezado_flotante p").animate({"font-size":"22px", "margin-bottom":"4px"},1000);

        }

      });

      $("#zoom_messenger_plugin_iframe_minimized_classic.disactive").on("click", function(event){
        
        let widthScreen = window.screen.width;
        let heightScreen = "100%";
        headerIndex = $('header').css("z-index");
        headerDisplay = $('header').css("visibility");
        if($(this).hasClass("disactive")){

            $(this).removeClass("disactive spinBordersClassic").addClass("active");
            $("#zoom_messenger_plugin_iframe_minimized_encabezado_classic img").css({"display":"none"});
            $("#zoom_messenger_plugin_iframe_minimized_encabezado_classic p").css({"float":"inherit", "text-align":"center", "line-height": "26px"});
            $("#zoom_messenger_plugin_iframe_minimized_encabezado_classic p span").css({"visibility":"visible"});
            $("#zoom_messenger_plugin_iframe_btnClassic").css({"visibility":"hidden"});

            $("iframe.zoom_messenger_plugin_iframe").css({"display":"initial"});
            if(widthScreen<=550){
                $('header').css({"cssText":"visibility: hidden !important; z-index: -1"});
                $("body").css({"overflow":"hidden"});
                $("#zoom_messenger_plugin_iframe_minimized_classic").css({"border-radius":"0"}).animate({height:heightScreen, width:"100%"},100);
                // $("#zoom_messenger_plugin_iframe_btnHome").css({"display":"inline-block"});
                $("#zoom_messenger_plugin_iframe_btnClose").removeClass("fa-chevron-down").addClass("fa-home zoom_messenger_plugin_iframe_leftIcon");
                $("#zoom_messenger_plugin_iframe_btnClose").css({"display":"inline-block"});
            }else{
                $("#zoom_messenger_plugin_iframe_btnExpand").css({"display":"inline-block"});
                $("#zoom_messenger_plugin_iframe_minimized_classic").css({"border-radius":"5px 10px 0px 20px"}).animate({height:"457px", width:"300px"},100);
                $("#zoom_messenger_plugin_iframe_btnClose").css({"display":"inline-block"});
            }
            
            $("#zoom_messenger_plugin_iframe_minimized_encabezado_classic p").animate({"font-size":"22px", "margin-bottom":"4px"},1000);

        }

      });

    });
  };

  useEffect(() => {
    initiate();
  }, []);

  return <div style={{ display: "none" }} />;
};

export default WebChatZoomCrm;
