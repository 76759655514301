import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useForm as hookUseForm } from 'react-hook-form';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { AcceptDialog, Toast } from '../../controls/UIControls';
import Modal from '@mui/material/Modal';
import * as CONSTANTS from '../../../assets/constants';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección descuentos
 * @interface DiscountSection
 */
export const DiscountSection = () => {

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = hookUseForm({ reValidateMode: "onChange" });
    const [discounts, setDiscounts] = useState([]);
    const [discountsFull, setDiscountsFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });    
    const [showModalCreateCoupon, setShowModalCreateCoupon] = useState(false);
    const [page, setPage] = useState(0);
    const [typeDiscount, setTypeDiscount] = useState(0)
    const [isEditing, setIsEditing] = useState(false)
    const [idEdit, setIdEdit] = useState(0)
    const [dataFilter, setDataFilter] = useState({
        name: '',
        type: '',
        since: '',
        price: '',
    })
    const { authToken, getSessionUser } = useAuthToken(true);
    const sessionUser = getSessionUser();
    const isAdmin = sessionUser.type === 0 ||  sessionUser.type === 1;
    // Handle Refrescar Descuentos
    const refreshDiscounts = () => {
        let filtername = dataFilter.name !== '' ? `&code=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''        
        let filterprice = dataFilter.price !== '' ? "&price=" + dataFilter.price : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        const response = handleFetch({
            url: `bo/discounts?${filtername}${filtertype}${filtersince}${filterprice}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setDiscounts(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
    }
    // Handle cerrar toast
    
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    // Consulta de endpoint listado de descuentos
    useEffect(() => {
        refreshDiscounts()
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]

    // Función mostrar modal en caso de registro plan b2b
    const handleOpenModalCreateCoupon = async () => {
        setIsEditing(false)
        setValue("code", '');
        setValue("total_uses", '');
        setValue("type", 0);
        setValue("price", 0);
        setShowModalCreateCoupon(true);
    }
    // Función mostrar modal en caso de registro plan b2b
    const handleCloseModalCreateCoupon = async () => {
        setShowModalCreateCoupon(false);
    }
    // Handle para crear nuevos cupones de descuento
    const handleCreateCoupon = (formValues) => {
        const method = isEditing ? 'PATCH' : 'POST';
        const url = 'bo/discounts' + (isEditing ? `/${idEdit}` : '');
        handleFetch({
            url: url,
            method: method,
            data: getRequestData(formValues),
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha añadido el cupón de descuento exitosamente.',
                    position: { horizontal: 'left' },
                });
                refreshDiscounts()
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al agregar el cupón de descuento. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
            setShowModalCreateCoupon(false);
    }
    // Función para retornar datos obtenidos en formulario
    const getRequestData = (formValues) => {
        return {
            code: formValues.code,
            price: formValues.price,
            type: formValues.type,
            total_uses: formValues.total_uses,
            remaining_uses: formValues.total_uses
        }
    } 
    // Header para listado de descuentos
    const headers = [
        {
            "name": "CÓDIGO DESCUENTO",
            "sort_name": "code",
            "width": "20%"
        },
        {
            "name": "TIPO DESCUENTO",
            "sort_name": "type",
            "width": "15%"
        },
        {
            "name": "PRECIO/PORCENTAJE",
            "sort_name": "price",
            "width": "20%"
        },
        {
            "name": "USOS RESTANTES",
            "sort_name": "remaining",
            "width": "15%"
        },
        {
            "name": "USOS TOTALES",
            "sort_name": "total_uses",
            "width": "15%"
        }
    ];
    // Función validar tipo de descuento
    const handleSelectChange = (selectedOption) => {
        if(selectedOption.target.value == 0) {
            setTypeDiscount(0);
        }else {
            setTypeDiscount(1);
        }
    }
    // Función mostrar modal en caso de editar cupón
    const handleOpenModalEditSegment = (id, event) => {
        event.preventDefault();
        setIsEditing(true)
        setIdEdit(id);
        const discountdata = discounts.find(
            (item) => parseInt(item.id) === id,
        );
        setValue("code", discountdata.code);
        setValue("total_uses", discountdata.total_uses);
        setValue("type", discountdata.type);
        setValue("price", discountdata.price);
        setShowModalCreateCoupon(true);
    }
    const handleDeleteSegment = (id, event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/discounts/${id}`,
            method: 'DELETE',            
            headers: {Authorization: `Bearer ${authToken}`} 
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha eliminado el cupón Exitosamente.',
                    position: { horizontal: 'left' },
                });
                refreshDiscounts()
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al eliminar el cupón seleccionado. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    // Función para activar filtros, recibe parametros del mismo
    const onSubmit = async (e) => {
        e.preventDefault();
        refreshDiscounts();
    }
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&code=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''        
        let filterprice = dataFilter.price !== '' ? "&price=" + dataFilter.price : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/discounts?&$skip=${result}${filtername}${filtertype}${filtersince}${filterprice}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setDiscounts(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalCreateCoupon}
                onClose={handleCloseModalCreateCoupon}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleCreateCoupon)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar cupón de descuento</div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                                <label className="form-label auth__form-label">Título <span className='auth__required-input'>*</span></label>
                                            <input 
                                                placeholder={CONSTANTS.LABEL_CODE_DISCOUNT_PLACEHOLDER}
                                                {...register("code",{required:CONSTANTS.MESSAGE_CODE_DISCOUNT_REQUIRED})} 
                                                className={`form-control PlanFormContainer__input ${errors.code?'PlanFormContainer__input--error':''}`}>
                                            </input>
                                            {errors.code && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.code.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label auth__form-label">CANTIDAD DE CUPONES</label>
                                                <input 
                                                    placeholder={CONSTANTS.LABEL_TOTAL_DISCOUNT_PLACEHOLDER}
                                                    type="number"
                                                    {...register("total_uses",{
                                                        required:CONSTANTS.MESSAGE_TOTAL_DISCOUNT_REQUIRED,
                                                        pattern: {
                                                            value: CONSTANTS.PATTERN_FLOAT,
                                                            message: CONSTANTS.MESSAGE_PLAN_DURATION_INVALID
                                                        }})}  
                                                    className={`form-control PlanFormContainer__input ${errors.total_uses?'PlanFormContainer__input--error':''}`}
                                                ></input>                              
                                                {errors.total_uses && (
                                                        <span role="alert" className='auth__inputError'>
                                                            {errors.total_uses.message}
                                                        </span>
                                                    )}
                                        </div>    
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                        <label className="form-label auth__form-label">Tipo</label>
                                            <select {...register("type")} onChange={handleSelectChange} className="form-select PlanFormContainer__input">
                                                <option value='0'>Precio</option>
                                                <option value='1'>Porcentaje</option>
                                            </select>
                                        </div>
                                        <div className="col-6">
                                                <label className="form-label auth__form-label">Costo Actual</label>
                                            <div className="input-group">
                                                <input 
                                                    placeholder={CONSTANTS.LABEL_PRICE_DISCOUNT_PLACEHOLDER}
                                                    type="number"
                                                    {...register("price",{
                                                        required:CONSTANTS.MESSAGE_PRICE_DISCOUNT_REQUIRED,
                                                        pattern: {
                                                            value: CONSTANTS.PATTERN_NUMERIC,
                                                            message: CONSTANTS.MESSAGE_PLAN_PRICE_INVALID
                                                        }})}  
                                                    className={`form-control PlanFormContainer__input ${errors.price?'PlanFormContainer__input--error':''}`}
                                                >
                                                </input>
                                                <span className="input-group-text">{typeDiscount===0 ? 'COP' : '%'}</span>
                                            </div>
                                            {errors.price && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.price.message}
                                                </span>
                                            )}
                                    </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Agregar cupón</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCreateCoupon}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Cupones de Descuentos</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listdiscounts.csv"}
                            onSubmit={onSubmit}
                            data={discounts}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Códigos de descuento</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Códigos de descuento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Tipo de descuento</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='type'
                                        value={dataFilter?.type || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value='0'>Precio</option>
                                        <option value='1'>Porcentaje</option>
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Precio</span>
                                <div>
                                    <input type='number' name='price' value={dataFilter?.price || ''} onChange={handleChangeFilter} placeholder="Precio" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "10px", textAlign: "right" }}>
                            { isAdmin && <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalCreateCoupon}>
                                    <span>Agregar cupón</span>
                                </Link>
                                <Link to='/admin/reportedescuentos' state={{ dataB2B: { id: 415, name: 'Alexsander' } }} className="FilterAdmin__option-button">
                                    <span>Cupones utilizados</span>
                                </Link>
                            </div> }
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "1px", overflowX: "auto" }}>
                            <table className='UserAdmin__table' >
                                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                                    <tr style={{ cursor: "pointer" }}>
                                        {headers.map((header, index) => {
                                            return (
                                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                                            )
                                        })}
                                        <th style={{ width: "13%" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        discounts.length != 0 ?
                                            discounts.map((coupon, index) => {
                                                return (
                                                    <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{coupon.code}</td>
                                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{coupon.type === 1 ? 'PORCENTAJE' : 'PRECIO'}</td>
                                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{coupon.price} {coupon.type === 1 ? '%' : 'COP'}</td>
                                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{coupon.remaining_uses}</td>
                                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{coupon.total_uses}</td>
                                                        <td className="d-flex justify-content-center" style={{ paddingLeft: "16px", textAlign: "center" }}>{ isAdmin && <><Link to={'#'} className="FilterAdmin__option-button mt-2" onClick={(event) => handleOpenModalEditSegment(coupon.id, event)}><i className='fa-solid fa-pen UserAdmin__color-red'></i></Link> <Link to={'#'} className="FilterAdmin__option-button mt-2" onClick={(event) => handleDeleteSegment(coupon.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></> }</td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            <tr>
                                                <td colSpan="6" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                                            </tr>
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                itemsPerPage={50}
                                totalElements={total}
                                onChangePage={handlePageClick}
                                forcePage={page}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}