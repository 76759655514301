import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { AcceptDialog, Toast } from '../../controls/UIControls';
import Modal from '@mui/material/Modal';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección planes
 * @interface PlanAdminSectionV2
 */
export const PlanAdminSectionV2 = () => {

    const [plans, setPlans] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();

    const [planToDelete, setPlanToDelete] = useState("");
    const [deletePlanConfirmation, setDeletePlanConfirmation] = useState(false)
    const { authToken, getSessionUser } = useAuthToken(true);
    const sessionUser = getSessionUser();
    const isAdmin = sessionUser.type === 0 ||  sessionUser.type === 1;

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [dataFilter, setDataFilter] = useState({
        name: '',
        price: '',
        typeplan: '',
        companyId: 1,
    })
    const location= useLocation();
    const { pathname } = location;
    const [toastProperties, setToastProperties] = useState({show: false});

    const navigate = useNavigate();
    // Handle cerrar modal
    const handleCloseModal = () =>{
        setOpenModalDelete(false);
        setPlanToDelete("");
    }
    // Handle confirmación borrar plan
    const handleDeletePlanConfirmation = ()=>{
        setDeletePlanConfirmation(true);
    }
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }
    // Función clonar plan
    const clonePlan = (planID)=>{
        handleFetch({
            url:'bo/plans/clone',
            method: 'POST', 
            data: {planId: planID},
            headers: {Authorization: `Bearer ${authToken}`} 
        })
        .then(()=>{
           navigate('/admin/planes',{
                            state: {
                                toastProperties:{
                                    show:true,
                                        type:'success',
                                        title: 'Operación exitosa',
                                        message: 'El plan se duplicó correctamente.',
                                        position: {horizontal:'left'},
                                    }
                            }
                        });
           window.location.reload();
        })
        .catch(() => {
            setToastProperties({
                show:true,
                    type:'error',
                    title: 'Error',
                    message: 'El plan no pudo duplicarse. Intenta nuevamente.',
                    position: {horizontal:'left'},
                });
            })
    }
    
    // Listado de planes, se excluye plan eanista
    useEffect(() => {
        const PLAN_TYPE_EAN_STUDENT_TEACHER_COLLABORATOR = 2;
        let filtername = dataFilter.name !== '' ? `&title=` + dataFilter.name : ''
        let filterprice = dataFilter.price !== '' ? `&price=` + dataFilter.price : ''        
        let filtertypeplan = dataFilter.typeplan !== '' ? "&typeplan[$eq]=" + dataFilter.typeplan : ''
        let filtercompany = dataFilter.companyId !== '' ? "&companyId[$eq]=" + dataFilter.companyId : ''
        const response = handleFetch({
            url: `bo/plans?${filtername}${filterprice}${filtertypeplan}${filtercompany}${dataFilter.companyId === 1 ?'&type[$ne]='+PLAN_TYPE_EAN_STUDENT_TEACHER_COLLABORATOR:''}`,
        //const response = handleFetch({
            //url:'bo/plans?$limit=100&companyId[$eq]=1,
            method: 'GET', 
            
        });
        response
                .then((r) => {
                    setPlans(r)
                    },
                    error => {
                        console.log(error.errors);
                        if(error.errors) {
    
                        }
                        
                    })
        const responsecomp = handleFetch({
            url: `bo/companiesb2b`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecomp
            .then((r) => {
                setCompanies(r.data)
            },
                error => {
                    console.log(error.errors);
                })
      
    }, [])
    
    // useEffect en caso de borrar plan
    useEffect(()=>{
        
        if(deletePlanConfirmation){
            
            const response = handleFetch({
                url:'bo/plans/'+planToDelete,
                method: 'DELETE',
                headers: {Authorization: `Bearer ${authToken}`} 

            });
            
            response
            .then((r) => {
                window.location.reload();
                setDeletePlanConfirmation(false);
                setPlanToDelete("");
            },
                error => {
                    console.log(error.errors);
                    if(error.errors) {

                    }
                    
                })
        }

    }, [deletePlanConfirmation]);

    useEffect(() => {
        setToastProperties(location.state?.toastProperties || {show:false});
        window.history.replaceState(null,'');
    },[]);

    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        const PLAN_TYPE_EAN_STUDENT_TEACHER_COLLABORATOR = 2;
        let filtername = dataFilter.name !== '' ? `&title=` + dataFilter.name : ''
        let filterprice = dataFilter.price !== '' ? `&price[$eq]=` + dataFilter.price : ''        
        let filtertypeplan = dataFilter.typeplan !== '' ? "&typeplan[$eq]=" + dataFilter.typeplan : ''
        let filtercompany = dataFilter.companyId !== '' ? "&companyId[$eq]=" + dataFilter.companyId : ''
        const response = handleFetch({
            url: `bo/plans?${filtername}${filterprice}${filtertypeplan}${filtercompany}${dataFilter.companyId === 10 ?'&type[$ne]='+PLAN_TYPE_EAN_STUDENT_TEACHER_COLLABORATOR:''}`,
            method: 'GET',
        });
        response
            .then((r) => {
                setPlans(r)
                setTotal(r.length)
            },
                error => {
                    console.log(error.errors);
                })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Planes</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/planes'} className={`${pathname == '/admin/planes' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Planes</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/cursosenplanes'} className={`${pathname == '/admin/cursosenplanes' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Cursos en Planes</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            onSubmit={handleSubmit}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre del plan</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                            <span className='FilterAdmin__input-title'>Precio</span>
                                <div>
                                    <input type='number' name='price' value={dataFilter?.price || ''} onChange={handleChangeFilter} min='0' max={dataFilter?.price || 0} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Tipo de plan</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='typeplan'
                                        value={dataFilter?.typeplan || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Selecciona un plan</option>
                                        <option value="0">Planes Eanistas</option>
                                        <option value="1">Planes pagos</option>
                                        <option value="2">Planes cortesía</option>
                                        <option value="3">Planes gratuitos</option>
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Empresa B2B</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='companyId'
                                        value={dataFilter?.companyId || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        {
                                            companies.length != 0 ?
                                                companies.map((companie, index) => {
                                                    return (<option value={companie.id}>{companie.name}</option>)
                                                }) : ''
                                        }
                                    </select>
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", height: "25px", textAlign: "right" }}>
                            { isAdmin && <Link className='FilterAdmin__search-button' to='/admin/planes/crear'>
                                <i className='fa-solid fa-plus' style={{marginRight: "15px"}}></i>
                                <span>Agregar nuevo plan</span>
                            </Link> }
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable plans={plans} setOpenModalDelete={setOpenModalDelete} setPlanToDelete={setPlanToDelete} clonePlan={clonePlan} total={total} dataFilter={dataFilter} authToken={authToken} isAdmin={isAdmin}/>
                            }
                        </div>
                    </div>
                    <DeleteConfirmationModal openModalDelete={openModalDelete} handleCloseModal={handleCloseModal} handleDeletePlanConfirmation={handleDeletePlanConfirmation} ></DeleteConfirmationModal>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página de registro directo a la plataforma según enlace de plan
 * @param {event} openModalDelete Evento para abrir modal
 * @param {event} handleCloseModal Evento para cancelar operación, cerrar modal
 * @param {event} handleDeletePlanConfirmation Evento para abrir confirmación borrar plan
 * @function DeleteConfirmationModal
 */
const DeleteConfirmationModal = ({openModalDelete, handleCloseModal, handleDeletePlanConfirmation})=>{
    return(
        <>
            <Modal
                open={openModalDelete}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                    <div className='PlanAdmin__modal-base PlanAdmin__outline-none'>
                        <div className='PlanAdmin__modal-container PlanAdmin__outline-none'>
                            ¿Estas seguro que<br />
                            deseas eliminar <br/>
                            este plan?
                        </div>
                        <div className='PlanAdmin__modal-buttons-container'>
                            <button 
                                className='PlanAdmin__modal-button-delete'
                                onClick={handleDeletePlanConfirmation}
                            >
                                Eliminar
                            </button>
                            <button
                                className='PlanAdmin__modal-button-cancel'
                                onClick={handleCloseModal}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
            </Modal>

        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar encuestas
 * @param {JSON} plans Información de planes
 * @param {string} total Cantidad total de planes
 * @function UsersTable
 */
const UsersTable = ({ plans, setOpenModalDelete, setPlanToDelete, clonePlan, total, dataFilter, authToken, isAdmin}) => {
    const [dialogProperties, setDialogProperties] = useState({ open: false });    
    const [data, handleFetch] = useFetch();
    //Header en listado de planes
    const headers = [
                     {
                        "name":"TÍTULO",
                        "sort_name":"title",
                        "width": "20%"
                     },
                     {
                        "name":"STATUS",
                        "sort_name":"enabled",
                        "width": "10%"
                     },
                     {
                         "name": "RECOMENDADO",
                         "sort_name": "highlighted",
                         "width":"15%"
                     },
                     {
                        "name":"DURACIÓN",
                        "sort_name": ["durationPeriod", "duration"],
                        "width": "12%"
                     },
                     {
                        "name":"PRECIO",
                        "sort_name":"price",
                        "width": "10%"
                     },
                     {
                        "name":"ENLACE ACTIVO",
                        "sort_name":"linkactive",
                        "width": "10%"
                     }
                    ];

    const durationStringPlural = ['días', 'semanas', 'meses', 'años'];
    const durationStringSingular = ['día', 'semana', 'mes', 'año'];

    const [sort, setSort] = useState([false, false, false, false]);
    const [plansSorted, setPlansSorted] = useState(plans)
    const [planHovered, setPlanHovered] = useState(Array(plans.length).fill(false))

    const [toastProperties, setToastProperties] = useState({ show: false });
    const navigate = useNavigate();

    useEffect(() => {
      setPlansSorted(plans)
    
    }, [plans])

    // Función para ordenar planes
    const GetSortOrder = (prop, sort_way) =>{
        if(!Array.isArray(prop)){
            prop = [prop]
        }
        return (a, b) =>{
            
            if(sort_way ==="desc"){
                for (let index = 0; index < prop.length; index++) {
                    const property = prop[index];
                    if (a[property] > b[property]) {   
                        return 1;    
                    } else if (a[property] < b[property]){    
                        return -1;    
                    }
                } 
                return 0
            }else{
                for (let index = 0; index < prop.length; index++) {
                    const property = prop[index];
                    if (a[property] < b[property]) {   
                        return 1;    
                    } else if (a[property] > b[property]){    
                        return -1;    
                    }
                } 
                return 0 
            }
            return 0;
        }
    }

    const resetState = () =>{
        setPlanHovered(Array(plans.length).fill(false))
    }

    const handleActiveRow = (index)=>{
        let auxArray = Array(plans.length).fill(false);
        auxArray[index] =  true
        setPlanHovered(auxArray)
    }
    // Función de orden de planes desc o asc
    const handleHeaderSort = (sort_name, index) =>{
        let auxArray = [false, false, false, false]
        let auxPlans = [... plans]
        if(sort[index]==="desc"){
            
            auxArray[index] = "asc"
            auxPlans.sort(GetSortOrder(sort_name, "asc"))
            setPlansSorted(auxPlans)
            setSort(auxArray)

        }else if(sort[index]==="asc"){
            setPlansSorted(plans)
            setSort(auxArray)
        }else{
            auxArray[index] = "desc"
            auxPlans.sort(GetSortOrder(sort_name, "desc"))
            setPlansSorted(auxPlans)
            setSort( auxArray)            
        }
        

    };
    // Handle para cambiar estado a usuarios
    const handleChangeState =  (data, event) => {
        event.preventDefault();
        const updatedData = { ...data, urlenabled: !data.urlenabled };
        handleFetch({
            url: `bo/plans/${data.id}`,
            method: 'PATCH',
            data: updatedData,
            headers: {Authorization: `Bearer ${authToken}`},
        })
            .then(()=>{
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha cambiado el estado del enlace exitosamente.',
                    position: { horizontal: 'left' },
                });
                const responseusersb2b = handleFetch({
                    url: 'bo/plans?$limit=50&companyId[$eq]=1&type[$ne]=2',
                    method: 'GET'
                });
                responseusersb2b
                    .then((r) => {
                        setPlansSorted(r)
                    },
                        error => {
                            console.log(error.errors);
                        })
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al cambiar el estado del enlace. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    
    return (
        <>
            <AcceptDialog {...dialogProperties}></AcceptDialog>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        plansSorted.length != 0 && 
                        plansSorted.sort((a, b) => a.title.localeCompare(b.title)).map((plan, index)=>{
                            return(
                                <tr onMouseLeave={resetState} onMouseEnter={()=>{handleActiveRow(index)}} className={`PlanAdmin__table-row ${index%2 ===0 ? "PlanAdmin__table-pair-row": "PlanAdmin__table-odd-row" }`} key={index}>
                                    <td className="overflow" style={{paddingLeft: "16px"}}>{plan.title}</td>
                                    <td className={`overflow ${plan.enabled?'PlanAdmin__activate':'PlanAdmin__inactivate'}`} style={{paddingLeft: "16px", marginLeft: "-20px"}}>{plan.enabled? "Activo": "Inactivo"}</td>
                                    <td className={`overflow ${plan.highlighted?'PlanAdmin__activate':'PlanAdmin__inactivate'}`} style={{paddingLeft: "16px"}}>{plan.highlighted? "Recomendado": "No recomendado"}</td>
                                    <td className="overflow" style={{paddingLeft: "16px"}}>{plan.duration} {plan.duration===1?durationStringSingular[plan.durationPeriod - 1]:durationStringPlural[plan.durationPeriod - 1]}</td>
                                    <td className="overflow" style={{paddingLeft: "16px", textAlign: "right"}}>{parseInt(plan.price).toLocaleString('es-CO')} COP</td>
                                    <td className="d-flex position-relative" style={{marginTop: "25px", paddingLeft: "16px"}}>
                                        <div className="aligncenter form-check form-switch">
                                      
                                            <input 
                                                name="urlenabled"
                                                value={plan.urlenabled}
                                                type="checkbox"
                                                className="form-check-input PlanFormContainer__check-input"
                                                defaultChecked={plan.urlenabled}
                                                disabled={!isAdmin}
                                                onClick={(event) => handleChangeState(plan, event)}
                                            />
                                        </div>
                                       
                                        
                                    </td>
                                    {
                                        planHovered[index]?
                                        <td>
                                            { isAdmin && <div className='d-flex justify-content-center'>
                                                <div className='PlanAdmin__button-container' onClick={() => { navigate('/admin/planes/crear?planID='+plan.id) }} >
                                                    <i className='fa-solid fa-pen PlanAdmin__color-primary'></i>
                                                </div>
                                                <div className='PlanAdmin__button-container' onClick={() =>{ setOpenModalDelete(true); setPlanToDelete(plan.id) }}>
                                                    <i className='fa-solid fa-trash PlanAdmin__color-secondary'></i>
                                                </div>
                                                <div className='PlanAdmin__button-container' onClick={()=>{clonePlan(plan.id)}}>
                                                    <i className='fa-solid fa-clone PlanAdmin__color-secondary'></i>
                                                </div>
                                            </div> }
                                        </td>
                                        :
                                        <td></td>
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}