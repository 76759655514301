import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { AcceptDialog } from '../../controls/UIControls';
import Modal from '@mui/material/Modal';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección encuestas
 * @interface SurveySection
 */
export const SurveySection = () => {

    const [surveys, setSurveys] = useState([]);
    const [surveysFull, setSurveysFull] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();

    const [dataFilter, setDataFilter] = useState({
        name: '',
        course: '',
        since: '',
        document: '',
    })
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint listado de encuestas
    useEffect(() => {
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filtercourse = dataFilter.course !== '' ? `&course=` + dataFilter.course : ''        
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        const companies = handleFetch({
            url:`bo/companiesb2b`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        companies
            .then((r) => {
                    setCompanies(r.data)
                },
                error => {
                    console.log(error.errors);
                })
        const response = handleFetch({
            url: `bo/surveys?${filtername}${filtercourse}${filtersince}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setSurveys(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecsv = handleFetch({
            url: `bo/surveys?$limit=50000${filtername}${filtercourse}${filtersince}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecsv
            .then((r) => {
                setSurveysFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filtercourse = dataFilter.course !== '' ? `&course=` + dataFilter.course : ''        
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        const response = handleFetch({
            url: `bo/surveys?$limit=50000${filtername}${filtercourse}${filtersince}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setSurveysFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Encuestas</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listsurveys.csv"}
                            onSubmit={handleSubmit}
                            data={surveysFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre de usuario</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre de curso</span>
                                <div>
                                    <input type='text' name='course' value={dataFilter?.course || ''} onChange={handleChangeFilter} placeholder="Curso" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='text' name='document' value={dataFilter?.document || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable surveys={surveys} companies={companies} total={total} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar encuestas
 * @param {JSON} surveys Información de encuestas
 * @param {string} total Cantidad total de encuestas
 * @function UsersTable
 */
const UsersTable = ({ surveys, companies, total, dataFilter }) => {

    const [page, setPage] = useState(0);
    // Header para listado de encuestas
    const headers = [
        {
            "name": "NOMBRE CURSO",
            "sort_name": "course",
            "width": "25%"
        },
        {
            "name": "NOMBRE USUARIO",
            "sort_name": "nameuser",
            "width": "25%"
        },
        {
            "name": "RECOMENDACIÓN",
            "sort_name": "recommendation",
            "width": "15%"
        },
        {
            "name": "EXPERIENCIA",
            "sort_name": "experience",
            "width": "12%"
        },
        {
            "name": "FECHA CREACIÓN",
            "sort_name": "date",
            "width": "12%"
        }
    ];

    const [details, setDetails] = useState(null)
    const [certifications, setUsersSorted] = useState(surveys)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(surveys.length).fill(false))
    const [dialogProperties, setDialogProperties] = useState({ open: false });
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setUsersSorted(surveys)
        setTotalSorted(total)
    }, [surveys, total])

    const resetState = () => {
        setUserHovered(Array(surveys.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(surveys.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }
    // Función mostrar modal para cambiar estado de certificados e insignias
    const handleOpenModal = (detail) => {
        const companyName = companies.find((item) => parseInt(item.id) === detail['webuser.companyId']);
        detail.companyName = companyName.name;
        setDetails(detail);
        setShowModal(true);
    }
    // Función cerrar modal para cambiar estado de certificados e insignias
    const handleCloseModal = () => {
        setShowModal(false);
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filtercourse = dataFilter.course !== '' ? `&course=` + dataFilter.course : ''        
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/surveys?&$skip=${result}${filtername}${filtercourse}${filtersince}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsersSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    return (
        <>
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Detalles Respuestas Encuesta</div>
                                {details && <>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div><span className='fw-bold'>Usuario:</span> {details['webuser.name']} {details['webuser.lastname']}</div>
                                            <div><span className='fw-bold'>B2B:</span> {details['webuser.name']} {details['webuser.lastname']}</div>
                                        </div>
                                        <div className='col-6'>
                                            <div><span className='fw-bold'>Documento:</span> {details['webuser.documentType']} {details['webuser.document']} </div>
                                            <div><span className='fw-bold'>Estado:</span> {details['webuser.active']===1 ? 'Activo' : 'Inactivo'} </div>
                                        </div>
                                    </div>
                                    <div className='my-3 text-center'>{details['course.name']}</div>
                                    <div className='mb-2 B2BAdmin__text-description'>¿Qué tan sencillo fue el proceso de registro? {details.SimpleRegister}</div>
                                    <div className='mb-2 B2BAdmin__text-description'>¿Qué tan positiva fue su experiencia de navegación y uso dentro de la plataforma? {details.ExperiencePlatform}</div>
                                    <div className='mb-2 B2BAdmin__text-description'>¿Cuánto recomienda la plataforma EanX? {details.Recommendation}</div>
                                    <div className='mb-2 B2BAdmin__text-description'>Del curso que inscribió/finalizó, ¿cuánto cumplió con sus expectativas? {details.FulfillmentExpectations}</div>
                                    { details.FulfillmentExpectations < 5 && <div className='mb-2 B2BAdmin__text-description'>Porque?<p>{details.FulfillmentComments}</p></div>}
                                    <div className='mb-2 B2BAdmin__text-description'>Comentario o sugerencia adicional<p>{details.Comments}</p></div>
                               </>}
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cerrar</button></div>
                                </div>
                            </div>
                        </div>
                </div>
            </Modal>
            <AcceptDialog {...dialogProperties}></AcceptDialog>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        certifications.length != 0 ?
                            certifications.map((cert, index) => {
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert['course.name']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert['webuser.name']} {cert['webuser.lastname']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.Recommendation}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.ExperiencePlatform}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{new Date(cert.createdAt).toISOString().split('T')[0]}</td>
                                        {
                                            userHovered[index] ?
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='UserAdmin__button-container' >
                                                            <Link to={{}} onClick={(event) => handleOpenModal(cert, event)}>
                                                                <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                <td></td>
                                        }
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="5" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}