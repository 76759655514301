import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
import { PlanPageGraduates } from '../plan/PlanPageGraduates'
/**
 * Creado por Equilibrio Agency 2025<br>
 * Página de validación de planes para graduados
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface GraduatesPlanData
 */
export const GraduatesPlanData = () => {
    const location = useLocation();
    const userID = location?.state?.eanuser.id;
    const navigator = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        if(!userID){
            navigator('/planes')
        };
    }, []);
  return (
    <div>
        <LandingHeader />
        <div style={{marginTop: "70px"}}></div>
        <PlanPageGraduates />
        <div className='mb-5'>
        </div>
        <Footer />
    </div>
  )
}
